import { Box, Typography } from "@mui/material";
import { Currency } from "dinero.js";
import React, { useContext } from "react";
import { formatMoney, ToDineroObj } from "src/Utils/MoneyUtils";
import { Recon360Context } from "../../Recon360";

const OpeningBalClosingBalSection = ({ linkingData, selectedTaskId }) => {
  const { ownOpeningBalance, partnerOpeningBalance, currency } = useContext(Recon360Context);
  const selectedTask = linkingData?.find((task) => task.ticketId === selectedTaskId);
  return (
    <>
      <Box
        sx={{
          width: "568px",
          display: "flex",
          flexDirection: "column",
          gap: 0,
        }}
      >
        {/* Header Row */}
        <Box
          sx={{
            width: "100%",
            height: "40px",
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#f3f3f3",
            borderRadius: "4px",
            border: "1px solid #d0d0d0",
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              padding: "8px 12px",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                fontFamily: "Roboto",
                fontWeight: 500,
                fontSize: "14px",
                letterSpacing: "0.1px",
                lineHeight: "157%",
                color: "rgba(0, 0, 0, 0.6)",
              }}
            ></Typography>
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              padding: "8px 12px",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                fontFamily: "Roboto",
                fontWeight: 500,
                fontSize: "14px",
                letterSpacing: "0.1px",
                lineHeight: "157%",
                color: "rgba(0, 0, 0, 0.6)",
              }}
            >
              Your
            </Typography>
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              padding: "8px 12px",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                fontFamily: "Roboto",
                fontWeight: 500,
                fontSize: "14px",
                letterSpacing: "0.1px",
                lineHeight: "157%",
                color: "rgba(0, 0, 0, 0.6)",
              }}
            >
              Partner
            </Typography>
          </Box>
        </Box>

        {/* Closing Balance Row */}
        <Box
          sx={{
            width: "100%",
            minHeight: "60px",
            display: "flex",
            flexDirection: "row",
            // border: '1px solid #d0d0d0',
            // borderRadius: '4px',
            // marginTop: '-1px',
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: "flex",
              padding: "8px 12px",
              alignItems: "center",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                fontFamily: "Roboto",
                fontWeight: 500,
                fontSize: "14px",
                letterSpacing: "0.1px",
                lineHeight: "157%",
                color: "rgba(0, 0, 0, 0.6)",
              }}
            >
              Closing Balance of selected reconciliation
            </Typography>
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              padding: "8px 12px",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: "16px",
                letterSpacing: "0.15px",
                lineHeight: "150%",
                color: selectedTask ? "rgba(0, 0, 0, 0.87)" : "rgba(0, 0, 0, 0.38)",
              }}
            >
              {selectedTask
                ? formatMoney(ToDineroObj(selectedTask.ownClosingBalance, currency.current as Currency))
                : "Select task first"}
            </Typography>
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              padding: "8px 12px",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: "16px",
                letterSpacing: "0.15px",
                lineHeight: "150%",
                color: selectedTask ? "rgba(0, 0, 0, 0.87)" : "rgba(0, 0, 0, 0.38)",
              }}
            >
              {selectedTask
                ? formatMoney(ToDineroObj(selectedTask.partnerClosingBalance, currency.current as Currency))
                : "Select task first"}
            </Typography>
          </Box>
        </Box>

        {/* Opening Balance Row */}
        <Box
          sx={{
            width: "100%",
            minHeight: "60px",
            display: "flex",
            flexDirection: "row",
            // border: '1px solid #d0d0d0',
            // borderRadius: '4px',
            // marginTop: '-1px',
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: "flex",
              padding: "8px 12px",
              alignItems: "center",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                fontFamily: "Roboto",
                fontWeight: 500,
                fontSize: "14px",
                letterSpacing: "0.1px",
                lineHeight: "157%",
                color: "rgba(0, 0, 0, 0.6)",
              }}
            >
              Opening Balance of current reconciliation
            </Typography>
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              padding: "8px 12px",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: "16px",
                letterSpacing: "0.15px",
                lineHeight: "150%",
                color: "rgba(0, 0, 0, 0.87)",
              }}
            >
              {ownOpeningBalance ? formatMoney(ToDineroObj(ownOpeningBalance, currency.current as Currency)) : "-"}
            </Typography>
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              padding: "8px 12px",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: "16px",
                letterSpacing: "0.15px",
                lineHeight: "150%",
                color: "rgba(0, 0, 0, 0.87)",
              }}
            >
              {partnerOpeningBalance
                ? formatMoney(ToDineroObj(partnerOpeningBalance, currency.current as Currency))
                : "-"}
            </Typography>
          </Box>
        </Box>

        {/* Difference Row */}
        <Box
          sx={{
            width: "100%",
            height: "40px",
            display: "flex",
            flexDirection: "row",
            border: "1px solid #d0d0d0",
            borderRadius: "4px",
            marginTop: "-1px",
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              padding: "8px 12px",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                fontFamily: "Roboto",
                fontWeight: 500,
                fontSize: "14px",
                letterSpacing: "0.1px",
                lineHeight: "157%",
                color: "rgba(0, 0, 0, 0.6)",
              }}
            >
              Difference
            </Typography>
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              padding: "8px 12px",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: "16px",
                letterSpacing: "0.15px",
                lineHeight: "150%",
                color: "rgba(0, 0, 0, 0.87)",
              }}
            >
              {selectedTask && ownOpeningBalance
                ? formatMoney(
                    ToDineroObj(selectedTask.ownClosingBalance - ownOpeningBalance, currency.current as Currency)
                  )
                : "-"}
            </Typography>
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              padding: "8px 12px",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: "16px",
                letterSpacing: "0.15px",
                lineHeight: "150%",
                color: "rgba(0, 0, 0, 0.87)",
              }}
            >
              {selectedTask && partnerOpeningBalance
                ? formatMoney(
                    ToDineroObj(
                      selectedTask.partnerClosingBalance - partnerOpeningBalance,
                      currency.current as Currency
                    )
                  )
                : "-"}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default OpeningBalClosingBalSection;
