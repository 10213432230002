import React, { createContext, Dispatch, SetStateAction, useState } from "react";
import { EmailTemplate, ListUserEmailSettingRes, PDFTemplate } from "../../../../entity/recon-entity/ReconInterfaces";
import { AutoReminder } from "../Common/PartnerCommsInterfaces";

type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;

function StateDispatchFn<T = any>(): StateDispatch<T> {
  return;
}

function CreateCustomContext<T = any>(initialValue: object | string | number) {
  return createContext<[T, StateDispatch<T>]>([initialValue as T, StateDispatchFn]);
}

export const Context = CreateCustomContext("");

export const PartnerCommsContext = createContext<IPartnerCommunicationContext>(null);

export interface IPartnerCommunicationContext {
  isOpenDeleteModal: boolean;
  setIsOpenDeleteModal: Dispatch<SetStateAction<boolean>>;
  handleCloseDeleteModal: () => void;
  showDeleteLoadingIcon: boolean;
  setShowDeleteLoadingIcon: Dispatch<SetStateAction<boolean>>;
  // Email States BEGIN
  openSelectEmailTemplate: boolean;
  setOpenSelectEmailTemplate: StateDispatch<boolean>;
  openCreateEmailTemplate: boolean;
  setOpenCreateEmailTemplate: StateDispatch<boolean>;
  openSendEmailTemplate: boolean;
  setOpenSendEmailTemplate: StateDispatch<boolean>;
  openSendEmailOnCancel: boolean;
  setOpenSendEmailOnCancel: StateDispatch<boolean>;
  hideSendEmailTemplate: boolean;
  setHideSendEmailTemplate: StateDispatch<boolean>;
  emailTemplates: EmailTemplate[];
  setEmailTemplates: StateDispatch<EmailTemplate[]>;
  emailTemplateContent: EmailTemplate;
  setEmailTemplateContent: StateDispatch<EmailTemplate>;
  emailBusinessPartnerId: number;
  setEmailBusinessPartnerId: StateDispatch<number>;
  mappingId: number;
  setMappingId: StateDispatch<number>;
  businessPartnerName: string;
  setBusinessPartnerName: StateDispatch<string>;
  emailDisplayName: string;
  setEmailDisplayName: StateDispatch<string>;
  fromEmailAddress: string;
  setFromEmailAddress: StateDispatch<string>;
  communicationTypeId: number;
  setCommunicationTypeId: StateDispatch<number>;
  taskLabel: { title: string };
  setTaskLabel: StateDispatch<{ title: string }>;
  openBcBetaMailing: boolean;
  setOpenBcBetaMailing: StateDispatch<boolean>;
  openReplyEmail: boolean;
  setOpenReplyEmail: StateDispatch<boolean>;
  withoutTemplateCheck: boolean;
  setWithoutTemplateCheck: StateDispatch<boolean>;
  selectedAutoReminder: AutoReminder;
  setSelectedAutoReminder: StateDispatch<AutoReminder>;
  escalationStarted: boolean;
  setEscalationStarted: StateDispatch<boolean>;
  ResetContext: () => void;
  // Email States END
  pdfTemplates: PDFTemplate[];
  setPdfTemplates: StateDispatch<PDFTemplate[]>;
  createPdfTemplate: boolean;
  setCreatePdfTemplate: StateDispatch<boolean>;
  dynamicContentOptions: string[];
  setDynamicContentOptions: StateDispatch<string[]>;
  allowSubjectChange: boolean;
  setAllowSubjectChange: StateDispatch<boolean>;
  userEmailSetting: ListUserEmailSettingRes["userEmailSetting"];
  setUserEmailSetting: StateDispatch<ListUserEmailSettingRes["userEmailSetting"]>;
}

const PartnerCommunicationContext = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const [openSelectEmailTemplate, setOpenSelectEmailTemplate] = useState(false); //old
  const [openCreateEmailTemplate, setOpenCreateEmailTemplate] = useState(false); //old
  const [openSendEmailTemplate, setOpenSendEmailTemplate] = useState(false); //old
  const [openSendEmailOnCancel, setOpenSendEmailOnCancel] = useState(false); //old
  const [hideSendEmailTemplate, setHideSendEmailTemplate] = useState(false); //old
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([]);
  const [emailTemplateContent, setEmailTemplateContent] = useState<EmailTemplate>(null);
  const [emailBusinessPartnerId, setEmailBusinessPartnerId] = useState<number>(null);
  const [mappingId, setMappingId] = useState<number>(null);
  const [businessPartnerName, setBusinessPartnerName] = useState<string>("");
  const [emailDisplayName, setEmailDisplayName] = useState(""); // both
  const [fromEmailAddress, setFromEmailAddress] = useState(""); // both
  const [pdfTemplates, setPdfTemplates] = useState<PDFTemplate[]>([]);
  const [createPdfTemplate, setCreatePdfTemplate] = useState(false);
  const [allowSubjectChange, setAllowSubjectChange] = useState(true);
  const [communicationTypeId, setCommunicationTypeId] = useState<number>(null);
  const [openBcBetaMailing, setOpenBcBetaMailing] = useState<boolean>(false);
  const [openReplyEmail, setOpenReplyEmail] = useState(false);
  const [withoutTemplateCheck, setWithoutTemplateCheck] = useState(false);

  // new bc beta states
  const [taskLabel, setTaskLabel] = useState<{ title: string }>({ title: "" });
  const [selectedAutoReminder, setSelectedAutoReminder] = useState<AutoReminder>({} as AutoReminder);
  const [escalationStarted, setEscalationStarted] = useState(false);

  const [userEmailSetting, setUserEmailSetting] = useState<ListUserEmailSettingRes["userEmailSetting"]>({} as any);

  // delete Template
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [showDeleteLoadingIcon, setShowDeleteLoadingIcon] = useState<boolean>(false);

  const [dynamicContentOptions, setDynamicContentOptions] = useState<string[]>([]);

  const ResetContext = () => {
    setOpenSelectEmailTemplate(false);
    setOpenCreateEmailTemplate(false);
    setOpenSendEmailTemplate(false);
    setHideSendEmailTemplate(false);
    setEmailTemplates([]);
    setEmailTemplateContent(null);
    setEmailBusinessPartnerId(null);
    setEmailDisplayName("");
    setFromEmailAddress("");
  };

  const handleCloseDeleteModal = () => {
    setIsOpenDeleteModal(false);
  };

  const commonContextValues: IPartnerCommunicationContext = {
    isOpenDeleteModal,
    setIsOpenDeleteModal,
    handleCloseDeleteModal,
    showDeleteLoadingIcon,
    setShowDeleteLoadingIcon,
    // Email States BEGIN
    openSelectEmailTemplate,
    setOpenSelectEmailTemplate,
    openCreateEmailTemplate,
    setOpenCreateEmailTemplate,
    openSendEmailTemplate,
    setOpenSendEmailTemplate,
    openSendEmailOnCancel,
    setOpenSendEmailOnCancel,
    hideSendEmailTemplate,
    setHideSendEmailTemplate,
    emailTemplates,
    setEmailTemplates,
    emailTemplateContent,
    setEmailTemplateContent,
    emailBusinessPartnerId,
    setEmailBusinessPartnerId,
    mappingId,
    setMappingId,
    businessPartnerName,
    setBusinessPartnerName,
    emailDisplayName,
    setEmailDisplayName,
    fromEmailAddress,
    setFromEmailAddress,
    communicationTypeId,
    setCommunicationTypeId,
    taskLabel,
    setTaskLabel,
    openBcBetaMailing,
    setOpenBcBetaMailing,
    openReplyEmail,
    setOpenReplyEmail,
    withoutTemplateCheck,
    setWithoutTemplateCheck,
    selectedAutoReminder,
    setSelectedAutoReminder,
    escalationStarted,
    setEscalationStarted,
    ResetContext,
    // Email States END
    pdfTemplates,
    setPdfTemplates,
    createPdfTemplate,
    setCreatePdfTemplate,
    dynamicContentOptions,
    setDynamicContentOptions,
    allowSubjectChange,
    setAllowSubjectChange,
    userEmailSetting,
    setUserEmailSetting,
  };

  return <PartnerCommsContext.Provider value={commonContextValues}>{children}</PartnerCommsContext.Provider>;
};

export default PartnerCommunicationContext;
