import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button,
  createFilterOptions,
  Dialog,
  DialogActions,
  Divider,
  IconButton,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import useFetch from "src/Components/Common/useFetch";
import { userContext } from "src/Components/Contexts/userContext";
import CustomToast from "src/Components/CustomToast";
import { ClientType } from "src/entity/models/FrontendActor";
import { TicketDetails } from "src/entity/recon-entity/ReconInterfaces";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { formatDate } from "src/Utils/DateUtils";
import { StateDispatch } from "../Recon360";
import { TICKET_PRIORITIES } from "./CreateTicket";
import { NAKAD_SERVICE } from "./TasksHistoryModal";
import UpdateBulkTasksConfirmationModal from "./UpdateBulkTasksConfirmationModal";

const filter = createFilterOptions<any>();

interface UpdateBulkTasksModalProps {
  showUpdateBulkTasksModal: boolean;
  setShowUpdateBulkTasksModal: StateDispatch<boolean>;
  selectedTasks: TicketDetails[] | any;
  allUsers: { name: string; id: number }[];
  allStatuses: { name: string; id: number }[];
  fetchTickets: any;

  setShowBulkTasksModal: StateDispatch<boolean>;
  setClearRows: StateDispatch<boolean>;
  allTaskLabels: any;
  getAllTaskLabels: any;
  companyId: string;
  branchCode: string;
  type: string;
}

export default function UpdateBulkTasksModal({
  type,
  showUpdateBulkTasksModal,
  setShowUpdateBulkTasksModal,
  selectedTasks,
  allUsers,
  allStatuses,
  fetchTickets,
  setShowBulkTasksModal,
  setClearRows,
  allTaskLabels,
  getAllTaskLabels,
  companyId,
  branchCode,
}: UpdateBulkTasksModalProps) {
  const { actor } = useContext(userContext);

  const allSelectedTasksarePCTasks = selectedTasks.every((task) => task.service === NAKAD_SERVICE.PartnerCommunication);

  const defaultMaker = { id: selectedTasks[0].makerId, name: selectedTasks[0].maker };
  let isMakerSameForAllTasks = true;
  const defaultChecker = {
    id: selectedTasks[0].checkerId ? selectedTasks[0].checkerId : null,
    name: selectedTasks[0].checker ? selectedTasks[0].checker : null,
  };
  let isCheckerSameForAllTasks = true;
  const defaultApprover = {
    id: selectedTasks[0].approverId ? selectedTasks[0].approverId : null,
    name: selectedTasks[0].approver ? selectedTasks[0].approver : null,
  };
  let isApproverSameForAllTasks = true;
  const defaultStatus = { id: selectedTasks[0].statusId, name: selectedTasks[0].status };
  let isStatusSameForAllTasks = true;
  const defaultPriority = selectedTasks[0].priority;
  let isPrioritySameForAllTasks = true;
  const defaultDueDate = selectedTasks[0].dueDate ? new Date(selectedTasks[0].dueDate) : null;
  let isDueDateSameForAllTasks = true;
  const defaultRemarks = selectedTasks[0].remarks;
  let isRemarksSameForAllTasks = true;
  const defaultTaskLabel = selectedTasks[0].taskLabel;
  let isTaskLabelSameForAllTasks = true;

  for (let i = 1; i < selectedTasks.length; i++) {
    const currentTask = selectedTasks.at(i);
    if (currentTask.makerId !== defaultMaker.id) isMakerSameForAllTasks = false;
    if (currentTask.checkerId !== defaultChecker.id) isCheckerSameForAllTasks = false;
    if (currentTask.approverId !== defaultApprover.id) isApproverSameForAllTasks = false;
    if (currentTask.statusId !== defaultStatus.id) isStatusSameForAllTasks = false;
    if (currentTask.priority !== defaultPriority) isPrioritySameForAllTasks = false;
    if (
      currentTask.dueDate !== null && defaultDueDate !== null
        ? new Date(currentTask.dueDate).getTime() !== defaultDueDate.getTime()
        : currentTask.dueDate !== null || defaultDueDate !== null
    )
      isDueDateSameForAllTasks = false;
    if (currentTask.remarks !== defaultRemarks) isRemarksSameForAllTasks = false;
    if (currentTask.taskLabel !== defaultTaskLabel) isTaskLabelSameForAllTasks = false;
  }

  const [allowUpdatingMaker, setAllowUpdatingMaker] = useState(false);
  const [allowUpdatingChecker, setAllowUpdatingChecker] = useState(false);
  const [allowUpdatingApprover, setAllowUpdatingApprover] = useState(false);
  const [allowUpdatingStatus, setAllowUpdatingStatus] = useState(false);
  const [allowUpdatingPriority, setAllowUpdatingPriority] = useState(false);
  const [allowUpdatingDueDate, setAllowUpdatingDueDate] = useState(false);
  const [allowUpdatingRemarks, setAllowUpdatingRemarks] = useState(false);
  const [allowUpdatingTaskLabel, setAllowUpdatingTaskLabel] = useState(false);

  const [currentMaker, setCurrentMaker] = useState(isMakerSameForAllTasks ? defaultMaker : { id: null, name: null });
  const [currentChecker, setCurrentChecker] = useState(
    isCheckerSameForAllTasks ? defaultChecker : { id: null, name: null }
  );
  const [currentApprover, setCurrentApprover] = useState(
    isApproverSameForAllTasks ? defaultApprover : { id: null, name: null }
  );
  const [currentStatus, setCurrentStatus] = useState(
    isStatusSameForAllTasks ? defaultStatus : { id: null, name: null }
  );
  const [currentPriority, setCurrentPriority] = useState(isPrioritySameForAllTasks ? defaultPriority : null);
  const [currentDate, setCurrentDate] = useState(isDueDateSameForAllTasks ? defaultDueDate : null);
  const [currentRemarks, setCurrentRemarks] = useState(isRemarksSameForAllTasks ? defaultRemarks : null);
  const [currentTaskLabel, setCurrentTaskLabel] = useState<any>(isTaskLabelSameForAllTasks ? defaultTaskLabel : null);

  const [dueDateErrorText, setDueDateErrorText] = useState("");
  const [fieldsToUpdate, setFieldsToUpdate] = useState([]);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isUpdatingBulkTasks, setIsUpdatingBulkTasks] = useState(false);
  const selectedTasksData = selectedTasks.map((task) => {
    return { ticketId: task[type === "workflow" ? "id" : "taskId"], mappingId: task.mappingId };
  });

  const updateBulkTasks = () => {
    setIsUpdatingBulkTasks(true);

    const payloadObj = fieldsToUpdate.reduce((obj, item) => {
      // Assign the value to the corresponding key
      obj[item.fieldId] = item.value;
      return obj;
    }, {});

    useFetch(API_ENDPOINTS.UPDATE_BULK_TICKET.url, "POST", {
      failureMessage: API_ENDPOINTS.UPDATE_BULK_TICKET.failureMessage,
      showSuccessToast: true,
      data: {
        taskData: selectedTasksData,
        ...payloadObj,
      },
      thenCallBack: () => {
        setIsUpdatingBulkTasks(false);
        setShowBulkTasksModal(false);
        setShowUpdateBulkTasksModal(false);
        fetchTickets();
        getAllTaskLabels();
        setClearRows(true);
      },
      catchCallBack: () => {
        setShowBulkTasksModal(false);
        setShowUpdateBulkTasksModal(false);
        setIsUpdatingBulkTasks(false);
        getAllTaskLabels();
        setClearRows(true);
      },
    });
  };
  return (
    <>
      <Dialog open={showUpdateBulkTasksModal} sx={{ "& .MuiDialog-paper": { borderRadius: "4px", minWidth: "600px" } }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 2 }}>
          <Typography variant="h6">Update Bulk Tasks</Typography>
          <IconButton onClick={() => setShowUpdateBulkTasksModal(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <Stack sx={{ padding: 2, gap: 2 }}>
          <Typography variant="subtitle2" sx={{ color: "rgba(0, 0, 0, 0.6)", marginBottom: 1 }}>
            {selectedTasks.length} Tasks selected
          </Typography>

          <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
            <Checkbox checked={allowUpdatingMaker} onChange={(e) => setAllowUpdatingMaker(e.target.checked)} />
            <Autocomplete
              defaultValue={isMakerSameForAllTasks ? (defaultMaker.id ? defaultMaker : null) : null}
              disabled={!allowUpdatingMaker}
              onChange={(_, newValue) => {
                setCurrentMaker(newValue);
              }}
              sx={{ width: "100%", "& fieldset": { borderRadius: "4px" } }}
              disableClearable
              options={allUsers}
              renderOption={(props, item) => (
                <li {...props} key={item.id}>
                  <ListItemText>{item.name}</ListItemText>
                </li>
              )}
              getOptionLabel={(option: { name: string; id: number }) => option.name}
              renderInput={(params) => <TextField {...params} label="Maker" size="small" />}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
            <Checkbox checked={allowUpdatingChecker} onChange={(e) => setAllowUpdatingChecker(e.target.checked)} />
            <Autocomplete
              defaultValue={isCheckerSameForAllTasks ? (defaultChecker.id ? defaultChecker : null) : null}
              disabled={!allowUpdatingChecker}
              onChange={(_, newValue) => {
                setCurrentChecker(newValue);
              }}
              sx={{ width: "100%", "& fieldset": { borderRadius: "4px" } }}
              disableClearable
              options={allUsers}
              renderOption={(props, item) => (
                <li {...props} key={item.id}>
                  <ListItemText>{item.name}</ListItemText>
                </li>
              )}
              getOptionLabel={(option: { name: string; id: number }) => option.name}
              renderInput={(params) => <TextField {...params} label="Checker" size="small" />}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
            <Checkbox checked={allowUpdatingApprover} onChange={(e) => setAllowUpdatingApprover(e.target.checked)} />
            <Autocomplete
              defaultValue={isApproverSameForAllTasks ? (defaultApprover.id ? defaultApprover : null) : null}
              disabled={!allowUpdatingApprover}
              onChange={(_, newValue) => {
                setCurrentApprover(newValue);
              }}
              sx={{ width: "100%", "& fieldset": { borderRadius: "4px" } }}
              disableClearable
              options={allUsers}
              renderOption={(props, item) => (
                <li {...props} key={item.id}>
                  <ListItemText>{item.name}</ListItemText>
                </li>
              )}
              getOptionLabel={(option: { name: string; id: number }) => option.name}
              renderInput={(params) => <TextField {...params} label="Approver" size="small" />}
            />
          </Box>
          {allSelectedTasksarePCTasks === false && (
            <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
              <Checkbox checked={allowUpdatingStatus} onChange={(e) => setAllowUpdatingStatus(e.target.checked)} />
              <Autocomplete
                defaultValue={isStatusSameForAllTasks ? defaultStatus : null}
                disabled={!allowUpdatingStatus}
                onChange={(_, newValue) => {
                  setCurrentStatus(newValue);
                }}
                sx={{ width: "100%", "& fieldset": { borderRadius: "4px" } }}
                disableClearable
                options={allStatuses}
                renderOption={(props, item) => (
                  <li {...props} key={item.id}>
                    <ListItemText>{item.name}</ListItemText>
                  </li>
                )}
                getOptionLabel={(option: { name: string; id: number }) => option.name}
                renderInput={(params) => <TextField {...params} label="Status" size="small" />}
              />
            </Box>
          )}
          <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
            <Checkbox checked={allowUpdatingPriority} onChange={(e) => setAllowUpdatingPriority(e.target.checked)} />
            <Autocomplete
              defaultValue={isPrioritySameForAllTasks ? defaultPriority : null}
              disabled={!allowUpdatingPriority}
              onChange={(_, newValue) => {
                setCurrentPriority(newValue);
              }}
              sx={{ width: "100%", "& fieldset": { borderRadius: "4px" } }}
              disableClearable
              options={TICKET_PRIORITIES}
              renderOption={(props, item) => (
                <li {...props} key={item}>
                  <ListItemText>{item}</ListItemText>
                </li>
              )}
              renderInput={(params) => <TextField {...params} label="Priority" size="small" />}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
            <Checkbox checked={allowUpdatingDueDate} onChange={(e) => setAllowUpdatingDueDate(e.target.checked)} />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                disabled={!allowUpdatingDueDate}
                sx={{ "& fieldset": { borderRadius: "4px" } }}
                label="Due Date"
                value={currentDate}
                onError={(error) => {
                  if (error) setDueDateErrorText(error);
                  else setDueDateErrorText("");
                }}
                onChange={(date: Date) => setCurrentDate(date)}
                format="dd/MM/yyyy"
                slotProps={{
                  textField: {
                    helperText: dueDateErrorText && "Invalid Date",
                    sx: { width: "100%" },
                    size: "small",

                    InputProps: {
                      style: { borderRadius: "4px" },
                    },
                  },
                }}
              />
            </LocalizationProvider>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
            <Checkbox checked={allowUpdatingRemarks} onChange={(e) => setAllowUpdatingRemarks(e.target.checked)} />
            <TextField
              defaultValue={isRemarksSameForAllTasks ? defaultRemarks : null}
              disabled={!allowUpdatingRemarks}
              InputProps={{
                style: {
                  borderRadius: "4px",
                },
              }}
              sx={{ width: "100%" }}
              size="small"
              label="Remarks"
              onChange={(e) => {
                const remarks = e.target?.value.trim();
                setCurrentRemarks(remarks.length > 0 ? remarks : null);
              }}
              multiline
              maxRows={4}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
            <Checkbox checked={allowUpdatingTaskLabel} onChange={(e) => setAllowUpdatingTaskLabel(e.target.checked)} />
            <Autocomplete
              disabled={!allowUpdatingTaskLabel}
              value={currentTaskLabel}
              onChange={(_, newValue) => {
                if (typeof newValue === "string") {
                  setCurrentTaskLabel({
                    title: newValue,
                  });
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  setCurrentTaskLabel({
                    title: newValue.inputValue,
                  });
                } else {
                  setCurrentTaskLabel(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                if (inputValue.trim().length === 0) {
                  return filtered;
                }
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue.toLowerCase() === option.title.toLowerCase());
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    title: `Add "${inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={allTaskLabels}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.title;
              }}
              renderOption={(props, option) => {
                const { ...optionProps } = props;
                return (
                  <li key={option.title} {...optionProps}>
                    {option.title}
                  </li>
                );
              }}
              sx={{ width: "100%", "& fieldset": { borderRadius: "4px" } }}
              freeSolo
              renderInput={(params) => <TextField {...params} label="Task Label" size="small" />}
            />
          </Box>
        </Stack>
        <DialogActions sx={{ padding: 2, background: "#F1F1F1" }}>
          <Button
            disabled={
              (!allowUpdatingMaker &&
                !allowUpdatingChecker &&
                !allowUpdatingApprover &&
                !allowUpdatingStatus &&
                !allowUpdatingPriority &&
                !allowUpdatingDueDate &&
                !allowUpdatingRemarks &&
                !allowUpdatingTaskLabel) ||
              (allowUpdatingMaker && currentMaker.id === null) ||
              (allowUpdatingChecker &&
                !(actor.clientType === ClientType.Product || actor.clientType === ClientType.ProductEnterprise) &&
                currentChecker.id === null) ||
              (allowUpdatingStatus && currentStatus.id === null) ||
              (allowUpdatingPriority && currentPriority === null) ||
              dueDateErrorText.length > 0
            }
            variant="contained"
            sx={{
              background: "#27B27C",
              padding: "8px 22px",
              color: "#FFF",
              borderRadius: "4px !important",
              fontWeight: 500,
              fontSize: "15px",
              "&:hover, &.Mui-focusVisible": { background: "#27B27C" },
            }}
            onClick={() => {
              const fieldsToUpdateArray = [];
              if (allowUpdatingMaker) {
                fieldsToUpdateArray.push({
                  field: "Maker",
                  fieldId: "makerId",
                  name: currentMaker.name,
                  value: currentMaker.id,
                });
              }
              if (allowUpdatingChecker) {
                fieldsToUpdateArray.push({
                  field: "Checker",
                  fieldId: "checkerId",
                  name: currentChecker.name,
                  value: currentChecker.id,
                });
              }
              if (allowUpdatingApprover) {
                fieldsToUpdateArray.push({
                  field: "Approver",
                  fieldId: "approverId",
                  name: currentApprover.name,
                  value: currentApprover.id,
                });
              }
              if (allowUpdatingStatus) {
                fieldsToUpdateArray.push({
                  field: "Status",
                  fieldId: "statusId",
                  name: currentStatus.name,
                  value: currentStatus.id,
                });
              }
              if (allowUpdatingPriority) {
                fieldsToUpdateArray.push({
                  field: "Priority",
                  fieldId: "priority",
                  name: currentPriority,
                  value: currentPriority,
                });
              }
              if (allowUpdatingDueDate) {
                fieldsToUpdateArray.push({
                  field: "Due Date",
                  fieldId: "dueDate",
                  name: currentDate ? formatDate(currentDate) : null,
                  value: currentDate,
                });
              }
              if (allowUpdatingRemarks) {
                fieldsToUpdateArray.push({
                  field: "Remarks",
                  fieldId: "remarks",
                  name: currentRemarks,
                  value: currentRemarks,
                });
              }
              if (allowUpdatingTaskLabel) {
                const taskLabelValue = currentTaskLabel
                  ? currentTaskLabel.title
                    ? currentTaskLabel.title.trim()
                    : currentTaskLabel
                  : null;

                if (taskLabelValue) {
                  if (taskLabelValue.length > 30) {
                    toast.error(
                      <CustomToast message="Character limit exceeded. Please add less than 30 characters." />
                    );
                    return;
                  }
                  if (taskLabelValue.includes(",")) {
                    toast.error(<CustomToast message="Multiple labels are not allowed for a task." />);
                    return;
                  }
                }
                fieldsToUpdateArray.push({
                  field: "Task Label",
                  fieldId: "taskLabel",
                  name: taskLabelValue,
                  value: taskLabelValue,
                });
              }

              setFieldsToUpdate(fieldsToUpdateArray);
              setShowConfirmationModal(true);
            }}
          >
            UPDATE
          </Button>
        </DialogActions>
      </Dialog>
      <UpdateBulkTasksConfirmationModal
        type={type}
        open={showConfirmationModal}
        setOpen={setShowConfirmationModal}
        title={`Are you sure you want to Update ${selectedTasks.length} Tasks?`}
        content={
          <>
            {`This action will update following for ${selectedTasks.length} tasks:`}
            {fieldsToUpdate.map((item, idx) => {
              return <p key={item.field}>{`${idx + 1}. ${item.field} : ${item.name}`}</p>;
            })}
          </>
        }
        updateBulkTasks={updateBulkTasks}
        isLoading={isUpdatingBulkTasks}
        companyId={companyId}
        branchCode={branchCode}
      />
    </>
  );
}
