import { createContext, MutableRefObject } from "react";
import { BusinessPartnerListForBcBeta } from "src/entity/recon-entity/ReconInterfaces";

export interface IBcNewContext {
  tabValue: number;
  setTabValue: StateDispatch<number>;
  tabChipLabel: { [k in keyof typeof TABS_MAP]: number };
  setTabChipLabel: StateDispatch<{ [k in keyof typeof TABS_MAP]: number }>;
  loader: boolean;
  rowsDataBcBeta: BusinessPartnerListForBcBeta[];
  setRowsDataBcBeta: StateDispatch<BusinessPartnerListForBcBeta[]>;
  storeRowsDataBcBeta: BusinessPartnerListForBcBeta[];
  rowsDataBcNewMailing: BusinessPartnerListForBcBeta[];
  setRowsDataBcNewMailing: StateDispatch<BusinessPartnerListForBcBeta[]>;
  storeRowsDataBcNewMailing: BusinessPartnerListForBcBeta[];
  setStoreRowsDataBcNewMailing: StateDispatch<BusinessPartnerListForBcBeta[]>;
  storeAllCategories: MutableRefObject<string[]>;
  currentRow: BusinessPartnerListForBcBeta;
  setCurrentRow: StateDispatch<BusinessPartnerListForBcBeta>;
  mailingRows: BusinessPartnerListForBcBeta[];
  setMailingRows: StateDispatch<BusinessPartnerListForBcBeta[]>;
  openBcBetaMailThread: boolean;
  setOpenBcBetaMailThread: StateDispatch<boolean>;
  openBcNewMailing: boolean;
  setOpenBcNewMailing: StateDispatch<boolean>;
  activeStep: number;
  setActiveStep: StateDispatch<number>;
  openRaiseReq: boolean;
  setOpenRaiseReq: StateDispatch<boolean>;
  withoutTemplateCheck: boolean;
  setWithoutTemplateCheck: StateDispatch<boolean>;
  listAllBusinessPartners: () => Promise<void>;
  listUserEmailTemplates: () => Promise<void>;
  listAllBusinessPartnerRequest: () => Promise<void>;
  setIntervalBCBeta: () => void;
  companyId: string;
  branchCode: string;
}

export const TABS_MAP = {
  responseReceived: {
    index: 0,
    label: "Response Received",
  },
  responseNotReceived: {
    index: 1,
    label: "Response Not Received",
  },
  allMails: {
    index: 2,
    label: "All Mails",
  },
} as const;

type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;

const BcNewContext = createContext<IBcNewContext>({} as IBcNewContext);

export default BcNewContext;
