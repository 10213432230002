import { Add } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import useFetch from "src/Components/Common/useFetch";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { uiLoggerNamesPartnerCommunication } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { BcBetaContext } from "../../BalanceConfirmationBeta/BalanceConfirmationBeta";
import { CommsType } from "../../BalanceConfirmationBeta/CommunicationTypes/CommunicationTypes";
import NewCommunicationType from "../../BalanceConfirmationBeta/CommunicationTypes/NewAndEditCommunicationType";
import { PartnerCommsContext } from "../../Context/PartnerCommunicationContext";
import { NdButtonOutlined } from "../../MsmePartnerPortal/CommonComponents";
import { StateDispatch, TaskLabelAutocomplete } from "../CommonComponents";

export const ChooseCommsType = ({ setSelectedCommsTypeId }: { setSelectedCommsTypeId: StateDispatch<number> }) => {
  const [communicationTypes, setCommunicationTypes] = useState<CommsType[]>([]);
  const { companyId, branchCode, selectedRow, listUserEmailTemplates } = useContext(BcBetaContext);
  const { setTaskLabel } = useContext(PartnerCommsContext);

  const [commsTypeId, setCommsTypeId] = useState<number | undefined>(undefined);

  const [openNewComms, setOpenNewComms] = useState(false);

  const [allTaskLabels, setAllTaskLabels] = useState<{ title: string }[]>([]);
  const [selectedTaskLabel, setSelectedTaskLabel] = useState<{ title: string }>({ title: "" });

  const ListCommunicationTypes = async () => {
    return useFetch<{ data: CommsType[] }>(API_ENDPOINTS.LIST_ALL_COMMUNICATION_TYPES.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_ALL_COMMUNICATION_TYPES.failureMessage,
      thenCallBack: (_res) => {
        if (_res.data?.data) setCommunicationTypes(_res.data?.data);
      },
    });
  };

  const GetAllTaskLabels = async () => {
    useFetch<{ allLabels: string[] }>(API_ENDPOINTS.GET_TASK_LABELS.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_TASK_LABELS.failureMessage,
      thenCallBack: (res) => {
        setAllTaskLabels(res.data.allLabels?.map((label) => ({ title: label })));
      },
    });
  };

  const GetTaskLabelValidForPartnerComms = async (label: string) => {
    return useFetch<{ isValid: boolean }>(API_ENDPOINTS.GET_TASK_LABEL_VALIDATION.url, "POST", {
      showSuccessToast: true,
      data: {
        businessPartnerData: selectedRow.map((row) => {
          return { businessPartnerId: row.businessPartnerId, mappingId: row.mappingId };
        }),
        taskLabel: label,
      },
      thenCallBack: (res) => {
        if (res.data?.isValid) {
          setTaskLabel({ title: label });
        } else {
          setTaskLabel({ title: "" });
          setSelectedTaskLabel({ title: "" });
        }
      },
      catchCallBack: (_err) => {
        setTaskLabel({ title: "" });
        setSelectedTaskLabel({ title: "" });
      },
    });
  };

  useEffect(() => {
    ListCommunicationTypes();
    GetAllTaskLabels();
  }, []);

  useEffect(() => {
    if (selectedTaskLabel?.title) GetTaskLabelValidForPartnerComms(selectedTaskLabel.title);
    else {
      setTaskLabel({ title: "" });
    }
  }, [selectedTaskLabel]);

  useEffect(() => {
    setSelectedCommsTypeId(commsTypeId);
  }, [commsTypeId]);

  return (
    <>
      {/* how to center a div ? */}
      <Box className="center_align_ver_horiz">
        <Card sx={{ maxWidth: 900, width: "100%", boxShadow: 3 }}>
          <CardContent>
            <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
              <Typography variant="h6" component="div">
                Choose Communication Type
              </Typography>
              <NdButtonOutlined
                variant="outlined"
                startIcon={<Add />}
                size="small"
                onClick={() => {
                  setCommsTypeId(undefined);
                  setOpenNewComms(true);
                }}
              >
                NEW COMMUNICATION
              </NdButtonOutlined>
            </Box>
            <FormControl>
              <RadioGroup
                defaultValue={"bcf"}
                onChange={(_e, val) => {
                  setCommsTypeId(Number(val));
                }}
              >
                <Stack direction={"row"} flexWrap={"wrap"} gap={2} p={2}>
                  {communicationTypes.map((commType) => (
                    <Card
                      className="vertical_center_align"
                      key={commType.id}
                      variant="outlined"
                      sx={{ p: 2, boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.08)", width: 400 }}
                    >
                      <FormControlLabel value={commType.id} control={<Radio />} label={commType.name} />
                      <Button
                        variant="text"
                        size="small"
                        sx={{ color: "primary.main" }}
                        onClick={(_e) => {
                          uiLogger(
                            uiLoggerNamesPartnerCommunication.UI_PC_BCB_BULK_MAIL_VIEW_COMMUNICATION_CLICK.functionName,
                            companyId,
                            branchCode,
                            {
                              message:
                                uiLoggerNamesPartnerCommunication.UI_PC_BCB_BULK_MAIL_VIEW_COMMUNICATION_CLICK.message,
                            }
                          );
                          setCommsTypeId(commType.id);
                          setOpenNewComms(true);
                        }}
                      >
                        VIEW
                      </Button>
                    </Card>
                  ))}
                </Stack>
              </RadioGroup>
            </FormControl>

            <Stack justifyContent="space-between" alignItems="center" p={2}>
              <FormControl fullWidth variant="outlined" sx={{ gap: 1, pb: "20px" }}>
                <Typography variant="subtitle2" sx={{ fontSize: 18 }}>
                  Label for this batch
                </Typography>

                <TaskLabelAutocomplete
                  taskLabel={selectedTaskLabel}
                  setTaskLabel={setSelectedTaskLabel}
                  allTaskLabels={allTaskLabels}
                />

                {commsTypeId && (
                  <Alert severity="info" sx={{ "*": { fontFamily: "inherit" } }}>
                    This label is for internal record keeping and will not be sent in the mails.
                  </Alert>
                )}
              </FormControl>
            </Stack>
          </CardContent>
        </Card>
      </Box>
      <NewCommunicationType
        open={openNewComms}
        setOpen={setOpenNewComms}
        communicationTypeId={commsTypeId}
        onClose={() => {
          ListCommunicationTypes();
        }}
        companyId={companyId}
        branchCode={branchCode}
        listUserEmailTemplates={listUserEmailTemplates}
      />
    </>
  );
};
