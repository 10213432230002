import {
  CheckCircle,
  Close,
  Download,
  FilterList,
  FilterListOff,
  MailOutline,
  MoreVert,
  Warning,
} from "@mui/icons-material";
import CachedIcon from "@mui/icons-material/Cached";
import PersonIcon from "@mui/icons-material/Person";
import {
  Alert,
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Buffer } from "buffer";
import saveAs from "file-saver";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { LoggedInSkeleton } from "src/Components/Common/LoggedInSkeleton";
import { getReconTopBarButtons } from "src/Components/Common/TopNavBar";
import useFetch from "src/Components/Common/useFetch";
import { userContext } from "src/Components/Contexts/userContext";
import CustomToast from "src/Components/CustomToast";
import {
  BulkMailStatusResponse,
  BusinessPartnerListForBcBeta,
  BusinessPartnersListForBcBetaRes,
  EmailTemplate,
  ListPDFTemplatesResponse,
  ListUserEmailSettingRes,
  ListUserEmailTemplateResponse,
  UseCaseType,
} from "src/entity/recon-entity/ReconInterfaces";
import {
  CounterState,
  updateBulkMailedTimeBCB,
  updateEmailResponseMsgBCB,
  updateIsSendingBulkMailsBCB,
  updateMailFailedUsersBCB,
  updateMailFailedWorkbookBCB,
  updateMailSentPartnerCountBCB,
} from "src/slices/partnerCommunication/bulkMailsSlice";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { exists } from "src/Utils/Common/Validators";
import { formatDateTime24Hours } from "src/Utils/DateUtils";
import { getBlankData } from "../BalanceConfirmationBeta/BalanceConfirmationBeta";
import { ManageCommunicationType } from "../BalanceConfirmationBeta/CommunicationTypes/CommunicationTypes";
import PartnerRequests, { AllIssues } from "../BalanceConfirmationBeta/PartnerRequests";
import ReviewCloseAndResetTasksModal from "../BalanceConfirmationBeta/ReviewCloseAndResetTasksModal";
import DownloadReport, { ReportDetailsCustomisationRes } from "../Common/DownloadReport";
import BulkMailReportDialog from "../CommonLegacy/BulkMailReportDialog";
import { DEFAULT_BRANCH_CODE, DEFAULT_COMPANY_ID } from "../CommonLegacy/CommonComponents";
import { PartnerCommsContext } from "../Context/PartnerCommunicationContext";
import { RaisedIssueBc } from "../LedgerRequestPortal/CommonComponents";
import { NdAsyncButton, NdButton, NdButtonOutlined } from "../MsmePartnerPortal/CommonComponents";
import { TabPanel } from "./Common/Components";
import FilterAutocomplete from "./Common/FilterAutocomplete";
import ViewReplyMailThread from "./Common/ViewReplyMailThread";
import BcNewContext, { IBcNewContext, TABS_MAP } from "./Components/BcNewContext";
import BalanceConfirmationNewFilter, { keys } from "./Components/BcNewFilter";
import Mailing from "./Components/Mailing/Mailing";
import AllMailsTab from "./Components/Tabs/AllMailsTab";
import ResponseNotReceivedTab from "./Components/Tabs/ResponseNotReceivedTab";
import ResponseReceivedTab from "./Components/Tabs/ResponseReceivedTab";

let intervalId: ReturnType<typeof setInterval> = null;
// const newIntervalId: ReturnType<typeof setInterval> = null;

const AuditCommunication = () => {
  const location = useLocation<any>();
  const { actor } = useContext(userContext);
  const dispatch = useDispatch();

  const {
    isSendingBulkMailsBCB,
    emailResponseMsgBCB,
    mailFailedUsersBCB,
    mailFailedWorkbookBCB,
    mailSentPartnerCountBCB,
    bulkMailedTimeBCB,
  } = useSelector((state: any) => state.bulkMailsStatus as CounterState);

  const [tabValue, setTabValue] = useState(0);
  const [tabChipLabel, setTabChipLabel] = useState<{ [k in keyof typeof TABS_MAP]: number }>({
    responseReceived: 0,
    responseNotReceived: 0,
    allMails: 0,
  });

  const [loader, setLoader] = useState<boolean>(true);
  const [rowsDataBcBeta, setRowsDataBcBeta] = useState<BusinessPartnerListForBcBeta[]>(getBlankData());
  const [storeRowsDataBcBeta, setStoreRowsDataBcBeta] = useState<BusinessPartnerListForBcBeta[]>([]);
  const [rowsDataBcNewMailing, setRowsDataBcNewMailing] = useState<BusinessPartnerListForBcBeta[]>([]);
  const [storeRowsDataBcNewMailing, setStoreRowsDataBcNewMailing] = useState<BusinessPartnerListForBcBeta[]>([]);
  const [currentSelectedRow, setCurrentSelectedRow] = useState<BusinessPartnerListForBcBeta>(null);
  const [mailingRows, setMailingRows] = useState<BusinessPartnerListForBcBeta[]>([]);

  // Internal Page States
  // const [openBcBetaMailThread, setOpenBcBetaMailThread] = useState<boolean>(false);
  const [openBcNewMailing, setOpenBcNewMailing] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [openRaiseReq, setOpenRaiseReq] = useState<boolean>(false);
  const [raisedIssues, setRaisedIssues] = useState<AllIssues[]>([]);
  const [withoutTemplateCheck, setWithoutTemplateCheck] = useState<boolean>(false);

  // Bulk Mailing Error States
  const [openBulkMailReportDialog, setOpenBulkMailReportDialog] = useState<boolean>(false);

  const companyId = useRef<string>(DEFAULT_COMPANY_ID);
  const branchCode = useRef<string>(DEFAULT_BRANCH_CODE);

  const storeAllCategories = useRef<string[]>([]);

  const {
    openBcBetaMailing: openBcBetaMailThread,
    setOpenBcBetaMailing: setOpenBcBetaMailThread,
    setEmailTemplates,
    setEmailTemplateContent,
    setPdfTemplates,
    setUserEmailSetting,
    setEmailDisplayName,
    setFromEmailAddress,
  } = useContext(PartnerCommsContext);

  const BcContextValue: IBcNewContext = {
    tabValue,
    setTabValue,
    tabChipLabel,
    setTabChipLabel,
    loader,
    rowsDataBcBeta,
    setRowsDataBcBeta,
    storeRowsDataBcBeta,
    rowsDataBcNewMailing,
    setRowsDataBcNewMailing,
    storeRowsDataBcNewMailing,
    setStoreRowsDataBcNewMailing,
    storeAllCategories,
    currentRow: currentSelectedRow,
    setCurrentRow: setCurrentSelectedRow,
    mailingRows,
    setMailingRows,
    openBcBetaMailThread,
    setOpenBcBetaMailThread,
    openBcNewMailing: openBcNewMailing,
    setOpenBcNewMailing: setOpenBcNewMailing,
    activeStep,
    setActiveStep,
    openRaiseReq,
    setOpenRaiseReq,
    withoutTemplateCheck,
    setWithoutTemplateCheck,
    listAllBusinessPartners: () => ListAllBusinessPartners(),
    listUserEmailTemplates: (_name?: string) => ListUserEmailTemplates(_name),
    listAllBusinessPartnerRequest: () => ListAllBusinessPartnerRequest(),
    setIntervalBCBeta: () => setIntervalBCBeta(),
    companyId: companyId.current,
    branchCode: branchCode.current,
  };

  const fetchData = async () => {
    ListUserEmailTemplates("");
    getListUserEmailSetting();
    // getListUserEmailSetting();
    await ListAllBusinessPartners();
    await ListAllBusinessPartnerRequest();
    // await ListAllPresets();
  };

  useEffect(() => {
    if (!actor.integration) fetchData();
    // clear All context state on component mount
    // ResetContext();
    // eslint-disable-next-line
  }, []);

  // Main ROWs API
  const ListAllBusinessPartners = async () => {
    setLoader(true);

    return useFetch<BusinessPartnersListForBcBetaRes>(API_ENDPOINTS.LIST_ALL_MAILED_BUSINESS_PARTNERS.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_ALL_MAILED_BUSINESS_PARTNERS.failureMessage,
      config: {
        params: {
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        },
      },
      thenCallBack: (response) => {
        // Extract the businessPartnerId values and store them in a new array
        if (!response.data?.businessPartnerList)
          response.data = { businessPartnerList: getBlankData(), allCategories: ["a", "b", "c"], response: "ok" };
        // storeBcBetaData.current = response.data?.businessPartnerList || [];
        storeAllCategories.current = response.data?.allCategories || [];
        setRowsDataBcBeta(response.data.businessPartnerList || []);
        setStoreRowsDataBcBeta(response.data.businessPartnerList || []);
        setLoader(false);
        // if (Object.keys(appliedFilters).length) setReFilter(true);
        // if (rowsDataBcBeta && defaultTallyCbDate) GetErpClosingBalanceForAllBp(response.data.businessPartnersList);
      },
    });
  };

  // User Email Templates for Mailing
  const ListUserEmailTemplates = async (createTemplateName = "") => {
    return useFetch<ListUserEmailTemplateResponse>(
      API_ENDPOINTS.LIST_USER_EMAIL_TEMPLATE_PARTNER_COMMUNICATION.url,
      "GET",
      {
        failureMessage: API_ENDPOINTS.LIST_USER_EMAIL_TEMPLATE_PARTNER_COMMUNICATION.failureMessage,
        config: {
          params: {
            templateType: UseCaseType.balanceConfirmationBeta,
            companyId: companyId.current,
          },
        },
        thenCallBack: (response) => {
          const { emailTemplates, lastEmailTemplateSelected } = response.data;
          setEmailTemplates(emailTemplates);
          ListPDFTemplates();

          if (lastEmailTemplateSelected) lastEmailTemplateSelected;
          if (createTemplateName?.trim() !== "") {
            if (response.data?.emailTemplates !== null && response.data?.emailTemplates?.length > 0) {
              const filteredSelectedTemplate = response.data.emailTemplates?.filter((item: EmailTemplate) => {
                return item.templateName === createTemplateName;
              })[0];
              if (filteredSelectedTemplate) setEmailTemplateContent(filteredSelectedTemplate);
            }
          }
        },
      }
    );
  };

  // PDF Templates for Mailing
  const ListPDFTemplates = (_createTemplateName = "") => {
    return useFetch<ListPDFTemplatesResponse>(API_ENDPOINTS.LIST_PDF_TEMPLATES.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_PDF_TEMPLATES.failureMessage,
      config: {
        params: {
          templateType: UseCaseType.balanceConfirmation,
        },
      },
      thenCallBack: (response) => {
        setPdfTemplates(response.data.templates || []);
      },
    });
  };

  // User Display Name and Email Id for Mail Dialog
  const getListUserEmailSetting = async () => {
    useFetch<ListUserEmailSettingRes>(API_ENDPOINTS.LIST_USER_EMAIL_SETTING.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_USER_EMAIL_SETTING.failureMessage,
      config: {
        params: {
          userId: actor.userId,
        },
      },
      thenCallBack: (response) => {
        if (exists(response.data.userEmailSetting)) {
          setUserEmailSetting(response.data.userEmailSetting);
        }

        if (exists(response.data.userEmailSetting?.display_name)) {
          setEmailDisplayName(response.data.userEmailSetting.display_name);
        }
        if (exists(response.data.userEmailSetting?.from_email)) {
          setFromEmailAddress(response.data.userEmailSetting.from_email);
        }

        // if (exists(response.data.userEmailSetting?.signature?.image)) {
        //   setSignatureImgBase64(`data:image/*;base64,${response.data.userEmailSetting.signature.image}`);
        // }
        // if (exists(response.data.userEmailSetting?.signature?.text)) {
        //   setSignature(response.data.userEmailSetting.signature.text);
        // }
      },
    });
  };

  // List All Business Partner Request
  const ListAllBusinessPartnerRequest = () => {
    // setLoadingPartnerRequests(true);
    return useFetch<{ requestMap: Record<string, RaisedIssueBc[]> }>(
      API_ENDPOINTS.LIST_ALL_BUSINESS_PARTNER_REQUESTS.url,
      "GET",
      {
        failureMessage: API_ENDPOINTS.LIST_ALL_BUSINESS_PARTNER_REQUESTS.failureMessage,
        config: {
          params: {
            companyId: companyId.current || DEFAULT_COMPANY_ID,
            branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          },
        },
        thenCallBack: (_res) => {
          // setLoadingPartnerRequests(false);
          const { requestMap } = _res.data;
          if (Object.keys(requestMap)?.length > 0) {
            setRaisedIssues(
              Object.keys(requestMap)?.map((item) => {
                const { businessPartnerName, vendorCode } = JSON.parse(item);
                return {
                  businessPartnerName: businessPartnerName,
                  vendorCode: vendorCode,
                  issues: requestMap[item],
                } as AllIssues;
              })
            );
          } else setRaisedIssues([]);
        },
        catchCallBack: () => {
          // setLoadingPartnerRequests(false);
        },
      }
    );
  };

  // Mark Bp Issue Resolved
  const markIssueResolved = async (issueId: number) => {
    return new Promise<{ success: boolean }>((resolve, reject) =>
      useFetch(API_ENDPOINTS.MARK_ISSUE_AS_RESOLVED.url, "POST", {
        failureMessage: API_ENDPOINTS.MARK_ISSUE_AS_RESOLVED.failureMessage,
        showSuccessToast: true,
        data: {
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          issueId,
        },
        thenCallBack: (_res) => {
          ListAllBusinessPartnerRequest();
          resolve({ success: true });
        },
        catchCallBack: reject,
        errorCallback: reject,
      })
    );
  };

  // Bulk Mail Status
  const getBulkMailStatus = async () => {
    return new Promise<{ isCompleted: boolean }>((resolve, reject) => {
      useFetch<{
        emailResponseMessage: string;
        isCompleted: boolean;
        listOfFailedUsers: { reason: string; businessPartnerName: string; businessPartnerId: number }[];
        failedEmailsWorkbook: string;
        partnerCount: number;
        timeStamp: Date;
      }>(API_ENDPOINTS.GET_BULK_EMAIL_STATUS.url, "GET", {
        failureMessage: API_ENDPOINTS.GET_BULK_EMAIL_STATUS.failureMessage,
        config: {
          params: {
            companyId: companyId.current || DEFAULT_COMPANY_ID,
            branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          },
        },
        thenCallBack: (res) => {
          if (res.data.isCompleted && res.data.partnerCount === 0) {
            resolve({ isCompleted: true });
            // Stop the interval and cleanup
            if (intervalId !== null) {
              clearInterval(intervalId);
              intervalId = null;
            }
            return;
          }

          dispatch(updateBulkMailedTimeBCB(res.data.timeStamp));
          dispatch(updateMailSentPartnerCountBCB(res.data.partnerCount));
          if (res.data.isCompleted) {
            ListAllBusinessPartners();

            if (res.data?.emailResponseMessage !== "") {
              toast.success(<CustomToast message={res.data.emailResponseMessage} />);
            }
            resolve({ isCompleted: true });
            // Stop the interval and cleanup
            if (intervalId !== null) {
              clearInterval(intervalId);
              intervalId = null;
            }
            dispatch(updateIsSendingBulkMailsBCB(false));

            if (res.data.listOfFailedUsers && res.data.listOfFailedUsers?.length) {
              const listOfFailedUsers: BulkMailStatusResponse["listOfFailedUsers"] = [];
              res.data.listOfFailedUsers.forEach((failedUser) => {
                const found = listOfFailedUsers.find((user) => user.reason === failedUser.reason);
                if (found) {
                  found.businessPartnerNames.push(failedUser.businessPartnerName);
                } else
                  listOfFailedUsers.push({
                    reason: failedUser.reason,
                    businessPartnerNames: [failedUser.businessPartnerName],
                  });
              });
              dispatch(updateEmailResponseMsgBCB(res.data.emailResponseMessage || ""));
              dispatch(updateMailFailedUsersBCB(listOfFailedUsers || []));
              dispatch(updateMailFailedWorkbookBCB(res.data?.failedEmailsWorkbook || ""));
              // reload templates after mail sent
              ListUserEmailTemplates("");
            } else dispatch(updateMailFailedUsersBCB([]));
          } else {
            dispatch(updateIsSendingBulkMailsBCB(true));
            resolve({ isCompleted: res.data.isCompleted });
          }
        },
        catchCallBack: () => {
          clearInterval(intervalId);
          intervalId = null;
          dispatch(updateIsSendingBulkMailsBCB(false));
          reject();
        },
      });
    });
  };

  const setIntervalBCBeta = () => {
    intervalId = setInterval(async () => {
      const { isCompleted } = await getBulkMailStatus();
      if (isCompleted) {
        // Bulk email operation is complete, stop the interval and cleanup
        clearInterval(intervalId);
      }
    }, 30_000);
  };

  // Bulk Mail Status Start
  useEffect(() => {
    if (actor.integration === false) {
      getBulkMailStatus();
      // getSyncMailStatus();
      if (!intervalId) {
        setIntervalBCBeta();
      }
      // if (!newIntervalId) {
      //   setIntervalMailSyncStatus();
      // }
    }
  }, []);

  const noInternalPagesOpen = !openBcBetaMailThread && !openBcNewMailing;
  const openBcNewMailPage = openBcNewMailing && !openBcBetaMailThread;

  return (
    <>
      <LoggedInSkeleton topBarButtons={getReconTopBarButtons(" ", actor.name, location?.state?.openCollapseObj, actor)}>
        <BcNewContext.Provider value={BcContextValue}>
          {/* Main Page */}
          {noInternalPagesOpen && (
            <Stack gap={1}>
              {/* Bulk Mailing Bar */}
              {noInternalPagesOpen && (isSendingBulkMailsBCB || mailSentPartnerCountBCB > 0) && (
                <Alert
                  color={isSendingBulkMailsBCB ? "info" : mailFailedUsersBCB.length > 0 ? "error" : "success"}
                  icon={
                    isSendingBulkMailsBCB ? (
                      <CircularProgress size={16} />
                    ) : mailFailedUsersBCB.length > 0 ? (
                      <Warning />
                    ) : (
                      <CheckCircle />
                    )
                  }
                  sx={{
                    mb: 2,
                    border: "1px solid #0288D1",
                    borderColor: isSendingBulkMailsBCB
                      ? "#0288D1"
                      : mailFailedUsersBCB.length > 0
                      ? "#D32F2F"
                      : "#2E7D32",
                    alignItems: "center",
                  }}
                  action={
                    !isSendingBulkMailsBCB && (
                      <Box className="right">
                        {mailFailedUsersBCB.length > 0 && (
                          <Button
                            variant="text"
                            onClick={() => {
                              setOpenBulkMailReportDialog(true);
                            }}
                          >
                            View Issues
                          </Button>
                        )}
                        <IconButton
                          onClick={() => {
                            dispatch(updateEmailResponseMsgBCB(""));
                            dispatch(updateMailFailedUsersBCB([]));
                            dispatch(updateMailFailedWorkbookBCB(""));
                            dispatch(updateMailSentPartnerCountBCB(0));
                            dispatch(updateBulkMailedTimeBCB(null));
                          }}
                          sx={{ ml: "auto" }}
                        >
                          <Close />
                        </IconButton>
                      </Box>
                    )
                  }
                >
                  <Box className="d_flex" sx={{ alignItems: "center" }}>
                    <Typography className="vertical_center_align" fontWeight="medium" fontSize={16}>
                      {isSendingBulkMailsBCB
                        ? `Sending mail to ${mailSentPartnerCountBCB} Partners | Initiated on: 
                                      ${formatDateTime24Hours(bulkMailedTimeBCB)}`
                        : mailFailedUsersBCB.length > 0
                        ? `Action required for ${mailSentPartnerCountBCB} Partners`
                        : `Bulk Email Sent to ${mailSentPartnerCountBCB} Partners | Sent on:   ${formatDateTime24Hours(
                            bulkMailedTimeBCB
                          )}`}
                    </Typography>
                  </Box>
                </Alert>
              )}

              <HeaderSection raisedIssues={raisedIssues} />

              {/* Main Content */}
              <Card className="d_flex" sx={{ minHeight: 480, flexFlow: "column", borderRadius: "8px !important" }}>
                <TabPanel value={tabValue} index={TABS_MAP.responseReceived.index} transition>
                  <ResponseReceivedTab />
                </TabPanel>
                <TabPanel value={tabValue} index={TABS_MAP.responseNotReceived.index} transition>
                  <ResponseNotReceivedTab />
                </TabPanel>
                <TabPanel value={tabValue} index={TABS_MAP.allMails.index} transition>
                  <AllMailsTab />
                </TabPanel>
              </Card>
            </Stack>
          )}
          {/* <MailThread /> */}
          {openBcBetaMailThread && <ViewReplyMailThread setOpen={setOpenBcBetaMailThread} />}
          {/* <NewMail /> */}
          {openBcNewMailPage && <Mailing setOpen={setOpenBcNewMailing} />}

          <PartnerRequests
            open={openRaiseReq}
            setOpen={setOpenRaiseReq}
            allIssues={raisedIssues}
            markIssueResolved={markIssueResolved}
            setSendSoleOrBulkMail={(_val) => null}
            setWithoutTemplateCheck={setWithoutTemplateCheck}
            rowsDataBcBeta={rowsDataBcBeta}
            useCaseType={UseCaseType.balanceConfirmationBeta}
          />

          {/* Bulk Mailing Error Components */}
          <BulkMailReportDialog
            open={openBulkMailReportDialog}
            setOpen={setOpenBulkMailReportDialog}
            emailResponseMsg={emailResponseMsgBCB}
            mailFailedUsers={mailFailedUsersBCB}
            workbook={mailFailedWorkbookBCB}
          />
        </BcNewContext.Provider>
      </LoggedInSkeleton>
    </>
  );
};

const HeaderSection: React.FC<{ raisedIssues: AllIssues[] }> = ({ raisedIssues }) => {
  const { actor } = useContext(userContext);
  const {
    companyId,
    branchCode,
    setOpenBcNewMailing,
    mailingRows,
    rowsDataBcBeta,
    setRowsDataBcBeta,
    listAllBusinessPartners,
    listUserEmailTemplates,
    storeRowsDataBcBeta,
    storeAllCategories,
  } = useContext(BcNewContext);

  // Customization States
  const [customizationSetting, setCustomizationSetting] = useState<ReportDetailsCustomisationRes>({} as any);

  // Top More Menu States
  const [openEditCommsType, setOpenEditCommsType] = useState<boolean>(false);
  const [openDownloadReportDlg, setOpenDownloadReportDlg] = useState<boolean>(false);
  const [isHistoryReport, setIsHistoryReport] = useState<boolean>(false);
  const [downloadBcBetaReportBase64, setDownloadBcBetaReportBase64] = useState<string>("");
  const [showReviewCloseAndResetTasksModal, setShowReviewCloseAndResetTasksModal] = useState<boolean>(false);

  // Filter States
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const [appliedFilters, setAppliedFilters] = useState<Record<string, string[]>>({});
  const [reFilter, setReFilter] = useState<boolean>(false);
  const [filterObj, setFilterObj] = useState<{ [k in keys]: string[] }>(null);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Get Report Customization
  const GetReportCustomization = () => {
    return new Promise<ReportDetailsCustomisationRes>((resolve, reject) => {
      useFetch<{ customisationSetting: ReportDetailsCustomisationRes }>(
        API_ENDPOINTS.GET_EXCEL_REPORT_CUSTOMIZATION_SETTING.uri,
        "GET",
        {
          failureMessage: API_ENDPOINTS.GET_EXCEL_REPORT_CUSTOMIZATION_SETTING.failureMessage,
          thenCallBack: (res) => {
            if (res.data?.customisationSetting) {
              setCustomizationSetting(res.data?.customisationSetting);
              resolve(res.data?.customisationSetting);
            }
          },
          catchCallBack: reject,
          errorCallback: reject,
        }
      );
    });
  };

  // Get BC Beta Report
  const getDownloadBcBetaReport = (customization: ReportDetailsCustomisationRes) => {
    return useFetch<{ base64string: string }>(API_ENDPOINTS.GET_BALANCE_CONFIRMATION_REPORT.url, "POST", {
      failureMessage: API_ENDPOINTS.GET_BALANCE_CONFIRMATION_REPORT.failureMessage,
      data: {
        companyId: companyId || DEFAULT_COMPANY_ID,
        branchCode: branchCode || DEFAULT_BRANCH_CODE,
        customisation: customization,
      },
      thenCallBack: (response) => {
        setDownloadBcBetaReportBase64(response.data?.base64string);
        downloadBcBetaReport(response.data?.base64string);
      },
    });
  };

  // Download BcBeta Rows Report
  const downloadBcBetaReport = (reportBase64: string) => {
    const excelData = Buffer.from(reportBase64 || downloadBcBetaReportBase64, "base64");
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const blob = new Blob([excelData], { type: fileType });
    saveAs(blob, `${actor.name} Partner Communication Report.xlsx`);
  };

  // Download Past Communication Report
  const downloadPastCommunicationReport = (customization: ReportDetailsCustomisationRes) => {
    return useFetch<{ base64string: string }>(API_ENDPOINTS.DOWNLOAD_EXCEL_REPORT_FOR_HISTORY.url, "POST", {
      failureMessage: API_ENDPOINTS.DOWNLOAD_EXCEL_REPORT_FOR_HISTORY.failureMessage,
      data: {
        companyId: companyId || DEFAULT_COMPANY_ID,
        branchCode: branchCode || DEFAULT_BRANCH_CODE,
        customisation: customization,
      },
      thenCallBack: (response) => {
        const { base64string } = response.data;
        if (base64string) {
          const excelData = Buffer.from(base64string, "base64");
          const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          const blob = new Blob([excelData], { type: fileType });
          saveAs(blob, `${actor.name} Past Communication.xlsx`);
        }
      },
    });
  };

  // Bulk Close And Reset Tasks
  const closeAndResetTasks = async (taskDetails) => {
    await useFetch(API_ENDPOINTS.CLOSE_TICKET_AND_RESET_COMMUNICATION.url, "POST", {
      failureMessage: API_ENDPOINTS.CLOSE_TICKET_AND_RESET_COMMUNICATION.failureMessage,
      data: { taskData: taskDetails, companyId: companyId, branchCode: branchCode },
      showSuccessToast: true,
      thenCallBack: () => {
        listAllBusinessPartners();
      },
      catchCallBack: () => {
        listAllBusinessPartners();
      },
    });
  };

  return (
    <Paper
      elevation={1}
      sx={{
        width: "100%",
        minWidth: "600px",
        height: "auto",
        borderRadius: "8px",
        position: "sticky",
        top: "-25px",
        zIndex: 10,
      }}
    >
      {/* Top Area */}
      <Box
        className="space_between main_header"
        sx={{
          padding: "24px",
        }}
      >
        <Typography variant="h5">Ongoing Communication</Typography>

        <Box className="vertical_center_align" gap={2}>
          <NdAsyncButton
            sx={{ minWidth: "auto", ".MuiButton-startIcon": { mx: "auto" } }}
            buttonComponent={NdButtonOutlined}
            onClick={async () => {
              const customization = await GetReportCustomization();
              if (customization || customizationSetting)
                await getDownloadBcBetaReport(customization || customizationSetting);
            }}
            startIcon={<Download sx={{ mx: "auto", fontSize: "24px !important" }} />}
            loadingIcon={<CircularProgress color="inherit" size={24} />}
          />
          <NdButtonOutlined startIcon={<MoreVert />} onClick={handleClick}>
            More
          </NdButtonOutlined>
          {/* More Menu */}
          <Menu anchorEl={anchorEl} open={openMenu} onClose={handleClose}>
            {/* Customize Report */}
            <MenuItem
              onClick={() => {
                setIsHistoryReport(false);
                setOpenDownloadReportDlg(true);

                handleClose();
              }}
            >
              Customize Report
            </MenuItem>
            {/* Manage Communication Type */}
            <MenuItem
              onClick={() => {
                setOpenEditCommsType(true);

                handleClose();
              }}
            >
              Manage Communication Type
            </MenuItem>
            {/* Download Past Report */}
            <MenuItem
              onClick={() => {
                setIsHistoryReport(true);
                setOpenDownloadReportDlg(true);

                handleClose();
              }}
            >
              Download Past Report
            </MenuItem>
            <Divider />
            {/* Close Task */}
            <MenuItem
              disabled={mailingRows.length < 1}
              onClick={() => {
                setShowReviewCloseAndResetTasksModal(true);
                handleClose();
              }}
              sx={{ color: (t) => t.palette.error.main }}
            >
              Close Task
            </MenuItem>
          </Menu>
          <NdButtonOutlined
            startIcon={isFilterApplied ? <FilterList /> : <FilterListOff />}
            onClick={() => setOpenFilter(true)}
          >
            Filter
          </NdButtonOutlined>
          <NdButton variant="contained" startIcon={<MailOutline />} onClick={() => setOpenBcNewMailing(true)}>
            Send New Mail
          </NdButton>
        </Box>
      </Box>

      {Object.keys(appliedFilters).length > 0 && (
        <Tabs value={false} onChange={() => null} variant="scrollable" scrollButtons="auto">
          <Stack direction="row" spacing={2} className="space_between" sx={{ px: 3 }}>
            <Box className="d_flex vertical_center_align">
              {Object.keys(appliedFilters).map((key) =>
                appliedFilters[key].length ? (
                  <Box sx={{ mr: 2 }} key={key}>
                    <FilterAutocomplete
                      filterName={key}
                      filteredValues={appliedFilters[key]}
                      setFilterObj={setFilterObj}
                      setReFilter={setReFilter}
                    />
                  </Box>
                ) : null
              )}
            </Box>
          </Stack>
        </Tabs>
      )}

      <Divider sx={{}} />

      {/* Bottom Area */}
      <TabsComponent raisedIssues={raisedIssues} />

      {/* Top More Menu Components */}
      <ManageCommunicationType
        open={openEditCommsType}
        setOpen={setOpenEditCommsType}
        companyId={companyId}
        branchCode={branchCode}
        listUserEmailTemplates={listUserEmailTemplates}
      />

      <DownloadReport
        open={openDownloadReportDlg}
        setOpen={setOpenDownloadReportDlg}
        downloadAction={async (customization) =>
          isHistoryReport
            ? await downloadPastCommunicationReport(customization)
            : await getDownloadBcBetaReport(customization)
        }
      />

      {showReviewCloseAndResetTasksModal && (
        <ReviewCloseAndResetTasksModal
          open={showReviewCloseAndResetTasksModal}
          setOpen={setShowReviewCloseAndResetTasksModal}
          selectedTasks={mailingRows}
          closeAndResetTasks={closeAndResetTasks}
          companyId={companyId}
          branchCode={branchCode}
          setClearRows={() => null} //TODO: Implement this
        />
      )}

      <BalanceConfirmationNewFilter
        allCategories={storeAllCategories.current}
        storeRowsDataBalanceConfirmation={storeRowsDataBcBeta}
        rowsDataBalanceConfirmation={rowsDataBcBeta}
        setRowsDataBalanceConfirmation={setRowsDataBcBeta}
        setAppliedFilters={setAppliedFilters}
        setIsFilterApplied={setIsFilterApplied}
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        reFilter={reFilter}
        setReFilter={setReFilter}
      />
    </Paper>
  );
};

const TabsComponent = ({ raisedIssues }: { raisedIssues: AllIssues[] }) => {
  const { tabValue, setTabValue, tabChipLabel, setOpenRaiseReq, listAllBusinessPartnerRequest } =
    useContext(BcNewContext);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box className="vertical_center_align" sx={{ pl: 1, pr: 2 }}>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        sx={{
          minHeight: 44,
          "& .MuiTabs-indicator": {
            backgroundColor: "#531c4c",
          },
          "& .MuiChip-root": {
            color: "#fff",
            bgcolor: "#0009",
            px: "6.5px",
            py: 0,
          },
          "& .Mui-selected": {
            color: "#531c4c !important",
            "& .MuiChip-root": {
              bgcolor: "#531c4c",
            },
          },
        }}
      >
        {Object.entries(TABS_MAP).map(([key, { label }]) => (
          <Tab
            key={key}
            label={label}
            icon={<Chip size="small" label={tabChipLabel[key]} />}
            iconPosition="end"
            sx={{ textTransform: "none", minHeight: "auto" }}
          />
        ))}
      </Tabs>
      {/* Partner Requests Async Button */}
      <NdAsyncButton
        onClick={async () => {
          await listAllBusinessPartnerRequest();
          setOpenRaiseReq(true);
        }}
        buttonComponent={Button}
        loadingIcon={
          <span style={{ marginRight: -6 }}>
            <LoadingIcon loading={true} />
          </span>
        }
        startIcon={<PersonIcon />}
        endIcon={
          <Chip
            label={raisedIssues?.length || 0}
            color="warning"
            size="small"
            sx={{ borderRadius: "100px", width: 24 }}
          />
        }
        color="warning"
        sx={{ ml: "auto" }}
      >
        Partner Issues
      </NdAsyncButton>
      <IconButton color="info">
        <CachedIcon />
      </IconButton>
    </Box>
  );
};

export default AuditCommunication;
