import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import HistoryIcon from "@mui/icons-material/History";
import { Box, Button, IconButton, Menu, MenuItem, Select, TextField, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import CustomToast from "src/Components/CustomToast";
import { TicketFollowUpType } from "src/entity/recon-entity/ReconInterfaces";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { formatDate, formatDateTime24Hours } from "src/Utils/DateUtils";
import { uiLoggerNamesWorkflow } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { NdButton } from "../PartnerCommunication/MsmePartnerPortal/CommonComponents";
import { Recon360Context } from "../Recon360";
import "./CurrentTicket.scss";
import DiscardTicketModal from "./DiscardTicketModal";
import SaveTicketModal from "./SaveTicketModal";
import { renderGroupedOptions } from "./ViewTickets";

export enum TicketStatuses {
  IssueInLedger = "Issue in Ledger",
  CheckerRejected = "Checker Rejected",
  ApproverRejected = "Approver Rejected",
  TechnicalIssue = "Technical Issue",
  Saved = "Saved",
  Discarded = "Discarded",
}

const mappingForUiLogStatuses = {
  "Both Side Ledger Pending": uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_BOTH_SIDE_LEDGER_PENDING_CLICK,
  "Partner Ledger Pending": uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_PARTNER_LEDGER_PENDING_CLICK,
  "Own Ledger Pending": uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_OWN_LEDGER_PENDING_CLICK,
  "Issue in Ledger": uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_ISSUE_IN_LEDGER_CLICK,
  "Yet to Start": uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_YET_TO_START_CLICK,
  "Recon in Progress": uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_RECON_IN_PROGRESS_CLICK,
  "Maker Passed": uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_MAKER_PASSED_CLICK,
  "Checker Passed": uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_CHECKER_PASSED_CLICK,
  "Checker Rejected": uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_CHECKER_REJECTED_CLICK,
  "Sent to Approver": uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_SENT_TO_APPROVER_CLICK,
  "Approver Passed": uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_APPROVER_PASSED_CLICK,
  "Approver Rejected": uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_APPROVER_REJECTED_CLICK,
  "Technical Issue": uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_TECHNICAL_ISSUE_CLICK,
  Dropped: uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_DROPPED_CLICK,
  Saved: uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_SAVE_TICKET_CLICK,
  Discarded: uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_DISCARD_TICKET_CLICK,
  "Recon Sent to Partner": uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_RECON_SENT_TO_PARTNER_CLICK,
  "Work in Progress- Action items": uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_WORK_IN_PROGRESS_ACTION_ITEMS_CLICK,
  "Sign-off Pending": uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_SIGNOFF_PENDING_WITH_PARTNER_CLICK,
  "Signed-off with partner": uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_SIGNED_OFF_WITH_PARTNER_CLICK,
  "On-Hold": uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_ON_HOLD_CLICK,
  "Recon Finished by user": uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_RECON_FINISHED_BY_USER_CLICK,
  "Balance Confirmed": uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_BALANCE_CONFIRMED_CLICK,
};
export const CurrentTicket = (props) => {
  const {
    companyId,
    branchCode,
    businessPartnerSelected,
    showSaveTicketModal,
    setShowSaveTicketModal,
    showDiscardTicketModal,
    setShowDiscardTicketModal,
    currentSelectedTaskDetails,
    assignBusinessPartnerNameRef,
  } = useContext(Recon360Context);

  const [currentStatus, setCurrentStatus] = useState<string>(props.ticket.status);
  const [currentStatusId, setCurrentStatusId] = useState<number>(props.ticket.statusId);
  const [remarks, setRemarks] = useState<string>(props.ticket.remarks);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [anchorElDispute, setAnchorElDispute] = React.useState(null);
  const openDispute = Boolean(anchorElDispute);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  function handleClose() {
    setAnchorEl(null);
  }

  function handleClickDispute(event) {
    setAnchorElDispute(event.currentTarget);
  }
  function handleCloseDispute() {
    setAnchorElDispute(null);
  }

  function handlefollowUpSelect(e) {
    handleClose();
    useFetch(API_ENDPOINTS.ADD_TASK_FOLLOW_UP.url, "POST", {
      failureMessage: API_ENDPOINTS.ADD_TASK_FOLLOW_UP.failureMessage,
      data: {
        ticketId: props.ticket.id,
        mappingId: props.mappingId,
        followUpType: e.target.value === 0 ? TicketFollowUpType.Mail : TicketFollowUpType.Conversation,
      },
      thenCallBack: (response) => {
        props.fetchTickets();
        props.setTicket(response.data.ticketDetails);
        e.target.value === 0
          ? toast.success(<CustomToast message="Successfully updated mail follow up" />)
          : toast.success(<CustomToast message="Successfully updated conversation follow up" />);
      },
      catchCallBack: () => {
        e.target.value === 0
          ? toast.error(<CustomToast message="Error in updating mail follow up" />)
          : toast.error(<CustomToast message="Error in updating conversation follow up" />);
      },
    });
  }

  const [saveRemarksDisabled, setSaveRemarksDisabled] = useState<boolean>(true);

  const [isSavingRemark, setIsSavingRemark] = useState(false);
  const [isStatusUpdating, setIsStatusUpdating] = useState(false);

  const updateTicketRemarks = () => {
    setIsSavingRemark(true);
    useFetch(API_ENDPOINTS.UPDATE_TASK_REMARKS.url, "POST", {
      failureMessage: API_ENDPOINTS.UPDATE_TASK_REMARKS.failureMessage,
      data: {
        ticketId: props.ticket.id,
        mappingId: props.mappingId,
        remarks: remarks,
      },
      thenCallBack: () => {
        setIsSavingRemark(false);
        toast.success(<CustomToast message="Remark Saved" />);
        props.fetchTickets();
        setSaveRemarksDisabled(true);
      },
      catchCallBack: () => {
        setIsSavingRemark(false);
        setRemarks(props.ticket.remarks ? props.ticket.remarks : "");
        toast.error(<CustomToast message="Unable to save remark" />);
      },
    });
  };
  const updateTaskMetaStatus = (status: string, taskDetails: { taskId: number; mappingId: number }) => {
    setIsStatusUpdating(true);
    uiLogger(mappingForUiLogStatuses[status].functionName, companyId.current, branchCode.current, {
      message: mappingForUiLogStatuses[status].functionName,
      businessPartnerId: businessPartnerSelected,
      ticketStatus: status,
      idOfTicket: taskDetails.taskId,
    });
    useFetch("/api/tickets/UpdateTicketMetaStatus", "POST", {
      showSuccessToast: true,
      data: {
        ticketId: taskDetails.taskId,
        mappingId: taskDetails.mappingId,
        metaStatus: status,
      },
      thenCallBack: () => {
        props.fetchTickets();
        setShowSaveTicketModal(false);
        setShowDiscardTicketModal(false);
        setIsStatusUpdating(false);
        props.setTicket(null);
      },
      catchCallBack: () => {
        props.fetchTickets();
        setShowSaveTicketModal(false);
        setIsStatusUpdating(false);
        setShowDiscardTicketModal(false);
      },
    });
  };
  const changeStatus = (statusId: number, ticketId: number, statusName: string) => {
    try {
      const uiLogFunctionName =
        mappingForUiLogStatuses[statusName]?.functionName ||
        uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_CUSTOM_STATUS_CLICK.functionName;
      const uiLogMessage =
        mappingForUiLogStatuses[statusName]?.message ||
        uiLoggerNamesWorkflow.UI_WF_IT_RECON_STATUS_CUSTOM_STATUS_CLICK.message;
      uiLogger(uiLogFunctionName, companyId.current, branchCode.current, {
        message: uiLogMessage,
        businessPartnerId: businessPartnerSelected,
        ticketStatus: statusName,
        idOfTicket: ticketId,
        statusId: statusId,
      });
    } catch (error) {
      console.log(error);
    }

    useFetch(API_ENDPOINTS.UPDATE_TICKET_STATUS.url, "POST", {
      failureMessage: API_ENDPOINTS.UPDATE_TICKET_STATUS.failureMessage,
      data: {
        ticketId,
        mappingId: props.mappingId,
        statusId,
      },
      thenCallBack: () => {
        setShowSaveTicketModal(false);
        setShowDiscardTicketModal(false);
        props.fetchTickets();
        toast.success(<CustomToast message="Successfully changed ticket status" />);
      },
      catchCallBack: (error) => {
        const message = error?.response?.data?.message || "Error in changing ticket status";
        toast.error(<CustomToast message={message} />);
        setShowSaveTicketModal(false);
        setShowDiscardTicketModal(false);
      },
    });
  };

  return (
    <>
      {showSaveTicketModal && (
        <SaveTicketModal
          showSaveTicketModal={showSaveTicketModal}
          setShowSaveTicketModal={setShowSaveTicketModal}
          changeStatus={updateTaskMetaStatus}
          taskDetails={{ taskId: props.ticket.id, mappingId: props.mappingId }}
          isStatusUpdating={isStatusUpdating}
        />
      )}
      {showDiscardTicketModal && (
        <DiscardTicketModal
          showDiscardTicketModal={showDiscardTicketModal}
          setShowDiscardTicketModal={setShowDiscardTicketModal}
          changeStatus={updateTaskMetaStatus}
          taskDetails={{ taskId: props.ticket.id, mappingId: props.mappingId }}
          isStatusUpdating={isStatusUpdating}
        />
      )}
      <div className="current-ticket">
        <div className="space_between current-ticket-header">
          <div className="ticket_header_left" style={{ display: "flex", alignItems: "center", color: "#232323" }}>
            <span className="fs_24 fw_700" style={{ marginLeft: "10px" }}>
              Current Recon Task
            </span>
            <Button
              variant="outlined"
              className="view_history fs_13 fw_500"
              sx={{
                color: "rgba(0, 0, 0, 0.87)",
                borderColor: "rgba(0, 0, 0, 0.87)",
                borderRadius: "4px !important",
                "&:hover, &.Mui-focusVisible": { borderColor: "rgba(0, 0, 0, 0.87)" },
              }}
              onClick={() => {
                currentSelectedTaskDetails.current = {
                  businessPartnerName: assignBusinessPartnerNameRef.current,
                  taskId: props.ticket.id,
                  mappingId: props.mappingId,
                };
                props.setShowTasksHistoryModal(true);
              }}
            >
              <HistoryIcon />
              <span className="fw_600 fs_13">VIEW HISTORY</span>
            </Button>
          </div>
          <div style={{ display: "flex", margin: "10px" }}>
            <Box sx={{ display: "flex", alignItems: "center", fontWeight: 500, fontSize: "20px", marginX: "20px" }}>
              Add Follow Up
              <IconButton onClick={handleClick}>
                <AddCircleOutlinedIcon fontSize="large" sx={{ color: "#45173f" }} />
              </IconButton>
              <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem
                  value={0}
                  onClick={(e) => {
                    uiLogger(
                      uiLoggerNamesWorkflow.UI_WF_IT_ADD_FOLLOW_UP_MAIL_CLICK.functionName,
                      companyId.current,
                      branchCode.current,
                      {
                        message: uiLoggerNamesWorkflow.UI_WF_IT_ADD_FOLLOW_UP_MAIL_CLICK.message,
                        businessPartnerId: businessPartnerSelected,
                        ticketStatus: currentStatus,
                        idOfTicket: props.ticket.id,
                      }
                    );
                    handlefollowUpSelect(e);
                  }}
                >
                  +1 time for Mail
                </MenuItem>
                <MenuItem
                  value={1}
                  onClick={(e) => {
                    uiLogger(
                      uiLoggerNamesWorkflow.UI_WF_IT_ADD_FOLLOW_UP_CONVERSATION_CLICK.functionName,
                      companyId.current,
                      branchCode.current,
                      {
                        businessPartnerId: businessPartnerSelected,
                        ticketStatus: currentStatus,
                        idOfTicket: props.ticket.id,
                        message: uiLoggerNamesWorkflow.UI_WF_IT_ADD_FOLLOW_UP_CONVERSATION_CLICK.message,
                      }
                    );
                    handlefollowUpSelect(e);
                  }}
                >
                  +1 time for Conversation
                </MenuItem>
              </Menu>
            </Box>
            <div style={{ display: "flex", alignItems: "center", color: "#232323" }}>
              <span className="fs_20 fw_500" style={{ marginRight: "10px" }}>
                Recon Status:
              </span>
            </div>
            <Select
              renderValue={() => currentStatus}
              displayEmpty={true}
              style={{ width: "200px", backgroundColor: "whitesmoke", padding: 0 }}
              value={currentStatusId}
              open={openDispute}
              onOpen={handleClickDispute}
              onClose={handleCloseDispute}
              onChange={(e) => {
                const newValue = Number(e.target.value);
                const statusName = props.ticketStatuses.find((status) => status.id === newValue).status;
                setCurrentStatusId(newValue);
                setCurrentStatus(statusName);
                changeStatus(newValue, props.ticket.id, statusName);
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    "& .MuiMenu-list": {
                      paddingBottom: 0,
                      paddingTop: 0,
                      maxHeight: 500,
                    },
                  },
                },
              }}
            >
              {renderGroupedOptions(props.taskCategories, props.ticketStatuses)}
              <div
                style={{
                  position: "sticky",
                  bottom: "0",
                  backgroundColor: "#fff",
                  zIndex: 100,
                  display: "flex",
                  flexDirection: "column",
                  padding: "16px",
                  gap: "16px",
                  borderTop: "2px solid #e7e7e7",
                  marginTop: "8px",
                }}
              >
                <Button
                  variant="contained"
                  className="theme_btn fs_14 fw_500"
                  sx={{ borderRadius: "4px !important", padding: "7px 16px" }}
                  onClick={() => setShowSaveTicketModal(true)}
                >
                  SAVE/SIGN OFF TASK
                </Button>
                <Button
                  className="fs_14 fw_500"
                  variant="outlined"
                  color="error"
                  sx={{ borderRadius: "4px !important", padding: "6px 16px" }}
                  onClick={() => setShowDiscardTicketModal(true)}
                >
                  DISCARD TASK
                </Button>
              </div>
            </Select>
          </div>
        </div>
        <div
          style={{ width: "100%", display: "flex", padding: "10px", background: "whitesmoke", borderRadius: "10px" }}
        >
          <table className="current-ticket-table">
            <tr>
              <th>Task ID</th>
              <th>Task Label</th>
              <th>Maker</th>
              <th>Checker</th>
              <th>Approver</th>
              <th>Priority</th>
              <th>Due Date</th>
              <th>Creation Date</th>
              <th style={{ backgroundColor: "white" }}>Time of Last Status Change</th>
              <th>Mail Follow Ups</th>
              <th>Conversation Follow Ups</th>
            </tr>
            <tr>
              <td>{props.ticket.id ? props.ticket.id : "-"}</td>
              <td>{props.ticket.taskLabel ? props.ticket.taskLabel : "-"}</td>
              <td>{props.ticket.maker ? props.ticket.maker : "-"}</td>
              <td>{props.ticket.checker ? props.ticket.checker : "-"}</td>
              <td>{props.ticket.approver ? props.ticket.approver : "-"}</td>
              <td>{props.ticket.priority}</td>
              <td>{props.ticket.dueDate ? formatDate(props.ticket.dueDate) : "-"}</td>
              <td>{props.ticket.createdAt ? formatDate(props.ticket.createdAt) : "-"}</td>
              <td style={{ backgroundColor: "white" }}>
                {props.ticket.lastStatusChangeTime ? formatDateTime24Hours(props.ticket.lastStatusChangeTime) : "-"}
              </td>
              <td>
                <Box fontSize={15} fontWeight={600}>
                  {(props.ticket.followUpCounts?.[TicketFollowUpType?.Mail] || "0") + " Times"}
                </Box>
                <Box>
                  {props.ticket.followUpCounts?.[TicketFollowUpType?.Mail] !== 0 &&
                    formatDate(props.ticket.followUpLastTime?.[TicketFollowUpType?.Mail])}
                </Box>
              </td>
              <td>
                <Box fontSize={15} fontWeight={600}>
                  {(props.ticket.followUpCounts?.[TicketFollowUpType?.Conversation] || "0") + " Times"}
                </Box>
                <Box>
                  {props.ticket.followUpCounts?.[TicketFollowUpType?.Conversation] !== 0 &&
                    formatDate(props.ticket.followUpLastTime?.[TicketFollowUpType?.Conversation])}
                </Box>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <Box sx={{ my: 2, width: "100%" }} className="vertical_center_align">
        <Typography m={1} variant="h6">
          Remarks
        </Typography>
        <TextField
          value={remarks}
          onChange={(e) => {
            setSaveRemarksDisabled(false);
            setRemarks(e.target.value);
          }}
          sx={{ backgroundColor: "#fff", width: "100%" }}
        />
        <NdButton
          disabled={saveRemarksDisabled || isSavingRemark}
          variant="contained"
          sx={{ minWidth: "170px", marginLeft: 2, minHeight: "45px" }}
          onClick={() => {
            uiLogger(
              uiLoggerNamesWorkflow.UI_WF_IT_SAVE_REMARKS_CLICK.functionName,
              companyId.current,
              branchCode.current,
              {
                message: uiLoggerNamesWorkflow.UI_WF_IT_SAVE_REMARKS_CLICK.message,
                businessPartnerId: businessPartnerSelected,
                ticketStatus: currentStatus,
                idOfTicket: props.ticket.id,
              }
            );
            updateTicketRemarks();
          }}
        >
          <LoadingIcon loading={isSavingRemark} />
          Save Remark
        </NdButton>
      </Box>
    </>
  );
};
