/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CallMade,
  Delete,
  HistoryOutlined,
  MailOutline,
  MarkEmailReadSharp,
  MoreVert,
  Save,
} from "@mui/icons-material";
import { Box, Button, IconButton, ListItemIcon, Menu, MenuItem, Stack, TextField } from "@mui/material";
import { MRT_Row, MRT_TableInstance } from "material-react-table";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import useFetch from "src/Components/Common/useFetch";
import ReactTable, { ColDef } from "src/Components/ReactTable/ReactTable";
import TasksHistoryModal from "src/Components/Recon360/Tickets/TasksHistoryModal";
import { BusinessPartnerListForBcBeta, UseCaseType } from "src/entity/recon-entity/ReconInterfaces";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { BalanceConfirmationBetaStatuses } from "src/Utils/PartnerCommunication";
import { ModifiedPresetData } from "../../../BalanceConfirmationBeta/BalanceConfirmationBeta";
import { PageSelectModeToggle } from "../../../Common/CommonComponents";
import { DeleteTableView, SaveTableView, UpdateResponseDialog } from "../../../Common/CommonDialogs";
import { DEFAULT_BRANCH_CODE, DEFAULT_COMPANY_ID } from "../../../CommonLegacy/CommonComponents";
import { PartnerCommsContext } from "../../../Context/PartnerCommunicationContext";
import { NdAsyncButton } from "../../../MsmePartnerPortal/CommonComponents";
import ColumnDefinitions from "../../Common/ColumnDefinitions";
import {
  ColorToggleButton,
  CustomBulkActionAlert,
  GetQuickFilterOptions,
  NoCommunication,
  QuickFilterRows,
  ReceivedQF,
} from "../../Common/Components";
import BcNewContext from "../BcNewContext";
import MultipleCommunicationTypes from "../Mailing/MultipleCommunicationTypes";

const AllMailsTab = () => {
  const { setEmailBusinessPartnerId, setMappingId, setBusinessPartnerName, setCommunicationTypeId } =
    useContext(PartnerCommsContext);
  const {
    setTabChipLabel,
    rowsDataBcBeta,
    companyId,
    branchCode,
    loader,
    currentRow,
    setCurrentRow,
    setMailingRows,
    setOpenBcBetaMailThread,
    setOpenBcNewMailing,
    setActiveStep,
  } = useContext(BcNewContext);

  const [columnDefinitions, setColumnDefinitions] = useState<ColDef<BusinessPartnerListForBcBeta>[]>([]);
  const [selectedRow, setSelectedRow] = useState<BusinessPartnerListForBcBeta[]>([]);
  const [selectedQF, setSelectedQF] = useState<ReceivedQF>("all");
  const pendingRows: BusinessPartnerListForBcBeta[] = rowsDataBcBeta.filter(
    (row) => row.status !== BalanceConfirmationBetaStatuses.RequestBalanceConfirmation
  );
  const quickFilterOptions = GetQuickFilterOptions(pendingRows);

  const finalQuickFilteredRows = QuickFilterRows(pendingRows, selectedQF);

  const NO_COMMUNICATION = !loader && pendingRows.length === 0;

  //   Table States and Presets
  const [pageSelectMode, setPageSelectMode] = useState(false);
  const [tableInstance, setTableInstance] = useState<MRT_TableInstance<BusinessPartnerListForBcBeta>>(null);
  const [selectedTablePreset, setSelectedTablePreset] = useState<string>("");
  const [allTablePresets, setAllTablePresets] = useState<{ [k: string]: ModifiedPresetData }>({});
  const [tablePresetState, setTablePresetState] = useState<ModifiedPresetData>({} as ModifiedPresetData);
  const [openTablePresetDlg, setOpenTablePresetDlg] = useState<boolean>(false);
  const [openTablePresetDelDlg, setOpenTablePresetDelDlg] = useState<boolean>(false);
  const currentPresetRef = useRef<string>("");
  // States for Table
  const [openUpdateResDialog, setOpenUpdateResDialog] = useState(false);
  const [openMultipleCommsType, setOpenMultipleCommsType] = useState(false);

  //   Menu State
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const ASYNC_INTERVAL_OPs_IN_PROGRESS = false;

  const fetchData = async () => {
    await ListAllPresets();
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleTabChipLabel = useCallback(() => {
    setTabChipLabel((prev) => ({ ...prev, allMails: pendingRows.length }));
  }, [pendingRows]);

  useEffect(() => {
    handleTabChipLabel();
  }, [rowsDataBcBeta]);

  const RowActionMenuItems = ({ row, closeMenu }: { row: MRT_Row<BusinessPartnerListForBcBeta>; closeMenu: any }) => {
    return {
      // {/* Send/View Email Button */}
      sendViewMail: (
        <MenuItem
          disabled={ASYNC_INTERVAL_OPs_IN_PROGRESS}
          key={"sendViewMail"}
          onClick={async () => {
            setEmailBusinessPartnerId(row.original.businessPartnerId);
            setMappingId(row.original.mappingId);
            setBusinessPartnerName(row.original.businessPartnerName);
            setCurrentRow(row.original);
            setOpenBcBetaMailThread(true);

            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <MailOutline sx={{ fill: "#541c4c" }} />
          </ListItemIcon>
          Send/View Email
        </MenuItem>
      ),
      // {/* Update Response */}
      updatedResponse: (
        <MenuItem
          disabled={
            ASYNC_INTERVAL_OPs_IN_PROGRESS ||
            row.original.status === BalanceConfirmationBetaStatuses.RequestBalanceConfirmation
          }
          key={"updatedResponse"}
          onClick={async () => {
            // logic
            setCurrentRow(row.original);
            setOpenUpdateResDialog(true);
            // close Menu
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <CallMade sx={{ fill: "#541c4c" }} />
          </ListItemIcon>
          Update Response
        </MenuItem>
      ),
      // {/* View History */}
      viewHistory: (
        <MenuItem
          disabled={
            ASYNC_INTERVAL_OPs_IN_PROGRESS ||
            row.original.ticketId === null ||
            row.original.status === BalanceConfirmationBetaStatuses.RequestBalanceConfirmation
          }
          key={"viewHistory"}
          onClick={() => {
            currentSelectedTaskDetails.current = {
              businessPartnerName: row.original.businessPartnerName,
              taskId: row.original.ticketId,
            };
            setShowTasksHistoryModal(true);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <HistoryOutlined sx={{ fill: "#541c4c" }} />
          </ListItemIcon>
          View History
        </MenuItem>
      ),
    };
  };

  // START space for bulk tasks

  const [showTasksHistoryModal, setShowTasksHistoryModal] = useState<boolean>(false);
  const currentSelectedTaskDetails = useRef({ taskId: null, businessPartnerName: null });

  // END space for bulk tasks

  useEffect(() => {
    setMailingRows(selectedRow);
  }, [selectedRow]);

  // Use useCallback to memoize the callback function
  const handleColDefsData = useCallback((data: React.SetStateAction<ColDef<BusinessPartnerListForBcBeta>[]>) => {
    setColumnDefinitions(data); // Update state with data from child
  }, []);

  // Table State and Presets
  const ListAllPresets = async () => {
    return useFetch<{ data: { presetName: string; presetData: ModifiedPresetData }[] }>(
      API_ENDPOINTS.LIST_ALL_PRESETS.url,
      "GET",
      {
        failureMessage: API_ENDPOINTS.LIST_ALL_PRESETS.failureMessage,
        config: {
          params: {
            companyId: companyId || DEFAULT_COMPANY_ID,
            branchCode: branchCode || DEFAULT_BRANCH_CODE,
          },
        },
        thenCallBack: async (response) => {
          const { data } = response.data;
          if (data) {
            const tempData = {};
            data.forEach((preset) => {
              tempData[preset.presetName] = preset.presetData;
            });
            setAllTablePresets(tempData);

            await GetActivePreset(tempData);
          }
        },
      }
    );
  };

  const CreateOrUpdatePreset = async (
    _oldName: string,
    _name: string,
    _presetData: ModifiedPresetData,
    isUpdating: boolean
  ) => {
    return useFetch(API_ENDPOINTS.CREATE_OR_UPDATE_PRESET.url, "POST", {
      failureMessage: API_ENDPOINTS.CREATE_OR_UPDATE_PRESET.failureMessage,
      showSuccessToast: true,
      data: {
        companyId: companyId || DEFAULT_COMPANY_ID,
        branchCode: branchCode || DEFAULT_BRANCH_CODE,
        presetName: _oldName,
        presetData: _presetData,
        updateExisting: isUpdating,
        updatedPresetName: _name,
      },
      thenCallBack: async () => {
        await ListAllPresets();
      },
    });
  };

  const handlePresetAddUpdate = async (_oldName: string, _name: string, isUpdating?: boolean) => {
    if (!_name) return;
    const currentState = tableInstance?.getState();
    const { columnOrder, columnSizing, columnVisibility, columnPinning } = currentState;
    const presetData: ModifiedPresetData = { columnOrder, columnSizing, columnVisibility, columnPinning };

    await CreateOrUpdatePreset(_oldName, _name, presetData, isUpdating);
  };

  const DeletePreset = async (presetName: string) => {
    return useFetch(API_ENDPOINTS.DELETE_PRESET.url, "POST", {
      failureMessage: API_ENDPOINTS.DELETE_PRESET.failureMessage,
      showSuccessToast: true,
      data: {
        companyId: companyId || DEFAULT_COMPANY_ID,
        branchCode: branchCode || DEFAULT_BRANCH_CODE,
        presetName,
      },
      thenCallBack: async () => {
        const tempData = { ...allTablePresets };
        delete tempData[presetName];
        setAllTablePresets(tempData);
        await ListAllPresets();
      },
    });
  };

  const SaveActivePreset = async (_presetName: string) => {
    return useFetch<{ response: string; message: string }>(API_ENDPOINTS.SAVE_ACTIVE_PRESET.url, "POST", {
      failureMessage: API_ENDPOINTS.SAVE_ACTIVE_PRESET.failureMessage,
      showSuccessToast: true,
      data: {
        companyId: companyId || DEFAULT_COMPANY_ID,
        branchCode: branchCode || DEFAULT_BRANCH_CODE,
        presetName: _presetName,
      },
    });
  };

  const GetActivePreset = async (_allTablePresets: { [k: string]: ModifiedPresetData }) => {
    return useFetch<{ presetName: string }>(API_ENDPOINTS.GET_ACTIVE_PRESET.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_ACTIVE_PRESET.failureMessage,
      config: {
        params: {
          companyId: companyId || DEFAULT_COMPANY_ID,
          branchCode: branchCode || DEFAULT_BRANCH_CODE,
        },
      },
      thenCallBack: (response) => {
        const { presetName } = response.data;
        // if (!presetName || !_allTablePresets[presetName]) return;

        setSelectedTablePreset(presetName);
        setTablePresetState(_allTablePresets[presetName]);
      },
    });
  };

  return NO_COMMUNICATION ? (
    <NoCommunication />
  ) : (
    <>
      <ColumnDefinitions
        props={{
          allMailsDeps: [],
          setAllMailsDefs: handleColDefsData,
          type: "allMails",
        }}
        otherProps={{
          isSyncingMailStatus: false,
        }}
      />
      <Stack className="all_mails_section" p={2} sx={{ gap: 1 }}>
        <CustomBulkActionAlert
          alertTitle="Total Mail Sent"
          alertText="View all the mails here"
          severity="info"
          icon={<MarkEmailReadSharp fontSize="medium" sx={{ color: "#fff" }} />}
          actionNode={
            <>
              <IconButton
                aria-label="more"
                aria-expanded={openMenu ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                sx={{ my: "auto", background: "transparent" }}
              >
                <MoreVert />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem
                  disabled={selectedRow.length === 0}
                  onClick={() => {
                    // check if all rows have same communication type
                    const firstFoundCommType = selectedRow.find((row) => row.communicationTypeId);
                    const allRowsSameCommsType = selectedRow.every(
                      (row) => row?.communicationTypeId === firstFoundCommType?.communicationTypeId
                    );
                    setMailingRows(selectedRow);

                    // setCommunicateTypeId if same for all or show the dialogue for choosing communication type and set comms type
                    // then set mailing Rows based on the communication type

                    if (firstFoundCommType && allRowsSameCommsType === false) {
                      setOpenMultipleCommsType(true);
                    } else {
                      // next page in the Bulk Mailing stepper if the communication type is preset else first page to select comms type
                      setCommunicationTypeId(firstFoundCommType?.communicationTypeId || null);
                      setActiveStep(firstFoundCommType?.communicationTypeId ? 2 : 0);
                      setOpenBcNewMailing(true);
                    }
                    handleClose();
                  }}
                >
                  Send Follow-Up Mail
                </MenuItem>
              </Menu>
            </>
          }
        />

        <ColorToggleButton<ReceivedQF> allQFValues={quickFilterOptions} setSelectedQF={setSelectedQF} />

        <ReactTable
          key={JSON.stringify(tablePresetState)}
          columns={columnDefinitions}
          rows={finalQuickFilteredRows}
          setSelectedRow={setSelectedRow}
          loading={loader}
          enableGrouping={true}
          positionToolbarAlertBanner={"top"}
          // clearRowsSelection={clearRows}
          // setClearRowsSelection={setClearRows}
          additionalInitialState={{ showColumnFilters: true, ...tablePresetState }}
          internalActionsOrder={["globalFilterToggle", "filterToggle", "showHideColumns", "fullScreenToggle"]}
          initialStateColumnVisibility={{
            ticketId: false,
            businessPartnerId: false,
            emailInitiationDate: false,
            responseSubmittedDate: false,
            relationship: false,
            teamReplyDate: false,
            teamMailSentDate: false,
            taskLabel: false,
            communicationType: false,
            "Response Status": false,
            "Response Approved": false,
          }}
          renderRowActionMenuItems={({ row, closeMenu }) => [
            RowActionMenuItems({ row, closeMenu }).sendViewMail,
            RowActionMenuItems({ row, closeMenu }).updatedResponse,
            RowActionMenuItems({ row, closeMenu }).viewHistory,
          ]}
          renderAdditionalBottomToolbarCustomActions={() => {
            return (
              <PageSelectModeToggle
                pageSelectMode={pageSelectMode}
                setPageSelectMode={setPageSelectMode}
                useCaseType={UseCaseType.balanceConfirmationBeta}
                companyId={companyId}
                branchCode={branchCode}
              />
            );
          }}
          renderTopToolbarCustomActions={() => {
            return (
              <Stack direction="row" flex={1}>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <TextField
                    select
                    value={selectedTablePreset}
                    onChange={async (e) => {
                      setTablePresetState(allTablePresets[e.target.value]);
                      console.log({ allTablePresets });
                      setSelectedTablePreset(e.target.value);
                      if (!e.target.value) return;
                      await SaveActivePreset(e.target.value);
                    }}
                    SelectProps={{
                      renderValue: (_value) => selectedTablePreset,
                    }}
                    size="small"
                    label="Select Table View"
                    sx={{ minWidth: 220, ".MuiInputBase-root": { borderRadius: 1 } }}
                  >
                    <MenuItem value="">
                      <em>Default</em>
                    </MenuItem>
                    {Object.keys(allTablePresets).map((presetName, _i) => (
                      <MenuItem className="vertical_center_align" key={presetName + _i} value={presetName}>
                        {presetName}
                        <NdAsyncButton
                          variant="text"
                          size="small"
                          onClick={async (_e) => {
                            _e.stopPropagation();
                            currentPresetRef.current = presetName;
                            setOpenTablePresetDelDlg(true);
                            // return await DeletePreset(presetName);
                          }}
                          buttonComponent={IconButton}
                          sx={{ ml: "auto" }}
                        >
                          <Delete />
                        </NdAsyncButton>
                      </MenuItem>
                    ))}
                  </TextField>
                  <Button
                    startIcon={<Save />}
                    onClick={() => {
                      console.log("Save Table View", allTablePresets);
                      setOpenTablePresetDlg(true);
                      // const name = window.prompt("Enter Table View Name", tablePresetName);
                    }}
                  >
                    Save Table View
                  </Button>
                </Box>
                {/* <Box sx={{ display: "flex", ml: "auto" }}>
                    <Tooltip title="Load Mail Replies" arrow placement="left">
                      <IconButton color="info" onClick={syncMail} disabled={isSyncingMailStatus}>
                        <CachedOutlined className={isSyncingMailStatus ? "spin" : ""} />
                      </IconButton>
                    </Tooltip>
                  </Box> */}
              </Stack>
            );
          }}
          pageSelectMode={pageSelectMode}
          setTableInstance={setTableInstance}
        />
      </Stack>
      <SaveTableView
        open={openTablePresetDlg}
        setOpen={setOpenTablePresetDlg}
        initialName={selectedTablePreset}
        saveAction={async (_name) => await handlePresetAddUpdate(selectedTablePreset, _name)}
        updateAction={async (_name) => await handlePresetAddUpdate(selectedTablePreset, _name, true)}
      />
      <DeleteTableView
        open={openTablePresetDelDlg}
        setOpen={setOpenTablePresetDelDlg}
        initialName={currentPresetRef.current}
        delAction={async () => await DeletePreset(currentPresetRef.current || "")}
      />

      <UpdateResponseDialog open={openUpdateResDialog} setOpen={setOpenUpdateResDialog} link={currentRow?.portalLink} />

      <MultipleCommunicationTypes
        open={openMultipleCommsType}
        setOpen={setOpenMultipleCommsType}
        setOpenBcNewMailing={setOpenBcNewMailing}
        setActiveStep={setActiveStep}
      />

      {showTasksHistoryModal && (
        <TasksHistoryModal
          showTasksHistoryModal={showTasksHistoryModal}
          setShowTasksHistoryModal={setShowTasksHistoryModal}
          taskId={currentSelectedTaskDetails.current.taskId}
          businessPartnerName={currentSelectedTaskDetails.current.businessPartnerName}
        />
      )}
    </>
  );
};

export default AllMailsTab;
