import { Close } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import useFetch from "src/Components/Common/useFetch";
import { userContext } from "src/Components/Contexts/userContext";
import { ListUserEmailSettingRes, UseCaseType } from "src/entity/recon-entity/ReconInterfaces";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { exists } from "src/Utils/Common/Validators";
import { uiLoggerNamesPartnerCommunication } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { StateDispatch } from "../CommonLegacy/ReplyEmail";
import { PartnerCommsContext } from "../Context/PartnerCommunicationContext";
import { NdAsyncButton } from "../MsmePartnerPortal/CommonComponents";
import $ from "../MsmePartnerPortal/PartnerPortal.module.scss";

export interface CommonDialogProps {
  open: boolean;
  setOpen: StateDispatch<boolean>;
}

interface ApproveResponseDialogProps extends CommonDialogProps {
  partnerCount: number;
  approveAction: () => Promise<void>;
}

export const ApproveResponseDialog = ({ open, setOpen, partnerCount, approveAction }: ApproveResponseDialogProps) => {
  const [waiting, setWaiting] = React.useState(false);
  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} classes={{ paper: $.BR_fix }} maxWidth="sm" fullWidth>
        <DialogTitle>Have you reviewed submitted response of {partnerCount ? partnerCount : ""} partners?</DialogTitle>
        <DialogContent className={$.flex_column} sx={{ py: 2, px: 3 }}>
          <Alert severity="info">
            Response submitted will be freezed and can't be edited. Make sure you have reviewed the partner's response.
          </Alert>
        </DialogContent>
        <DialogActions>
          <NdAsyncButton
            className={$.BR_fix}
            onClick={async () => {
              setWaiting(true);
              await approveAction();
              setOpen(false);
              setWaiting(false);
            }}
            color="primary"
            buttonComponent={Button}
          >
            Yes
          </NdAsyncButton>
          <Button
            className={$.BR_fix}
            onClick={() => {
              setOpen(false);
            }}
            color="primary"
            disabled={waiting}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

interface ResetStatusDialogProps extends CommonDialogProps {
  partnerCount: number;
  partnerName: string;
  resetAction: () => Promise<void>;
}

export const ResetStatusDialog = ({
  open,
  setOpen,
  partnerCount,
  partnerName,
  resetAction,
}: ResetStatusDialogProps) => {
  const [waiting, setWaiting] = React.useState(false);

  return (
    <>
      <Dialog open={open} classes={{ paper: $.BR_fix }} maxWidth="md" fullWidth>
        <DialogTitle textTransform={"uppercase"} className="textOverflow_hidden d_flex">
          Have you reviewed{"  "}
          <h2
            style={{ font: "inherit", maxWidth: 400, display: "inline-block", paddingLeft: 6 }}
            className="textOverflow_hidden"
          >
            {partnerName ? partnerName : `all of ${partnerCount} partner`}
          </h2>
          's response?
        </DialogTitle>
        <DialogContent className={$.flex_column} sx={{ py: 2, px: 3 }}>
          <Alert severity="warning" sx={{ "*": { fontFamily: "inherit" } }}>
            This action will reset the communication status of the selected partner and cannot be reverted
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button
            className={$.BR_fix}
            onClick={() => {
              setOpen(false);
            }}
            color="primary"
            disabled={waiting}
          >
            Cancel
          </Button>
          <NdAsyncButton
            variant="contained"
            className={$.BR_fix}
            onClick={async () => {
              setWaiting(true);
              await resetAction();
              setOpen(false);
              setWaiting(false);
            }}
            color="warning"
            buttonComponent={Button}
          >
            Reset
          </NdAsyncButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

interface UpdateResponseDialogProps extends CommonDialogProps {
  link?: string;
  companyId?: string;
  branchCode?: string;
}

export const UpdateResponseDialog = ({ open, setOpen, link, ...props }: UpdateResponseDialogProps) => {
  const [waiting, setWaiting] = React.useState(false);
  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} classes={{ paper: $.BR_fix }} maxWidth="sm" fullWidth>
        <DialogTitle>Are you sure you want to Update Response?</DialogTitle>
        <DialogContent className={$.flex_column} sx={{ py: 2, px: 3 }}>
          <Alert severity="warning">You are about to update response manually in partner portal</Alert>
        </DialogContent>
        <DialogActions>
          <Button
            className={$.BR_fix}
            onClick={() => {
              uiLogger(
                uiLoggerNamesPartnerCommunication.UI_PC_BCB_UPDATE_RESPONSE_YES_CLICK.functionName,
                props.companyId,
                props.branchCode,
                { message: uiLoggerNamesPartnerCommunication.UI_PC_BCB_UPDATE_RESPONSE_YES_CLICK.message }
              );
              setWaiting(true);
              window.open(link, "_blank");
              setOpen(false);
              setWaiting(false);
            }}
            color="primary"
          >
            Yes
          </Button>
          <NdAsyncButton
            className={$.BR_fix}
            variant="contained"
            onClick={async () => {
              setOpen(false);
            }}
            disabled={waiting}
          >
            No
          </NdAsyncButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

interface UpdateEmailTemplateDialogProps extends CommonDialogProps {
  noAction?: () => Promise<void>;
  yesAction?: () => Promise<void>;
}

export const UpdateEmailTemplateDialog = ({ open, setOpen, yesAction, noAction }: UpdateEmailTemplateDialogProps) => {
  const [waiting, setWaiting] = React.useState(false);
  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(waiting ? true : false)}
        classes={{ paper: $.BR_fix }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Update E-mail template</DialogTitle>
        <DialogContent className={$.flex_column} sx={{ py: 2, px: 3 }}>
          <Alert severity="warning">Do you want to update the email template to use it in future?</Alert>
        </DialogContent>
        <DialogActions>
          <NdAsyncButton
            className={$.BR_fix}
            variant="text"
            buttonComponent={Button}
            color="error"
            onClick={async () => {
              setWaiting(true);
              await noAction();
              setOpen(false);
              setWaiting(false);
            }}
            disabled={waiting}
          >
            No
          </NdAsyncButton>
          <NdAsyncButton
            className={$.BR_fix}
            variant="contained"
            onClick={async () => {
              setWaiting(true);
              await yesAction();
              setOpen(false);
              setWaiting(false);
            }}
            color="primary"
          >
            Yes
          </NdAsyncButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const UpdateUserEmailSettingDialog = ({ open, setOpen }: CommonDialogProps) => {
  const { actor } = useContext(userContext);
  const { setUserEmailSetting } = useContext(PartnerCommsContext);

  const [waiting, setWaiting] = React.useState(false);

  // User Display Name and Email Id
  const getListUserEmailSetting = async () => {
    return useFetch<ListUserEmailSettingRes>(API_ENDPOINTS.LIST_USER_EMAIL_SETTING.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_USER_EMAIL_SETTING.failureMessage,
      config: {
        params: {
          userId: actor.userId,
        },
      },
      thenCallBack: (response) => {
        if (exists(response.data.userEmailSetting)) {
          setUserEmailSetting(response.data.userEmailSetting);
        }
      },
    });
  };

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} classes={{ paper: $.BR_fix }} maxWidth="sm" fullWidth>
        <DialogTitle>Update User Email Settings?</DialogTitle>
        <DialogContent className={$.flex_column} sx={{ py: 2, px: 3 }}>
          <Alert severity="info" sx={{ "& *": { fontFamily: "inherit" } }}>
            Please update to reflect changes in email settings
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button
            className={$.BR_fix}
            onClick={() => {
              setOpen(false);
            }}
            color="inherit"
            disabled={waiting}
          >
            No
          </Button>
          <NdAsyncButton
            className={$.BR_fix}
            variant="contained"
            onClick={async () => {
              setWaiting(true);
              await getListUserEmailSetting();
              setOpen(false);
              setWaiting(false);
            }}
            // buttonComponent={Button}
          >
            Yes
          </NdAsyncButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

interface MailStatusRefreshDialogProps extends CommonDialogProps {
  approveAction: () => Promise<void>;
}

export const MailStatusRefreshDialog = ({ open, setOpen, approveAction }: MailStatusRefreshDialogProps) => {
  const [waiting, setWaiting] = React.useState(false);
  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} classes={{ paper: $.BR_fix }} maxWidth="sm" fullWidth>
        <DialogTitle>Update Status!</DialogTitle>
        <DialogContent className={$.flex_column} sx={{ py: 2, px: 3 }}>
          <Alert severity="info">
            You have not refreshed partner replies since some time, do you still want to proceed?
          </Alert>
        </DialogContent>
        <DialogActions>
          <NdAsyncButton
            className={$.BR_fix}
            onClick={async () => {
              setWaiting(true);
              await approveAction();
              setOpen(false);
              setWaiting(false);
            }}
            color="primary"
            buttonComponent={Button}
          >
            Proceed
          </NdAsyncButton>
          <Button
            className={$.BR_fix}
            onClick={() => {
              setOpen(false);
            }}
            color="primary"
            disabled={waiting}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

interface StopAutoReminderProps extends CommonDialogProps {
  stopAction: () => Promise<void>;
  partnerName?: string;
}

export const StopAutoReminder = ({ open, setOpen, stopAction, partnerName }: StopAutoReminderProps) => {
  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} classes={{ paper: $.BR_fix }} maxWidth="sm" fullWidth>
        <DialogTitle>Are you sure you want to stop auto-reminder?</DialogTitle>
        <DialogContent className={$.flex_column} sx={{ py: 2, px: 3 }}>
          <Alert severity="warning" sx={{ "& *": { fontFamily: "inherit" } }}>
            This auto-reminder will be stopped and cannot be reinstated {partnerName ? "For the Partner " : ""}
            {partnerName ? <b>{partnerName}</b> : ""}.
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button
            className={$.BR_fix}
            onClick={() => {
              setOpen(false);
            }}
            color="inherit"
          >
            No
          </Button>
          <NdAsyncButton
            className={$.BR_fix}
            variant="contained"
            buttonComponent={Button}
            color="error"
            onClick={async () => {
              await stopAction();
              setOpen(false);
            }}
          >
            STOP AUTO REMINDER
          </NdAsyncButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

interface SaveTableViewProps extends CommonDialogProps {
  initialName?: string;
  updateAction: (name: string) => Promise<void>;
  saveAction: (name: string) => Promise<void>;
}

export const SaveTableView = ({ open, setOpen, initialName, updateAction, saveAction }: SaveTableViewProps) => {
  const [name, setName] = useState(initialName);
  const [waiting, setWaiting] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    setName("");
  };

  useEffect(() => {
    setName(initialName);
  }, [initialName]);

  return (
    <>
      <Dialog open={open} onClose={() => handleClose()} classes={{ paper: $.BR_fix }} maxWidth="xs" fullWidth>
        <Box className="dialog_header space_between" alignItems="center" pr={1}>
          <DialogTitle component={"div"} width={"90%"}>
            Save Table View
          </DialogTitle>
          <IconButton
            onClick={() => {
              handleClose();
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <DialogContent sx={{ display: "flex", flexFlow: "column", gap: 1 }}>
          <TextField
            className={$.BR_fix}
            label="Enter table view name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            variant="outlined"
            size="medium"
            sx={{ ".MuiInputBase-root": { borderRadius: "4px" } }}
          />
        </DialogContent>
        <DialogActions className="dialog_footer">
          {initialName && (
            <NdAsyncButton
              className={$.BR_fix}
              variant="contained"
              disabled={waiting}
              onClick={async () => {
                setWaiting(true);
                await updateAction(name);
                setWaiting(false);
                setOpen(false);
              }}
            >
              Update Existing
            </NdAsyncButton>
          )}
          <NdAsyncButton
            className={$.BR_fix}
            variant="contained"
            disabled={waiting}
            onClick={async () => {
              if (!name.trim()) return; // Add validation
              setWaiting(true);
              await saveAction(name);
              setWaiting(false);
              setOpen(false);
            }}
          >
            Save as New
          </NdAsyncButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const DeletePDFTemplate = ({
  open,
  setOpen,
  templateId,
  listUserEmailTemplates,
}: CommonDialogProps & { listUserEmailTemplates: () => void; templateId: number }) => {
  const [waiting, setWaiting] = React.useState(false);

  // Delete PDF Template
  const deletePDFTemplate = (_templateId: number) => {
    return useFetch(API_ENDPOINTS.DELETE_PC_PDF_TEMPLATE.url, "POST", {
      failureMessage: API_ENDPOINTS.DELETE_PC_PDF_TEMPLATE.failureMessage,
      showSuccessToast: true,
      data: {
        templateId: _templateId,
        templateType: UseCaseType.balanceConfirmation,
      },
      thenCallBack: (_res) => {
        listUserEmailTemplates();
      },
    });
  };

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} classes={{ paper: $.BR_fix }} maxWidth="sm" fullWidth>
        <DialogTitle>Delete PDF Template?</DialogTitle>
        <DialogContent className={$.flex_column} sx={{ py: 2, px: 3 }}>
          <Alert severity="warning" sx={{ "& *": { fontFamily: "inherit" } }}>
            The PDF Template will be Deleted!
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button
            className={$.BR_fix}
            onClick={() => {
              setOpen(false);
            }}
            color="inherit"
            disabled={waiting}
          >
            No
          </Button>
          <NdAsyncButton
            className={$.BR_fix}
            variant="contained"
            onClick={async () => {
              setWaiting(true);
              await deletePDFTemplate(templateId);
              setOpen(false);
              setWaiting(false);
            }}
            // buttonComponent={Button}
          >
            Yes
          </NdAsyncButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

interface DeleteTableViewProps extends CommonDialogProps {
  initialName: string;
  delAction: () => Promise<void>;
}

export const DeleteTableView = ({ open, setOpen, initialName, delAction }: DeleteTableViewProps) => {
  const [waiting, setWaiting] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={() => handleClose()} classes={{ paper: $.BR_fix }} maxWidth="sm" fullWidth>
        <Box className="dialog_header space_between" alignItems="center" pr={1}>
          <DialogTitle component={"div"} width={"90%"}>
            Save Table View
          </DialogTitle>
          <IconButton
            onClick={() => {
              handleClose();
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <DialogContent sx={{ display: "flex", flexFlow: "column", gap: 1 }}>
          <Alert severity="warning" sx={{ "& *": { fontFamily: "inherit" } }}>
            The table view <b>{initialName}</b> will be deleted and will not be recovered.
          </Alert>
        </DialogContent>
        <DialogActions className="dialog_footer">
          <Button
            className={$.BR_fix}
            disabled={waiting}
            onClick={async () => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          <NdAsyncButton
            className={$.BR_fix}
            variant="contained"
            color="error"
            buttonComponent={Button}
            disabled={waiting}
            onClick={async () => {
              setWaiting(true);
              await delAction();
              setWaiting(false);
              setOpen(false);
            }}
          >
            Delete
          </NdAsyncButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
