import { Close } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableContainer,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { BusinessPartnerListForBcBeta } from "src/entity/recon-entity/ReconInterfaces";
import { PartnerCommsContext } from "../../Context/PartnerCommunicationContext";
import { NdAsyncButton } from "../../MsmePartnerPortal/CommonComponents";
import $ from "../../MsmePartnerPortal/PartnerPortal.module.scss";
import { CommonDialogProps } from "./BulkMailWarnDialog";

export interface RowStructure {
  toStart: number;
  alreadyEscalated: number;
  totalPartners: number;
}

interface EscalateMailDialogProps extends CommonDialogProps {
  selectedRow: BusinessPartnerListForBcBeta[];
  approveAction: () => Promise<void>;
}

export const EscalateMailDialog = ({ open, setOpen, selectedRow, approveAction }: EscalateMailDialogProps) => {
  const [rows, setRows] = React.useState<RowStructure[]>([]);
  const { setEscalationStarted } = useContext(PartnerCommsContext);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const escalationStarted = selectedRow.filter((row) => {
      return row.isEscalationStarted;
    });

    const escalationNotStarted = selectedRow.filter((row) => {
      return !row.isEscalationStarted;
    });

    const totalPartners = selectedRow.length;

    const rows: RowStructure[] = [
      {
        toStart: escalationNotStarted.length,
        alreadyEscalated: escalationStarted.length,
        totalPartners: totalPartners,
      },
    ];

    if (escalationStarted.length === totalPartners) setEscalationStarted(true);

    setRows(rows);
  }, [selectedRow]);

  return (
    <>
      <Dialog open={open} onClose={() => handleClose()} classes={{ paper: $.BR_fix }} maxWidth="md" fullWidth>
        <Box className="dialog_header space_between" alignItems="center" pr={1}>
          <DialogTitle component={"div"} width={"95%"}>
            Escalate Mail
          </DialogTitle>
          <IconButton
            onClick={() => {
              handleClose();
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <DialogContent sx={{ display: "flex", flexFlow: "column", gap: 1 }}>
          {/* Alert */}
          <Alert severity="info" sx={{ "& *": { fontFamily: "inherit" } }}>
            This will start the flow for Internal Escalations and mails will be sent to all the escalation POC mail ids.
          </Alert>
          {/* table  */}
          <TableContainer className="client-table">
            <Table sx={{ width: "100%", borderCollapse: "collapse", "& *": { borderRadius: "0 !important" } }}>
              <thead>
                <tr>
                  <th>Partner to start escalation with</th>
                  {rows?.[0]?.alreadyEscalated > 0 && <th>Partners already escalated</th>}
                  <th>Total Partners</th>
                </tr>
              </thead>
              <tbody>
                {rows.map((row) => (
                  <tr key={row.toStart}>
                    <td>{row.toStart}</td>
                    {row.alreadyEscalated > 0 && <td>{row.alreadyEscalated}</td>}
                    <td>{row.totalPartners}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions className="dialog_footer" sx={{ p: 1.5 }}>
          <Button
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
          <NdAsyncButton
            // className={$.BR_fix}
            variant="contained"
            onClick={async () => {
              await approveAction();
              handleClose();
            }}
          >
            Escalate Mail
          </NdAsyncButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EscalateMailDialog;
