import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  IconButton,
} from "@mui/material";
import React, { useContext, useState } from "react";
import useFetch from "src/Components/Common/useFetch";
import LoadingIcon from "../../Common/LoadingIcon";
import { Recon360Context } from "../Recon360";

const ManualChangesConfirmModal = () => {
  const {
    openManualChangesConfirmModal,
    setOpenManualChangesConfirmModal,
    getManuallyChangedEntries,
    businessPartnerSelected,
    companyId,
    branchCode,
    setManualChangesExist,
    getSummaryForReconciliationCommonFunction,
    mappingId,
  } = useContext(Recon360Context);
  const [showLoadingIconApplyBtn, setShowLoadingIconApplyBtn] = useState(false);
  // const [showLoadingIconDontApplyBtn, setShowLoadingIconDontApplyBtn] = useState(true);
  // const [showLoadingIconViewBtn, setShowLoadingIconViewBtn] = useState(true);

  const closeManualChangesConfirmModal = () => {
    setOpenManualChangesConfirmModal(false);
    clearManualChangesData();
  };
  const clearManualChangesData = () => {
    setManualChangesExist(false);
  };
  const preAppliedChanges = async () => {
    setShowLoadingIconApplyBtn(true);
    await useFetch("/api/recon/manual/ReapplyPreviousChanges", "POST", {
      showSuccessToast: true,
      data: {
        businessPartnerId: businessPartnerSelected,
        companyId: companyId.current,
        branchCode: branchCode.current,
        mappingId: mappingId.current,
      },
      thenCallBack: () => {
        setShowLoadingIconApplyBtn(false);
        closeManualChangesConfirmModal();
        getSummaryForReconciliationCommonFunction();
      },
      catchCallBack: () => {
        setShowLoadingIconApplyBtn(false);
      },
    });
  };

  return (
    <div>
      <MuiDialog open={openManualChangesConfirmModal} maxWidth="xs" fullWidth={true}>
        <Box className="dialog_header space_between" alignItems="center" pr={1}>
          <DialogTitle>Manual changes?</DialogTitle>
          <IconButton
            onClick={() => {
              closeManualChangesConfirmModal();
              getSummaryForReconciliationCommonFunction();
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <DialogContent sx={{ display: "flex", flexFlow: "column", gap: 0.5 }}>
          <FormLabel>Do you want to apply the manual changes?</FormLabel>
        </DialogContent>
        <DialogActions className="dialog_footer space_around">
          <Button
            className="theme_btn"
            startIcon={<LoadingIcon loading={showLoadingIconApplyBtn} />}
            disabled={showLoadingIconApplyBtn}
            size="small"
            sx={{
              ".MuiButton-startIcon": {
                display: "inherit",
                marginRight: 0,
                marginLeft: 0,
              },
            }}
            onClick={() => {
              preAppliedChanges();
            }}
          >
            Apply
          </Button>
          <Button
            className="theme_btn theme_btn_warning"
            disabled={showLoadingIconApplyBtn}
            size="small"
            onClick={() => {
              closeManualChangesConfirmModal();
              getSummaryForReconciliationCommonFunction();
            }}
          >
            Don't Apply
          </Button>
          <Button
            className="theme_outline_btn"
            disabled={showLoadingIconApplyBtn}
            size="small"
            onClick={() => {
              closeManualChangesConfirmModal();
              getManuallyChangedEntries();
            }}
          >
            View
          </Button>
        </DialogActions>
      </MuiDialog>
    </div>
  );
};

export default ManualChangesConfirmModal;
