import { Close } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Loader from "src/Components/Common/Loader";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import $ from "src/Components/Recon360/PartnerCommunication/MsmePartnerPortal/PartnerPortal.module.scss";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { NdButton } from "../PartnerCommunication/MsmePartnerPortal/CommonComponents";
import { CUSTOMISATION_KEYS_UI_MAPPING, ReportCustomisationSettings } from "./Interfaces";

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

interface DownloadCustomisedReportModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleDownloadReport: () => void;
}
export default function DownloadCustomisedReportModal({
  open,
  setOpen,
  handleDownloadReport,
}: DownloadCustomisedReportModalProps) {
  const [reportCustomisationSettings, setReportCustomisationSettings] = useState<ReportCustomisationSettings>({
    taskAndPartnerLevelDetails: [],
    reconDetails: [],
    reconDetailsCountOfEntries: [],
    ledgerSummary: [],
    documentsRepository: [],
    partnerCommunicationDetails: [],
    otherDetails: [],
  });
  const [isUpdatingSettings, setIsUpdatingSettings] = useState<boolean>(false);
  const [isFetchingSettings, setIsFetchingSettings] = useState<boolean>(false);

  useEffect(() => {
    getReportCustomisationSettings();
  }, []);

  const updateReportCustomisationSettings = async () => {
    setIsUpdatingSettings(true);
    await useFetch(API_ENDPOINTS.SAVE_CUSTOMISED_REPORT_COLUMNS.url, "POST", {
      failureMessage: API_ENDPOINTS.SAVE_CUSTOMISED_REPORT_COLUMNS.failureMessage,
      showSuccessToast: true,
      data: {
        reportCustomisationSettings: reportCustomisationSettings,
      },
      thenCallBack: async () => {
        setIsUpdatingSettings(false);
        setOpen(false);
      },
      catchCallBack: () => {
        setIsUpdatingSettings(false);
        setOpen(false);
      },
    });
  };
  const getReportCustomisationSettings = () => {
    setIsFetchingSettings(true);
    useFetch<{ reportCustomisationSettings: ReportCustomisationSettings }>(
      API_ENDPOINTS.GET_CUSTOMISED_REPORT_COLUMNS.url,
      "GET",
      {
        failureMessage: API_ENDPOINTS.GET_CUSTOMISED_REPORT_COLUMNS.failureMessage,
        thenCallBack: (response) => {
          setIsFetchingSettings(false);
          setReportCustomisationSettings(
            response.data.reportCustomisationSettings || {
              taskAndPartnerLevelDetails: [],
              reconDetails: [],
              reconDetailsCountOfEntries: [],
              ledgerSummary: [],
              documentsRepository: [],
              partnerCommunicationDetails: [],
              otherDetails: [],
            }
          );
        },
        catchCallBack: () => {
          setIsFetchingSettings(false);
        },
      }
    );
  };
  return (
    <>
      <Dialog open={open} maxWidth="sm" fullWidth sx={{ ".MuiPaper-root": { maxWidth: 650 } }}>
        <Box className="space_between" alignItems="center" pr={1} sx={{ background: "#f5f5f5" }}>
          <DialogTitle component={"div"}>
            <Typography variant="h6">Customise Report</Typography>
          </DialogTitle>
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <DialogContent sx={{ padding: 0, minHeight: 390 }}>
          {isFetchingSettings ? (
            <Loader />
          ) : (
            (Object.entries(reportCustomisationSettings) as Entries<ReportCustomisationSettings>).map(
              ([key, reportDetails]) => {
                const allVisible = reportDetails.every((item) => item.isVisible);
                return (
                  <Box key={key}>
                    <Accordion
                      disableGutters
                      sx={{
                        boxShadow: "unset",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        sx={{
                          borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                          "& .MuiAccordionSummary-content": {
                            display: "flex",
                            direction: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            margin: "8px",
                          },
                        }}
                      >
                        <Typography variant="subtitle1">{CUSTOMISATION_KEYS_UI_MAPPING[key]}</Typography>
                        <Switch
                          checked={allVisible}
                          onClick={(event) => {
                            event.stopPropagation();
                            setReportCustomisationSettings((prev) => {
                              const allVisible = prev[key].every((item) => item.isVisible);
                              return {
                                ...prev,
                                [key]: prev[key].map((item) => ({ ...item, isVisible: !allVisible })),
                              };
                            });
                          }}
                        />
                      </AccordionSummary>
                      <AccordionDetails sx={{ padding: 2 }}>
                        <Grid container spacing={1} pl={2}>
                          {reportDetails.map((item, index) => (
                            <Grid item xs={6} key={item.name}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    size="small"
                                    checked={item.isVisible}
                                    onChange={() => {
                                      setReportCustomisationSettings((prev) => {
                                        const updatedArray = prev[key].map((item, i) =>
                                          i === index ? { ...item, isVisible: !item.isVisible } : item
                                        );
                                        return { ...prev, [key]: updatedArray };
                                      });
                                    }}
                                  />
                                }
                                label={item.name}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                );
              }
            )
          )}
        </DialogContent>
        <DialogActions className="dialog_footer">
          <NdButton
            disabled={isUpdatingSettings}
            className={$.BR_fix}
            variant="contained"
            onClick={async () => {
              await updateReportCustomisationSettings();
              await handleDownloadReport();
              setOpen(false);
            }}
          >
            {isUpdatingSettings && <LoadingIcon loading={true} />}
            Save and Download Report
          </NdButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
