import { ContentCopy, Delete, MoreVert, WarningAmber } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Autocomplete,
  Button,
  Chip,
  Dialog as DeleteDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { fetchAllActors } from "src/slices/admin/userListSlice";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import FormatDate from "src/Utils/DateUtils";
import plus from "../../assets/Icon Left.svg";
import {
  CodesWithSign,
  ConfigTemplateType,
  Group,
  LedgerConfiguration,
  ListAllLedgerConfigTemplateResponse,
  ListSanitizationRulesResponse,
} from "../../entity/recon-entity/ReconInterfaces";
import "../Common/Common.scss";
import LoadingIcon from "../Common/LoadingIcon";
import { LoggedInSkeleton } from "../Common/LoggedInSkeleton";
import { getAdminTopBarButtons } from "../Common/TopNavBar";
import useFetch from "../Common/useFetch";
import CustomToast from "../CustomToast";
import { Dialog } from "../Dialog/Dialog";
import { ColumnFilter } from "../ReactTable/ColumnFilter";
import Table, { ColumnDefs } from "../ReactTable/Table";
import "../Recon360/Recon360.scss";
import { ConfigTemplateNames, ConfigTemplateRes, Template } from "../Recon360/ReconHome";
import ColumnHeaderConfig from "../Recon360/Transformer/ColumnHeaderConfig";
import DocumentTypeConfig from "../Recon360/Transformer/DocumentTypeConfig";
import "./Admin.scss";
import { LedgerConfigTemplate } from "./AdminInterfaces";

export interface LedgerConfiguration3 {
  documentDate: string[];
  documentType: string[];
  otherUniqueId: string[];
  paymentId: string[];
  dncnId: string[];
  invoiceIdForDncn: string[];
  particulars: string[];
  amount: string;
  debitAmount: string[];
  creditAmount: string[];
  referenceNumber: string[];
  postingDate: string[];
  documentNumber: string[];
  clearingDocument: string[];
  clearingDate: string[];
  miscellaneous: string[];
  taxAmount: string[];
  closingBalance: number;

  invoice: CodesWithSign[];
  payment: CodesWithSign[];
  debitNote: CodesWithSign[];
  debitAndCreditNotes: CodesWithSign[];
  reversal: CodesWithSign[];
  tds: CodesWithSign[];
  creditNote: CodesWithSign[];
  interDocument: CodesWithSign[];
  debitAmountSignReverseFlag: boolean;
  creditAmountSignReverseFlag: boolean;
  taxAmountSignReverseFlag: boolean;
}
export interface SanitizeConfiguration {
  ruleId: number;
  nthIndex: number;
  text: string;
  textToReplaceWith: string;
}
export interface ColumnsToSanitize {
  columnToSanitize: string;
  sanitizationRules: SanitizeConfiguration[];
}

export interface AnchorLedgerConfig {
  templateName: string;
  config: LedgerConfiguration;
  columnsToSanitize?: ColumnsToSanitize[];
  groups: Group[];
}

export interface ListAnchorLedgerConfigResponse {
  ledgerConfigTemplate: AnchorLedgerConfig;
}

const ConfigTemplates = () => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [headerNameChanged, setChangedHeaderName] = useState({});
  const [headerName, setHeaderName] = useState<LedgerConfiguration>({} as LedgerConfiguration);
  const [sanitizeCol, setSanitizeCol] = useState([]);
  const [allRules, setAllRules] = useState([]);
  const [rulesArray, setRulesArray] = useState([]);
  const [templateName, setTemplateName] = useState<string>("");
  const [templateNameChanged, setChangedTemplateName] = useState("");
  const [configList, setConfigList] = useState<any[]>([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [newConfig, setNewConfig] = useState<boolean>(false);
  const [specificConfig, setSpecificConfig] = useState(false);
  const [columnHeaderConfigDialog, setColumnHeaderConfigDialog] = useState<boolean>(true);
  const [debitAmountSignReverseFlag, setDebitAmountSignReverseFlag] = useState<boolean>(false);
  const [creditAmountSignReverseFlag, setCreditAmountSignReverseFlag] = useState<boolean>(false);
  const [taxAmountSignReverseFlag, setTaxAmountSignReverseFlag] = useState<boolean>(false);

  const [selectedTemplate, setSelectedTemplate] = useState<string>("");
  const [selectedTemplateForCopy, setSelectedTemplateForCopy] = useState<string>("");
  const [selectedCompanyForCopy, setSelectedCompanyForCopy] = useState(null);
  const [inputValueTemplateNameForCopy, setInputValueTemplateNameForCopy] = useState("");
  const [errTemplateNameForCopy, setErrTemplateNameForCopy] = useState(false);
  const [errTemplateNameMsgForCopy, setErrTemplateNameMsgForCopy] = useState("");
  const [loader, setLoader] = useState(true);

  const rowsDataPartnerRef = useRef([]);
  const rowsDataERPRef = useRef([]);
  const rowsDataRURef = useRef([]);
  const [isShow, setIsShow] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const selectedTemplateIdRef = useRef(null);
  const selectedTemplateNameRef = useRef();
  const selectedTemplateOwnIdRef = useRef();
  const selectedConfigTemplateTypeRef = useRef<string>();
  const [openDeleteConfig, setOpenDeleteConfig] = useState(false);
  const [openCopyConfig, setOpenCopyConfig] = useState(false);
  const [startSubmitCopyLoader, setStartSubmitCopyLoader] = useState(false);
  const listAllActors = useSelector((state: any) => state.userList.list);

  const dispatch = useDispatch();
  const { error } = useSelector((state: any) => state.userList);

  const groupsListRef = useRef<Group[]>([]);

  const handleOptionsClick = (
    event: React.MouseEvent<HTMLElement>,
    selectedTemplateId,
    selectedTemplateName,
    selectedConfigTemplateType: string
  ) => {
    setAnchorEl(event.currentTarget);
    selectedTemplateIdRef.current = selectedTemplateId;
    selectedTemplateNameRef.current = selectedTemplateName;
    selectedConfigTemplateTypeRef.current = selectedConfigTemplateType;
  };

  const handleOptionsClickRU = (
    event: React.MouseEvent<HTMLElement>,
    selectedTemplateId,
    selectedTemplateName,
    selectedTemplateOwnId
  ) => {
    setAnchorEl(event.currentTarget);
    selectedTemplateIdRef.current = selectedTemplateId;
    selectedTemplateNameRef.current = selectedTemplateName;
    selectedTemplateOwnIdRef.current = selectedTemplateOwnId;
    selectedConfigTemplateTypeRef.current = "RU";
  };

  const columnDefinitionERP = React.useMemo(
    (): ColumnDefs[] => [
      {
        Header: "Template Name",
        accessor: "templateName",
        Filter: ColumnFilter,
        Sort: false,
        width: 300,
      },
      {
        Header: "Visibility",
        accessor: "isDisabled",
        Sort: false,
        width: 200,
        Cell: ({ row }: any) => (
          <div>
            {row.original.isDisabled ? (
              <Chip label="Hidden" color="warning" />
            ) : (
              <Chip label="Visible" color="success" />
            )}
          </div>
        ),
      },
      {
        Header: "Last Edited",
        accessor: "updatedAt",
        Sort: false,
        width: 250,
        Cell: ({ row }: any) => <div>{FormatDate(row.original.updatedAt, "hh:mm A, DD MMM YY")?.toString()}</div>,
      },
      {
        Header: "Actions",
        accessor: "actions",
        Sort: false,
        width: 350,
        Cell: ({ row }: any) => (
          <div className="d_flex">
            <span>
              {row.original.isDisabled ? (
                <Button
                  startIcon={<VisibilityIcon />}
                  variant="contained"
                  style={{ width: "95px", borderRadius: "4px !important" }}
                  onClick={() => {
                    showHideHandleChange(row.original.templateId, !row.original.isDisabled, ConfigTemplateType.ERP);
                  }}
                >
                  SHOW{" "}
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  style={{ width: "95px", borderRadius: "4px !important" }}
                  startIcon={<VisibilityOffIcon />}
                  onClick={() => {
                    showHideHandleChange(row.original.templateId, !row.original.isDisabled, ConfigTemplateType.ERP);
                  }}
                >
                  HIDE{" "}
                </Button>
              )}
            </span>

            <span className="ml_10">
              <Button
                variant="outlined"
                style={{
                  width: "95px",
                  color: "#535353",
                  borderColor: "#535353",
                }}
                startIcon={<EditIcon />}
                onClick={() => {
                  selectedTemplateIdRef.current = row.original.templateId;
                  setSelectedTemplateId(row.original.templateId);
                  // setSelectedTemplateType(ConfigTemplateType.ERP);
                  setNewConfig(true);
                  getConfigTemplate(row.original.templateId);
                  // setOpenDialog(true);
                  // changeTemplate({ target: { value: row.original.templateId } });
                }}
              >
                EDIT{" "}
              </Button>
            </span>

            <span
              className="vertical_center_align ml_10 cursor_pointer"
              onClick={(e) =>
                handleOptionsClick(e, row.original.templateId, row.original.templateName, ConfigTemplateType.ERP)
              }
            >
              <MoreVert />
            </span>
          </div>
        ),
      },
    ],
    []
  );
  const columnDefinitionRU = React.useMemo(
    (): ColumnDefs[] => [
      {
        Header: "Template Name",
        accessor: "templateName",
        Filter: ColumnFilter,
        Sort: false,
        width: 250,
      },
      {
        Header: "Company Name",
        accessor: "ownCompanyDetails",
        Sort: false,
        width: 320,
        Cell: ({ row }: any) => (
          <div>
            {row.original.CompanyDetails?.companyName ? (
              <div>
                <div>{row.original.CompanyDetails?.companyName}</div>
                <div>
                  <small style={{ color: "#666666", fontSize: "12px" }}>
                    Actor ID:{row.original.CompanyDetails?.actorId}
                  </small>
                </div>
              </div>
            ) : (
              <div>-</div>
            )}
          </div>
        ),
      },
      {
        Header: "Visibility",
        accessor: "isDisabled",
        Sort: false,
        width: 180,
        Cell: ({ row }: any) => (
          <div>
            {row.original.isDisabled ? (
              <Chip label="Hidden" color="warning" />
            ) : (
              <Chip label="Visible" color="success" />
            )}
          </div>
        ),
      },
      {
        Header: "Last Edited",
        accessor: "updatedAt",
        Sort: false,
        width: 220,
        Cell: ({ row }: any) => <div>{FormatDate(row.original.updatedAt, "hh:mm A, DD MMM YY")?.toString()}</div>,
      },
      {
        Header: "Actions",
        accessor: "actions",
        Sort: false,
        width: 320,
        Cell: ({ row }: any) => (
          <div className="d_flex">
            <span>
              {row.original.isDisabled ? (
                <Button
                  startIcon={<VisibilityIcon />}
                  variant="contained"
                  style={{ width: "95px", borderRadius: "4px !important" }}
                  onClick={() => {
                    showHideHandleChangeRU(
                      row.original.templateId,
                      !row.original.isDisabled,
                      row.original?.CompanyDetails?.actorId
                    );
                  }}
                >
                  SHOW{" "}
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  style={{ width: "95px", borderRadius: "4px !important" }}
                  startIcon={<VisibilityOffIcon />}
                  onClick={() => {
                    showHideHandleChangeRU(
                      row.original.templateId,
                      !row.original.isDisabled,
                      row.original?.CompanyDetails?.actorId
                    );
                  }}
                >
                  HIDE{" "}
                </Button>
              )}
            </span>

            <span className="ml_10">
              <Button
                // className="theme_btn theme_outline_btn"
                variant="outlined"
                style={{
                  width: "95px",
                  color: "#535353",
                  borderColor: "#535353",
                }}
                startIcon={<EditIcon />}
                onClick={() => {
                  setSelectedTemplateId(row.original.templateId);
                  // setSelectedTemplateType(ConfigTemplateType.RU);
                  setNewConfig(true);
                  // getConfigTemplate(row.original.templateId);
                  ListConfigTemplate(row.original.templateId, row.original?.CompanyDetails?.actorId);
                  selectedTemplateOwnIdRef.current = row.original?.CompanyDetails?.actorId;

                  // setOpenDialog(true);
                  // changeTemplate({ target: { value: row.original.templateId } });
                }}
              >
                EDIT{" "}
              </Button>
            </span>

            <span
              className="vertical_center_align ml_10 cursor_pointer"
              onClick={(e) =>
                handleOptionsClickRU(
                  e,
                  row.original.templateId,
                  row.original.templateName,
                  row.original?.CompanyDetails?.actorId
                )
              }
            >
              <MoreVert />
            </span>
          </div>
        ),
      },
    ],
    []
  );
  const columnDefinitionPartner = React.useMemo(
    (): ColumnDefs[] => [
      {
        Header: "Template Name",
        accessor: "templateName",
        Filter: ColumnFilter,
        Sort: false,
        width: 250,
      },
      {
        Header: "Template Decsription",
        accessor: "templateDescription",
        Sort: false,
        width: 250,
        Cell: ({ row }: any) => (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
              maxWidth: "240px", // Ensuring max width for truncation
            }}
            title={row.original.templateDescription}
          >
            {row.original.templateDescription}
          </div>
        ),
      },
      {
        Header: "Company Name",
        accessor: "ownCompanyDetails",
        Sort: false,
        width: 320,
        Cell: ({ row }: any) => (
          <div>
            {row.original.ownCompanyDetails?.companyName ? (
              <div>
                <div>{row.original.ownCompanyDetails?.companyName}</div>
                <div>
                  <small style={{ color: "#666666", fontSize: "12px" }}>
                    Actor ID:{row.original.ownCompanyDetails?.actorId}
                  </small>
                </div>
              </div>
            ) : (
              <div>-</div>
            )}
          </div>
        ),
      },
      {
        Header: "Business Partner Name",
        accessor: "bpCompanyDetails",
        Sort: false,
        width: 320,
        Cell: ({ row }: any) => (
          <div>
            {row.original.bpCompanyDetails?.companyName ? (
              <div>
                <div>{row.original.bpCompanyDetails?.companyName}</div>
                <div>
                  <small style={{ color: "#666666", fontSize: "12px" }}>
                    Actor ID:{row.original.bpCompanyDetails?.actorId}
                  </small>
                </div>
              </div>
            ) : (
              <div>-</div>
            )}
          </div>
        ),
      },
      {
        Header: "Visibility",
        accessor: "isDisabled",
        Sort: false,
        width: 180,
        Cell: ({ row }: any) => (
          <div>
            {row.original.isDisabled ? (
              <Chip label="Hidden" color="warning" />
            ) : (
              <Chip label="Visible" color="success" />
            )}
          </div>
        ),
      },
      {
        Header: "Last Edited",
        accessor: "updatedAt",
        Sort: false,
        width: 220,
        Cell: ({ row }: any) => <div>{FormatDate(row.original.updatedAt, "hh:mm A, DD MMM YY")?.toString()}</div>,
      },
      {
        Header: "Actions",
        accessor: "actions",
        Sort: false,
        width: 320,
        Cell: ({ row }: any) => (
          <div className="d_flex">
            <span>
              {row.original.isDisabled ? (
                <Button
                  startIcon={<VisibilityIcon />}
                  variant="contained"
                  style={{ width: "95px", borderRadius: "4px !important" }}
                  onClick={() => {
                    showHideHandleChange(row.original.templateId, !row.original.isDisabled, ConfigTemplateType.Partner);
                  }}
                >
                  SHOW{" "}
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  style={{ width: "95px", borderRadius: "4px !important" }}
                  startIcon={<VisibilityOffIcon />}
                  onClick={() => {
                    showHideHandleChange(row.original.templateId, !row.original.isDisabled, ConfigTemplateType.Partner);
                  }}
                >
                  HIDE{" "}
                </Button>
              )}
            </span>

            <span className="ml_10">
              <Button
                variant="outlined"
                style={{
                  width: "95px",
                  color: "#535353",
                  borderColor: "#535353",
                }}
                startIcon={<EditIcon />}
                onClick={() => {
                  selectedTemplateIdRef.current = row.original.templateId;
                  setSelectedTemplateId(row.original.templateId);
                  // setSelectedTemplateType(ConfigTemplateType.Partner);
                  setNewConfig(true);
                  getConfigTemplate(row.original.templateId);
                  // setOpenDialog(true);
                  // changeTemplate({ target: { value: row.original.templateId } });
                }}
              >
                EDIT{" "}
              </Button>
            </span>

            <span
              className="vertical_center_align ml_10 cursor_pointer"
              onClick={(e) =>
                handleOptionsClick(e, row.original.templateId, row.original.templateName, ConfigTemplateType.Partner)
              }
            >
              <MoreVert />
            </span>
          </div>
        ),
      },
    ],
    []
  );

  const handleChangeTemplateSelection = (event) => {
    setSelectedTemplate(event.target.value);
    setIsShow(false);
  };
  const handleChangeTemplateSelectionForCopy = (event) => {
    if (event.target.value !== ConfigTemplateType.RU) {
      setSelectedCompanyForCopy(null);
    }
    setSelectedTemplateForCopy(event.target.value);
    setErrTemplateNameMsgForCopy("");
    setErrTemplateNameForCopy(false);
  };

  const saveNewconfig = async (configData: any, template: any, data: any) => {
    configData.debitAmountSignReverseFlag = debitAmountSignReverseFlag;
    configData.creditAmountSignReverseFlag = creditAmountSignReverseFlag;
    configData.taxAmountSignReverseFlag = taxAmountSignReverseFlag;
    if (selectedTemplate === ConfigTemplateType.RU) {
      await useFetch(API_ENDPOINTS.UPDATE_RU_CONFIG_TEMPLATE.url, "PUT", {
        failureMessage: API_ENDPOINTS.UPDATE_RU_CONFIG_TEMPLATE.failureMessage,
        showSuccessToast: true,
        data: {
          templateName: template,
          config: configData,
          columnsToSanitize: data,
          templateId: Number(selectedTemplateId) === 0 ? null : selectedTemplateId,
          ownId: selectedTemplateOwnIdRef.current,
          groups: groupsListRef.current,
        },
        thenCallBack: () => {
          getLedgerConfigList();
        },
      });
    } else {
      await useFetch(API_ENDPOINTS.INSERT_LEDGER_CONFIG_TEMPLATE.url, "POST", {
        failureMessage: API_ENDPOINTS.INSERT_LEDGER_CONFIG_TEMPLATE.failureMessage,
        showSuccessToast: true,
        data: {
          templateName: template,
          config: configData,
          columnsToSanitize: data,
          templateType: selectedTemplate,
        },
        config: {
          params: {
            templateId: selectedTemplateIdRef.current || null,
          },
        },
        thenCallBack: () => {
          getLedgerConfigList();
        },
      });
    }
  };

  useEffect(() => {
    getLedgerConfigList();
    useFetch<ListSanitizationRulesResponse>(API_ENDPOINTS.LIST_SANITIZE_RULE.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_SANITIZE_RULE.failureMessage,
      thenCallBack: (response) => {
        setRulesArray(response.data.sanitizationRules);
      },
    });
  }, []);

  const getConfigTemplate = async (id: number) => {
    await useFetch<ListAnchorLedgerConfigResponse>(API_ENDPOINTS.LIST_LEDGER_CONFIG_TEMPLATE(id).url, "GET", {
      thenCallBack: (response) => {
        setTemplateName(response.data.ledgerConfigTemplate.templateName);
        setHeaderName(response.data.ledgerConfigTemplate.config);
        setSanitizeCol(response.data.ledgerConfigTemplate.columnsToSanitize);

        setDebitAmountSignReverseFlag(response?.data?.ledgerConfigTemplate?.config?.debitAmountSignReverseFlag);
        setCreditAmountSignReverseFlag(response?.data?.ledgerConfigTemplate?.config?.creditAmountSignReverseFlag);
        setTaxAmountSignReverseFlag(response?.data?.ledgerConfigTemplate?.config?.taxAmountSignReverseFlag);
        if (response.data.ledgerConfigTemplate.groups?.length > 0) {
          groupsListRef.current = response.data.ledgerConfigTemplate.groups;
        } else {
          groupsListRef.current = [];
        }

        setOpenDialog(true);
      },
    });
  };

  const ListPartnerConfigTemplates = async () => {
    return new Promise<LedgerConfigTemplate[]>((resolve, reject) => {
      useFetch<ListAllLedgerConfigTemplateResponse>(API_ENDPOINTS.LIST_ALL_LEDGER_CONFIG_TEMPLATES.url, "GET", {
        failureMessage: API_ENDPOINTS.LIST_ALL_LEDGER_CONFIG_TEMPLATES.failureMessage,
        config: {
          params: {
            templateType: ConfigTemplateType.Partner,
          },
        },
        thenCallBack: (response) => {
          resolve(response.data.ledgerConfigTemplates);
        },
        catchCallBack: reject,
        errorCallback: reject,
      });
    });
  };

  const listRuConfigTemplatesByAdmin = async () => {
    return new Promise<ConfigTemplateNames>((resolve, reject) => {
      useFetch<ConfigTemplateNames>(API_ENDPOINTS.LIST_RU_CONFIG_TEMPLATES_BY_ADMIN.url, "GET", {
        failureMessage: API_ENDPOINTS.LIST_RU_CONFIG_TEMPLATES_BY_ADMIN.failureMessage,
        thenCallBack: (response) => {
          resolve(response.data);
        },
        catchCallBack: reject,
        errorCallback: reject,
      });
    });
  };

  const getLedgerConfigList = async () => {
    const RUConfigTemplates: Template[] & { IsRuConfigTemplate: boolean }[] = [];
    const data = await listRuConfigTemplatesByAdmin(); // get RU Config Templates List and add to normal Ledger Config Templates List
    if (data.templates.length > -1) {
      RUConfigTemplates.push(...data.templates.map((x) => ({ ...x, IsRuConfigTemplate: true })));
      rowsDataRURef.current = RUConfigTemplates;
    }

    const PartnerConfigTemplates: Template[] & { IsPartnerConfigTemplate: boolean }[] = [];
    const dataPartner = await ListPartnerConfigTemplates(); // get PArtner Config Templates List and add to normal Ledger Config Templates List
    if (dataPartner.length > -1) {
      PartnerConfigTemplates.push(...dataPartner.map((x) => ({ ...x, IsPartnerConfigTemplate: true })));
      rowsDataPartnerRef.current = PartnerConfigTemplates;
    }
    await useFetch<ListAllLedgerConfigTemplateResponse>(API_ENDPOINTS.LIST_ALL_LEDGER_CONFIG_TEMPLATES.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_ALL_LEDGER_CONFIG_TEMPLATES.failureMessage,
      config: {
        params: {
          templateType: ConfigTemplateType.ERP,
        },
      },
      thenCallBack: (response) => {
        setLoader(false);

        const ERPConfigTemplates: Template[] & { IsERPConfigTemplate: boolean }[] = [];
        const { ledgerConfigTemplates } = response.data;

        if (response?.data?.ledgerConfigTemplates?.length > 0) {
          ERPConfigTemplates.push(...ledgerConfigTemplates.map((x) => ({ ...x, IsERPConfigTemplate: true })));
        }
        const finalData = [...ERPConfigTemplates];
        if (PartnerConfigTemplates.length > 0) finalData.push(...(PartnerConfigTemplates as any[]));

        setConfigList(
          finalData.sort((a, b) => {
            return a.templateName.localeCompare(b.templateName, undefined, { sensitivity: "base" });
          })
        );
        rowsDataERPRef.current = ERPConfigTemplates;
      },
    });
  };

  const showHideHandleChange = async (templateId, isDisabled, templateType) => {
    await useFetch(API_ENDPOINTS.INSERT_LEDGER_CONFIG_TEMPLATE.url, "POST", {
      failureMessage: API_ENDPOINTS.INSERT_LEDGER_CONFIG_TEMPLATE.failureMessage,
      showSuccessToast: true,
      data: {
        templateType,
        isDisabled,
      },
      config: {
        params: {
          templateId,
        },
      },
      thenCallBack: () => {
        if (templateType === ConfigTemplateType.Partner) {
          const updatedRowsDataPartner = rowsDataPartnerRef.current.map((item) => {
            if (item.templateId === templateId) {
              return { ...item, isDisabled: isDisabled };
            }
            return item;
          });
          rowsDataPartnerRef.current = updatedRowsDataPartner;
        } else if (templateType === ConfigTemplateType.ERP) {
          const updatedRowsDataPartner = rowsDataERPRef.current.map((item) => {
            if (item.templateId === templateId) {
              return { ...item, isDisabled: isDisabled };
            }
            return item;
          });
          rowsDataERPRef.current = updatedRowsDataPartner;
        }
      },
    });
  };
  const showHideHandleChangeRU = async (templateId, isDisabled, ownId) => {
    await useFetch(API_ENDPOINTS.UPDATE_RU_CONFIG_TEMPLATE.url, "PUT", {
      failureMessage: API_ENDPOINTS.UPDATE_RU_CONFIG_TEMPLATE.failureMessage,
      showSuccessToast: true,
      data: { templateId, isDisabled, ownId },
      thenCallBack: () => {
        const updatedRowsDataPartner = rowsDataRURef.current.map((item) => {
          if (item.templateId === templateId) {
            return { ...item, isDisabled: isDisabled };
          }
          return item;
        });
        rowsDataRURef.current = updatedRowsDataPartner;
      },
    });
  };

  const deleteLedgerConfigTemplate = async () => {
    await useFetch(API_ENDPOINTS.DELETE_LEDGER_CONFIG_TEMPLATE.url, "DELETE", {
      failureMessage: API_ENDPOINTS.DELETE_LEDGER_CONFIG_TEMPLATE.failureMessage,
      showSuccessToast: true,
      config: {
        params: {
          templateId: selectedTemplateIdRef.current,
        },
      },
      thenCallBack: () => {
        if (selectedConfigTemplateTypeRef.current === ConfigTemplateType.Partner) {
          const updatedRowsDataPartner = rowsDataPartnerRef.current.filter(
            (item) => item.templateId !== selectedTemplateIdRef.current
          );

          rowsDataPartnerRef.current = updatedRowsDataPartner;
        } else if (selectedConfigTemplateTypeRef.current === ConfigTemplateType.ERP) {
          const updatedRowsDataPartner = rowsDataERPRef.current.filter(
            (item) => item.templateId !== selectedTemplateIdRef.current
          );
          rowsDataERPRef.current = updatedRowsDataPartner;
        }
        setOpenDeleteConfig(false);
        getLedgerConfigList();
      },
    });
  };

  const deleteLedgerConfigTemplateRU = async () => {
    await useFetch(API_ENDPOINTS.DELETE_RU_CONFIG_TEMPLATE.url, "POST", {
      failureMessage: API_ENDPOINTS.DELETE_RU_CONFIG_TEMPLATE.failureMessage,
      showSuccessToast: true,
      data: { templateId: selectedTemplateIdRef.current, ownId: selectedTemplateOwnIdRef.current },
      thenCallBack: () => {
        const updatedRowsDataPartner = rowsDataRURef.current.filter(
          (item) => item.templateId !== selectedTemplateIdRef.current
        );

        rowsDataRURef.current = updatedRowsDataPartner;

        setOpenDeleteConfig(false);
      },
    });
  };

  const ListConfigTemplate = (_templateId, ownId) => {
    useFetch<ConfigTemplateRes>(API_ENDPOINTS.GET_RU_CONFIG_TEMPLATE.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_RU_CONFIG_TEMPLATE.failureMessage,
      config: {
        params: {
          templateId: _templateId.toString(),
          ownId: ownId,
        },
      },
      thenCallBack: (response) => {
        if (response.data.template) {
          setHeaderName(response.data.template.config);
          setTemplateName(response.data.template.templateName);
          setSanitizeCol(response.data.template.columnsToSanitize);
          setDebitAmountSignReverseFlag(response?.data?.ledgerConfigTemplate?.config?.debitAmountSignReverseFlag);
          setCreditAmountSignReverseFlag(response?.data?.ledgerConfigTemplate?.config?.creditAmountSignReverseFlag);
          setTaxAmountSignReverseFlag(response?.data?.ledgerConfigTemplate?.config?.taxAmountSignReverseFlag);
          if (response.data.template.groups?.length > 0) {
            groupsListRef.current = response.data.template.groups;
          } else {
            groupsListRef.current = [];
          }
          setOpenDialog(true);
        }
      },
    });
  };

  const handleDeleteClose = () => {
    setOpenDeleteConfig(true);
    setAnchorEl(null);
  };
  const handleCopyMenuClose = () => {
    setOpenCopyConfig(true);
    setAnchorEl(null);
  };

  useEffect(() => {
    dispatch(fetchAllActors() as any); // Dispatch the fetchAllActors action creator to fetch the list of actors
  }, [dispatch]);

  // Display error message
  useEffect(() => {
    if (error) {
      toast.error(<CustomToast message={error} />);
    }
  }, [error]);

  const clearCopyTemplateStates = () => {
    setSelectedTemplateForCopy("");
    setSelectedCompanyForCopy(null);
    setInputValueTemplateNameForCopy("");
    setErrTemplateNameForCopy(false);
    setErrTemplateNameMsgForCopy("");
    setOpenCopyConfig(false);
  };

  const copyRUConfigTemplateByAdmin = () => {
    useFetch(API_ENDPOINTS.COPY_RU_CONFIG_TEMPLATE_BY_ADMIN.url, "POST", {
      failureMessage: API_ENDPOINTS.COPY_RU_CONFIG_TEMPLATE_BY_ADMIN.failureMessage,
      showSuccessToast: true,
      data: {
        templateUseCase: selectedTemplateForCopy,
        ownId: selectedTemplateOwnIdRef.current,
        templateId: selectedTemplateIdRef.current,
        templateName: inputValueTemplateNameForCopy,
        partnerId: selectedCompanyForCopy?.actorId,
      },
      thenCallBack: () => {
        setStartSubmitCopyLoader(false);
        clearCopyTemplateStates();
        getLedgerConfigList();
      },
      catchCallBack: () => {
        setStartSubmitCopyLoader(false);
      },
    });
  };

  return (
    <LoggedInSkeleton topBarButtons={getAdminTopBarButtons("Config Templates")}>
      <Grid>
        <div className="templatemgt_text">Template management</div>

        <Grid>
          <div className="benificiary_wrapper">
            <Box className="benificiary_box">
              <div className="space_between vertical_center_align">
                <div className="space_between vertical_center_align">
                  <div>
                    <Box sx={{ minWidth: 280 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select Template Category</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedTemplate}
                          label="Select Template Category"
                          onChange={handleChangeTemplateSelection}
                        >
                          <MenuItem value={"ERP"}>ERP Template</MenuItem>
                          <MenuItem value={"RU"}>Recon User (RU) Template</MenuItem>
                          <MenuItem value={"Partner"}>Business Partner (BP) Template</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                  <div className="ml_16">
                    <Button
                      className="showBtn"
                      disabled={loader}
                      onClick={() => {
                        setIsShow(true);
                      }}
                    >
                      SHOW
                    </Button>
                  </div>
                </div>

                <div>
                  <Button
                    className="newTemplate"
                    onClick={() => {
                      selectedTemplateIdRef.current = null;
                      setChangedHeaderName({});
                      setTemplateName("");
                      setChangedTemplateName("");
                      setSanitizeCol([]);
                      setDebitAmountSignReverseFlag(false);
                      setCreditAmountSignReverseFlag(false);
                      setTaxAmountSignReverseFlag(false);
                      groupsListRef.current = [];
                      setNewConfig(false);
                      setOpenDialog(true);
                    }}
                    disabled={selectedTemplate === "" || selectedTemplate === "RU"}
                  >
                    <span className="vertical_center_align">
                      <span className="vertical_center_align">
                        <img src={plus} alt="" />
                      </span>{" "}
                      <span>New Template</span>
                    </span>
                  </Button>
                </div>
              </div>
            </Box>
          </div>
        </Grid>

        <Grid></Grid>
      </Grid>

      <Grid>
        <Grid>
          {selectedTemplate === ConfigTemplateType.ERP && isShow && (
            <Grid className="userlisttable center_align" sx={{ height: "78vh" }}>
              <Table
                columns={columnDefinitionERP}
                rows={rowsDataERPRef.current}
                sortEnable={false}
                // actorType="admin"
                showPagination={true}
                loading={loader}
              />
            </Grid>
          )}
          {selectedTemplate === ConfigTemplateType.RU && isShow && (
            <Grid className="userlisttable center_align" sx={{ height: "78vh" }}>
              <Table
                columns={columnDefinitionRU}
                rows={rowsDataRURef.current}
                sortEnable={false}
                // actorType="admin"
                showPagination={true}
                loading={loader}
              />
            </Grid>
          )}
          {selectedTemplate === ConfigTemplateType.Partner && isShow && (
            <Grid className="userlisttable center_align" sx={{ height: "78vh" }}>
              <Table
                columns={columnDefinitionPartner}
                rows={rowsDataPartnerRef.current}
                sortEnable={false}
                // actorType="admin"
                showPagination={true}
                loading={loader}
              />
            </Grid>
          )}
          <Menu
            anchorEl={anchorEl}
            open={openMenu}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              horizontal: "right",
              vertical: "bottom",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {selectedTemplate === ConfigTemplateType.RU && (
              <MenuItem onClick={handleCopyMenuClose} sx={{ fontSize: 13 }}>
                <ContentCopy className="mr_10" fontSize="small" /> Copy Template
              </MenuItem>
            )}
            <MenuItem onClick={handleDeleteClose} sx={{ fontSize: 13, color: "red" }}>
              <Delete className="mr_10" fontSize="small" /> Delete
            </MenuItem>
          </Menu>
        </Grid>
        {/* </Box>
          </div> */}
      </Grid>

      <Dialog open={openDialog} onClose={() => setOpenDialog(true)} width70Per={true}>
        {columnHeaderConfigDialog ? (
          <ColumnHeaderConfig
            setColumnHeaderConfigDialog={setColumnHeaderConfigDialog}
            headerName={newConfig ? headerName : headerNameChanged || ({} as LedgerConfiguration)}
            setHeaderName={setHeaderName}
            rules={rulesArray}
            templateName={newConfig ? templateName : templateNameChanged || ""}
            setTemplateName={setTemplateName}
            sanitizeCol={sanitizeCol}
            template={true}
            newconfig={newConfig}
            setNewMapping={setNewConfig}
            heading="Column header mapping"
            openNextDialog={(value: any, header: string, template: string) => {
              setAllRules(value);
              setSanitizeCol(value);
              setChangedHeaderName(header);
              setChangedTemplateName(template);
              setColumnHeaderConfigDialog(false);
            }}
            showSettingsIcon={true}
            setOpenDialog={setOpenDialog}
            specificConfig={specificConfig}
            setSpecificConfig={setSpecificConfig}
            disableSpecificConfigCheckbox={true}
            configList={
              selectedTemplate === ConfigTemplateType.ERP
                ? configList.filter((item) => item.IsERPConfigTemplate)
                : selectedTemplate === ConfigTemplateType.Partner
                ? configList.filter((item) => item.IsPartnerConfigTemplate)
                : configList
            }
            debitAmountSignReverseFlag={debitAmountSignReverseFlag}
            setDebitAmountSignReverseFlag={setDebitAmountSignReverseFlag}
            creditAmountSignReverseFlag={creditAmountSignReverseFlag}
            setCreditAmountSignReverseFlag={setCreditAmountSignReverseFlag}
            taxAmountSignReverseFlag={taxAmountSignReverseFlag}
            setTaxAmountSignReverseFlag={setTaxAmountSignReverseFlag}
            subTab={"Ledger"}
            saveRulesApi={(header) => {
              saveNewconfig(header, templateNameChanged || templateName, allRules);
              setOpenDialog(false);
              setColumnHeaderConfigDialog(true);
            }}
            isUsedAsTemplate={false}
            companyId={null}
            branchCode={null}
            groupsListRef={groupsListRef}
          />
        ) : (
          <DocumentTypeConfig
            setColumnHeaderConfigDialog={setColumnHeaderConfigDialog}
            headerName={newConfig ? headerName : headerNameChanged || ({} as LedgerConfiguration)}
            setHeaderName={setHeaderName}
            setNewMapping={setNewConfig}
            name="Configuration File"
            heading="Document Type Key"
            backTemplate={() => {
              setColumnHeaderConfigDialog(true);
            }}
            setOpenDialog={setOpenDialog}
            saveRulesApi={(header) => {
              saveNewconfig(header, templateNameChanged || templateName, allRules);
              setOpenDialog(false);
              setColumnHeaderConfigDialog(true);
            }}
            setDebitAmountSignReverseFlag={setDebitAmountSignReverseFlag}
            setCreditAmountSignReverseFlag={setCreditAmountSignReverseFlag}
            setTaxAmountSignReverseFlag={setTaxAmountSignReverseFlag}
            groupsListRef={groupsListRef}
          />
        )}
      </Dialog>

      <DeleteDialog open={openDeleteConfig} maxWidth="xs" fullWidth={true} classes={{ root: "z_index_30" }}>
        <DialogContent>
          <div className="delete_temp_text">
            <div>Are you sure, You want to Delete Template:</div>
            <div>{selectedTemplateNameRef.current}?</div>
          </div>
          <div className="warning_text vertical_center_align">
            <span className="mr_6">
              {" "}
              <WarningAmber />
            </span>
            WARNING: This template might be used by multiple users
          </div>
        </DialogContent>
        <DialogActions className="">
          <Button
            style={{
              color: "#555555",
            }}
            onClick={() => {
              setOpenDeleteConfig(false);
            }}
          >
            CANCEL
          </Button>
          <Button
            onClick={() => {
              if (selectedConfigTemplateTypeRef.current === ConfigTemplateType.RU) {
                deleteLedgerConfigTemplateRU();
              } else {
                deleteLedgerConfigTemplate();
              }
            }}
            style={{
              background: "#EB4D47",
              color: "#fff",
            }}
            variant="contained"
          >
            DELETE
          </Button>
        </DialogActions>
      </DeleteDialog>

      <DeleteDialog
        open={openCopyConfig}
        disableEnforceFocus={true}
        maxWidth={false}
        fullWidth={true}
        PaperProps={{
          style: { width: 400, margin: "auto" },
        }}
      >
        <DialogTitle className="dialog_header dialog_header_padding">
          <p className="fs_24">Copy Template</p>
          <button className="delete ml_20" aria-label="close" onClick={() => clearCopyTemplateStates()} />
        </DialogTitle>
        <DialogContent sx={{ display: "flex", flexFlow: "column", gap: 0.5 }}>
          <div className="mt_15">
            <FormLabel>Template name: {selectedTemplateNameRef.current}</FormLabel>
          </div>

          <div className="mt_15">
            <Box sx={{ minWidth: 280 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select Template Category</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedTemplateForCopy}
                  label="Select Template Category"
                  onChange={handleChangeTemplateSelectionForCopy}
                >
                  <MenuItem value={ConfigTemplateType.ERP}>ERP Template</MenuItem>
                  <MenuItem value={ConfigTemplateType.RU}>Recon User (RU) Template</MenuItem>
                  <MenuItem value={ConfigTemplateType.Partner}>Business Partner (BP) Template</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>

          <div className="mt_15">
            <Autocomplete
              disabled={selectedTemplateForCopy === ConfigTemplateType.RU ? false : true}
              id="country-select-demo"
              sx={{ minWidth: 280 }}
              options={listAllActors}
              autoHighlight
              value={selectedCompanyForCopy}
              getOptionLabel={(option: any) => `${option.actorName} (Actor ID: ${option.actorId})`}
              onChange={(_event, newValue) => {
                setSelectedCompanyForCopy(newValue); // Store the selected option in state
                setErrTemplateNameMsgForCopy("");
                setErrTemplateNameForCopy(false);
              }}
              renderOption={(props, option) => (
                <Box
                  key={`${option.actorId}-${option.actorName}`}
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                  style={{ display: "block" }}
                >
                  <div>{option.actorName}</div>
                  <div>
                    <span style={{ color: "#666666", fontSize: "12px" }}>Actor ID: {option.actorId}</span>
                  </div>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Company"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          </div>

          <div className="mt_15">
            <TextField
              variant="outlined"
              fullWidth={true}
              label="Template Name"
              name="templateName"
              error={errTemplateNameForCopy}
              helperText={errTemplateNameForCopy ? errTemplateNameMsgForCopy : ""}
              value={inputValueTemplateNameForCopy}
              onChange={(e) => {
                if (e.target.value.length > 16) {
                  setErrTemplateNameMsgForCopy("You can't use more than 16 characters");
                  setErrTemplateNameForCopy(true);
                } else {
                  setErrTemplateNameForCopy(false);
                  setInputValueTemplateNameForCopy(e.target.value);
                }
              }}
              sx={{
                borderRadius: 24,
                "& .MuiOutlinedInput-root": {
                  borderRadius: 24,
                },
              }}
            />
          </div>
        </DialogContent>
        <DialogActions className="dialog_footer dialog_footer_padding space_around">
          <Button
            className="theme_btn"
            style={{ width: "96%" }}
            onClick={() => {
              let existingTemplate = false;
              if (selectedTemplateForCopy === ConfigTemplateType.RU) {
                existingTemplate = rowsDataRURef.current?.find(
                  (template) =>
                    template.templateName === inputValueTemplateNameForCopy &&
                    template.IsRuConfigTemplate === true &&
                    template?.CompanyDetails?.actorId === selectedCompanyForCopy.actorId
                );
              } else if (selectedTemplateForCopy === ConfigTemplateType.Partner) {
                existingTemplate = rowsDataPartnerRef.current?.find(
                  (template) =>
                    template.templateName === inputValueTemplateNameForCopy && template.IsPartnerConfigTemplate === true
                );
              } else if (selectedTemplateForCopy === ConfigTemplateType.ERP) {
                existingTemplate = rowsDataERPRef.current?.find(
                  (template) =>
                    template.templateName === inputValueTemplateNameForCopy && template.IsERPConfigTemplate === true
                );
              }

              if (existingTemplate) {
                setErrTemplateNameMsgForCopy("Template with name exists");
                setErrTemplateNameForCopy(true);
              } else {
                setErrTemplateNameMsgForCopy("");
                setErrTemplateNameForCopy(false);
                setStartSubmitCopyLoader(true);
                copyRUConfigTemplateByAdmin();
              }
            }}
            startIcon={<LoadingIcon loading={startSubmitCopyLoader} />}
            disabled={
              startSubmitCopyLoader ||
              inputValueTemplateNameForCopy?.trim() === "" ||
              selectedTemplateForCopy?.trim() === "" ||
              (selectedTemplateForCopy === ConfigTemplateType.RU && selectedCompanyForCopy === null)
            }
          >
            Submit
          </Button>
        </DialogActions>
      </DeleteDialog>
    </LoggedInSkeleton>
  );
};

export default ConfigTemplates;
