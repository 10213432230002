import { ChevronRight, Delete } from "@mui/icons-material";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Grid,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import { userContext } from "src/Components/Contexts/userContext";
import {
  EmailSendCount,
  EmailTemplate,
  PDFTemplate,
  TemplateType,
  UseCaseType,
} from "src/entity/recon-entity/ReconInterfaces";
import { updateIsSendingBulkMailsLRB } from "src/slices/partnerCommunication/bulkMailsSlice";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { uiLoggerName } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { DEFAULT_BRANCH_CODE, DEFAULT_COMPANY_ID, TallyOrSapCompanyId } from "../CommonLegacy/CommonComponents";
import EmailTemplateDeleteWarningModal from "../CommonLegacy/EmailTemplateDeleteWarningModal";
import { getShortName } from "../CommonLegacy/MailThreadDialog";
import { PartnerCommsContext } from "../Context/PartnerCommunicationContext";
import Editor from "../Editor/Editor";

export type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;

interface SendEmailTemplateProps<T extends object> {
  companyId: TallyOrSapCompanyId;
  branchCode: string;
  base64Image: string;
  signature: string;
  sendSoleOrBulkMail: EmailSendCount;
  selectedRow: object[] | T[] | any[];
  listAllBusinessPartnersWSR: () => Promise<void>;
  listUserEmailTemplate: (param: string) => void;
  // partnerCommunicationSelected: PartnerCommunicationSelected;
  templateType: Exclude<TemplateType, "LR" | "CB" | "MSME">;
  // storeAllBusinessPartnerCheck: React.MutableRefObject<{ [k: number]: boolean }>;
  mailTo?: string;
  files?: File[];
  setIntervalBulkMails: any;
  withoutTemplateCheck?: boolean;
  setWithoutTemplateCheck?: StateDispatch<boolean>;
}

export const SendEmailTemplateBeta = <T extends object>(props: SendEmailTemplateProps<T>) => {
  const { actor } = useContext(userContext);
  const [dynamicContent, setDynamicContent] = useState<string>("");
  const [files, setFiles] = useState<File[]>(props.files || []);
  const [pdfFiles, setPdfFiles] = useState<PDFTemplate[]>([]);
  const [selectedTemplateName, setSelectedTemplateName] = useState<string>("");
  const [copyAlert, setCopyAlert] = useState<boolean>(false);
  const [emailSubject, setEmailSubject] = useState<string>("");
  const [errEmailSubject, setErrEmailSubject] = useState<boolean>(false);
  const [showLoadingIcon, setShowLoadingIcon] = useState<boolean>(false);
  const [showUserEmailTemplateToBeUpdatedModal, setShowUserEmailTemplateToBeUpdatedModal] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const [anchorMenuEl, setAnchorMenuEl] = useState<HTMLElement>(null);

  const openMenu = Boolean(anchorEl);
  const openSubMenu = Boolean(anchorMenuEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => setAnchorMenuEl(event.currentTarget);

  const handleCloseMenu = () => setAnchorEl(null);
  const handleCloseSubMenu = () => setAnchorMenuEl(null);

  // Email States BEGIN
  const {
    emailTemplateContent,
    setEmailTemplateContent,
    openSendEmailTemplate,
    setOpenSendEmailTemplate,
    setOpenCreateEmailTemplate,
    setOpenSendEmailOnCancel,
    emailTemplates,
    emailBusinessPartnerId,
    mappingId,
    emailDisplayName,
    fromEmailAddress,
    pdfTemplates,
    setCreatePdfTemplate,
    dynamicContentOptions,
    allowSubjectChange,
  } = useContext(PartnerCommsContext);

  // const contextData = useContext(PartnerCommsContext);

  const [selectedTemplateId, setSelectedTemplateId] = useState<number>(null);
  const { setIsOpenDeleteModal, handleCloseDeleteModal, setShowDeleteLoadingIcon } = useContext(PartnerCommsContext);

  // Email States END
  const dispatch = useDispatch();

  useEffect(() => {
    if (emailTemplateContent) {
      setDynamicContent(emailTemplateContent.emailBody);
      setEmailSubject(emailTemplateContent.emailSubject);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailTemplateContent, dynamicContentOptions]);

  const handleClose = () => {
    setOpenSendEmailTemplate(false);
    setSelectedTemplateName("");
    setFiles([]);
    setPdfFiles([]);
  };

  const sendEmail = () => {
    const EndPointLR = API_ENDPOINTS.SEND_EMAIL_FOR_LEDGER_REQUEST.url;
    const EndPointBC = API_ENDPOINTS.SEND_EMAIL.url;

    const EndPoint = props.templateType === "LRB" ? EndPointLR : props.templateType === "BCB" ? EndPointBC : "/api/";

    setShowUserEmailTemplateToBeUpdatedModal(false);
    props.setWithoutTemplateCheck(false);

    setShowLoadingIcon(true);

    const bodyFormData = new FormData();
    bodyFormData.append("businessPartnerId", emailBusinessPartnerId?.toString() || "");
    bodyFormData.append("mappingId", mappingId?.toString() || "");
    bodyFormData.append("emailSubject", emailSubject);
    bodyFormData.append("emailBody", dynamicContent);
    bodyFormData.append("companyId", props.companyId || DEFAULT_COMPANY_ID);
    bodyFormData.append("branchCode", props.branchCode || DEFAULT_BRANCH_CODE);

    if (files !== null && files.length > 0) {
      for (const item of files) {
        bodyFormData.append("file", item);
      }
    }

    if (pdfFiles !== null && pdfFiles?.length > 0) {
      bodyFormData.append("pdfTemplate", pdfFiles[0]?.templateId?.toString());
    }

    useFetch(EndPoint, "POST", {
      failureMessage: API_ENDPOINTS.SEND_EMAIL.failureMessage,
      showSuccessToast: true,
      data: bodyFormData,
      thenCallBack: (_res) => {
        setShowLoadingIcon(false);
        handleClose();
        // props.setLoader(true);
        props.listAllBusinessPartnersWSR();
        // reload templates after mail sent
        props.listUserEmailTemplate("");
      },
      catchCallBack: (_err) => {
        setShowLoadingIcon(false);
      },
    });
  };

  const sendMailInBulk = () => {
    const EndPointLR = API_ENDPOINTS.SEND_BULK_EMAIL_FOR_LEDGER_REQUEST.url;
    const EndPointBC = API_ENDPOINTS.SEND_BULK_EMAIL.url;

    const EndPoint = props.templateType === "LRB" ? EndPointLR : props.templateType === "BCB" ? EndPointBC : "/api/";

    setShowUserEmailTemplateToBeUpdatedModal(false);
    props.setWithoutTemplateCheck(false);

    if (emailSubject.trim() === "") {
      setErrEmailSubject(true);
      return;
    }

    const selectedBPIds = props.selectedRow.map((item) => item?.businessPartnerId);

    setShowLoadingIcon(true);

    dispatch(updateIsSendingBulkMailsLRB(true));

    const bodyFormData = new FormData();
    bodyFormData.append("businessPartnerIds", selectedBPIds?.toString());
    bodyFormData.append("emailSubject", emailSubject);
    bodyFormData.append("emailBody", dynamicContent);
    bodyFormData.append("companyId", props.companyId || DEFAULT_COMPANY_ID);
    bodyFormData.append("branchCode", props.branchCode || DEFAULT_BRANCH_CODE);

    if (files !== null && files.length > 0) {
      for (const item of files) {
        bodyFormData.append("file", item);
      }
    }

    if (pdfFiles !== null && pdfFiles?.length > 0) {
      bodyFormData.append("pdfTemplate", pdfFiles[0]?.templateId?.toString());
    }

    useFetch(EndPoint, "POST", {
      failureMessage: API_ENDPOINTS.SEND_BULK_EMAIL.failureMessage,
      showSuccessToast: true,
      data: bodyFormData,
      thenCallBack: (_res) => {
        handleClose();
        setShowLoadingIcon(false);
        props.setIntervalBulkMails();
      },
      catchCallBack: () => {
        dispatch(updateIsSendingBulkMailsLRB(false));
        setShowLoadingIcon(false);
        handleClose();
      },
    });
  };

  const checkIfUserEmailTemplateHasToUpdate = () => {
    if (emailSubject.trim() === "") {
      setErrEmailSubject(true);
    }

    const bodyFormData = new FormData();
    bodyFormData.append("emailBody", dynamicContent);
    bodyFormData.append("emailSubject", emailSubject);
    bodyFormData.append("templateType", props.templateType);
    bodyFormData.append("templateName", emailTemplateContent.templateName);

    if (emailSubject.trim() !== "") {
      setShowLoadingIcon(true);

      useFetch<{ isTemplateUpdated: boolean }>(API_ENDPOINTS.CHECK_IF_EMAIL_TEMPLATE_NEEDS_TO_BE_ADDED.url, "POST", {
        failureMessage: API_ENDPOINTS.CHECK_IF_EMAIL_TEMPLATE_NEEDS_TO_BE_ADDED.failureMessage,
        config: {
          headers: { "Content-Type": "multipart/form-data" },
        },
        data: bodyFormData,
        thenCallBack: (res) => {
          // check response here
          setShowLoadingIcon(false);
          if (res.data.isTemplateUpdated) {
            setShowUserEmailTemplateToBeUpdatedModal(true);
          } else {
            if (props.sendSoleOrBulkMail === EmailSendCount.Sole) {
              sendEmail();
            } else if (props.sendSoleOrBulkMail === EmailSendCount.Bulk) {
              sendMailInBulk();
            }
          }
        },
      });
    }
  };

  const updateUserEmailTemplate = () => {
    setShowUserEmailTemplateToBeUpdatedModal(false);
    if (emailSubject.trim() === "") {
      setErrEmailSubject(true);
    }

    const bodyFormData = new FormData();
    bodyFormData.append(
      "businessPartnerDetails",
      JSON.stringify({ businessPartnerId: emailBusinessPartnerId, mappingId: mappingId })
    );
    bodyFormData.append("emailBody", dynamicContent);
    bodyFormData.append("emailSubject", emailSubject);
    bodyFormData.append("templateName", emailTemplateContent.templateName);
    bodyFormData.append("templateType", props.templateType);
    bodyFormData.append("senderCompanyId", props.companyId);
    if (files !== null && files.length > 0) {
      // for (let i = 0; i < attachment.length; i++) {
      //   bodyFormData.append("file", attachment[i]);
      // }
      for (const item of files) {
        bodyFormData.append("file", item);
      }
    }

    if (emailSubject.trim() !== "") {
      setShowLoadingIcon(true);

      useFetch(API_ENDPOINTS.ADD_USER_EMAIL_TEMPLATE.url, "POST", {
        failureMessage: API_ENDPOINTS.ADD_USER_EMAIL_TEMPLATE.failureMessage,
        config: {
          headers: { "Content-Type": "multipart/form-data" },
        },
        data: bodyFormData,
        thenCallBack: (_response) => {
          setShowLoadingIcon(false);
          if (props.sendSoleOrBulkMail === EmailSendCount.Sole) {
            sendEmail();
          } else if (props.sendSoleOrBulkMail === EmailSendCount.Bulk) {
            sendMailInBulk();
          }
          handleClose();

          // reload templates after Adding/Updating User Email Template
          props.listUserEmailTemplate("");
        },
      });
    }
  };

  const getPDFTemplateBody = (_templateId: number) => {
    return new Promise<{ templateBody: string }>((resolve, reject) => {
      useFetch<{ templateBody: string }>(API_ENDPOINTS.GET_PDF_TEMPLATE_BODY.url, "POST", {
        failureMessage: API_ENDPOINTS.GET_PDF_TEMPLATE_BODY.failureMessage,
        showSuccessToast: true,
        data: {
          templateId: _templateId,
          templateType: props.templateType === "BCB" ? UseCaseType.balanceConfirmation : UseCaseType.ledgerRequest,
        },
        thenCallBack: (_res) => {
          if (_res.data.templateBody) {
            resolve({ templateBody: _res.data.templateBody });
          }
        },
        catchCallBack: reject,
        errorCallback: reject,
      });
    });
  };

  const deletePDFTemplate = (_templateId: number) => {
    useFetch(API_ENDPOINTS.DELETE_PC_PDF_TEMPLATE.url, "POST", {
      failureMessage: API_ENDPOINTS.DELETE_PC_PDF_TEMPLATE.failureMessage,
      showSuccessToast: true,
      data: {
        templateId: _templateId,
        templateType: props.templateType === "BCB" ? UseCaseType.balanceConfirmation : UseCaseType.ledgerRequest,
      },
      thenCallBack: (_res) => {
        props.listUserEmailTemplate("");
        handleCloseDeleteModal();
        setShowDeleteLoadingIcon(false);
      },
    });
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={openSendEmailTemplate}
        classes={{ paper: "maxWidth_1000" }}
        // onClose={handleClose}
        aria-labelledby="SendEmailTemplate"
        // className="editBpContactMaster_popup"
      >
        <DialogContent sx={{ py: 1.25 }}>
          <div className="email_text">
            <Box>
              <p className="Email_Field">
                <FormLabel className="w_100">From : </FormLabel>
                {fromEmailAddress}
              </p>
              <p className="Email_Field">
                <FormLabel className="w_100">Display Name : </FormLabel>
                {emailDisplayName}
              </p>
              <div style={{ display: "grid", gridTemplateColumns: "30px 96%", padding: "3px", alignItems: "center" }}>
                <FormLabel className="w_100">To : </FormLabel>{" "}
                <Box display={"flex"} sx={{ whiteSpace: "nowrap", width: "inherit", gap: 0.5 }}>
                  <span className="Email_Field">
                    {props.sendSoleOrBulkMail === EmailSendCount.Sole && props.mailTo ? props.mailTo : ""}
                    {props.sendSoleOrBulkMail === EmailSendCount.Bulk
                      ? props.selectedRow.map((item) => item.businessPartnerName).join(", ")
                      : ""}
                  </span>
                  {props.sendSoleOrBulkMail === EmailSendCount.Bulk && (
                    <FormLabel>({props.selectedRow.length} Recipients)</FormLabel>
                  )}
                </Box>
              </div>
              <div className="mt_5" style={{ marginTop: 5 }}>
                <FormLabel>+ CC</FormLabel>
                <FormLabel className="ml_10">+ BCC</FormLabel>
              </div>
            </Box>
            <Box display={"flex"} gap={2} alignItems="center" mt={2} mb={1}>
              <FormLabel className="w_100">Subject : </FormLabel>
              <TextField
                size="small"
                id="mail_subject"
                variant="outlined"
                placeholder="Enter Subject"
                value={emailSubject}
                error={errEmailSubject}
                helperText={errEmailSubject ? "please enter subject" : ""}
                disabled={false}
                onChange={(e) => {
                  setEmailSubject(e.target.value);
                  if (e.target.value.trim() !== "") {
                    setErrEmailSubject(false);
                  } else {
                    setErrEmailSubject(true);
                  }
                }}
                sx={{
                  width: "90%",
                }}
              />
            </Box>
            {!allowSubjectChange && (
              <Alert severity="info" sx={{ py: 0, mb: 1 }}>
                <Typography fontSize={14}>
                  Subject will not change for existing mail communication initiated with Business Partners
                </Typography>
              </Alert>
            )}
            <div>
              <Editor
                dynamicContent={dynamicContent}
                setDynamicContent={setDynamicContent}
                selectedTemplateName={selectedTemplateName}
                selectedEmailTemplateContent={emailTemplateContent}
              />
              {/* <NewEditor /> */}
            </div>
            <div className="mt_10 pl_10" style={{ whiteSpace: "pre-wrap" }}>
              {props.signature !== null && props.signature !== undefined && props.signature !== "" ? (
                <span>{props.signature}</span>
              ) : (
                <>
                  <p>
                    <FormLabel className="w_100">Thanks,</FormLabel>
                  </p>
                  <p>{actor?.name}</p>
                  {/* <p>{props.emailDisplayName}</p> */}
                </>
              )}
            </div>
            <div className="Signature_Box">
              {props.base64Image && <img src={props.base64Image} style={{ width: "180px", height: "80px" }} />}
            </div>
            <div className="Footer_Content">
              <div style={{ height: 10 }} className="mb_10 flex_end">
                {copyAlert && (
                  <Alert severity="success" sx={{ width: 200, height: 48, position: "relative", top: -30 }}>
                    Copied!
                  </Alert>
                )}
              </div>
              <Box display={"flex"} gap={1} sx={{ overflowX: "auto" }}>
                {files !== null &&
                  files.length > 0 &&
                  files.map((_file, fileInd) => (
                    <Chip
                      title={files[fileInd].name}
                      key={files[fileInd].name}
                      label={getShortName(files[fileInd].name)}
                      onDelete={() => {
                        getShortName(files[fileInd].name);
                        files.splice(fileInd, 1);
                        setFiles([...files]);
                      }}
                      deleteIcon={<Delete sx={{ fontSize: 20, ":hover": { color: "#f118 !important" } }} />}
                      sx={{
                        maxWidth: 200,
                        "&, span": {
                          textOverflow: "unset !important",
                        },
                        background: "rgba(84, 28, 76, 0.1) !important",
                        "&:hover": {
                          background: "rgba(84, 28, 76, 0.2) !important",
                        },
                      }}
                    />
                  ))}
                {pdfFiles?.length > 0 &&
                  pdfFiles.map((_file, fileInd) => (
                    <Chip
                      title={_file.templateName}
                      key={_file.templateName + fileInd}
                      label={getShortName(_file.templateName)}
                      onDelete={() => {
                        getShortName(_file.templateName);
                        pdfFiles.splice(fileInd, 1);
                        setPdfFiles([...pdfFiles]);
                      }}
                      deleteIcon={<Delete sx={{ fontSize: 20, ":hover": { color: "#f118 !important" } }} />}
                      sx={{
                        maxWidth: 200,
                        "&, span": {
                          textOverflow: "unset !important",
                        },
                        background: "rgba(84, 28, 76, 0.1) !important",
                        "&:hover": {
                          background: "rgba(84, 28, 76, 0.2) !important",
                        },
                      }}
                    />
                  ))}
              </Box>
              <Grid item={true} xs={12} sm={12} className="d_flex">
                {props.sendSoleOrBulkMail && (
                  <div className="email_width">
                    <Grid item={true} xs={4} sm={4}>
                      <div>
                        <Button
                          variant="contained"
                          component="label"
                          className="theme_btn"
                          style={{ width: "100%" }}
                          size="small"
                          onClick={handleClick}
                        >
                          <span className="file-icon">
                            <i className="fas fa-upload" />
                          </span>
                          Attachment
                        </Button>
                        <Menu
                          anchorEl={anchorEl}
                          open={openMenu}
                          onClose={handleCloseMenu}
                          sx={{ "&>*": { color: "#555}" } }}
                          keepMounted={true}
                        >
                          <MenuItem
                            onClick={() => {
                              if (inputRef.current) inputRef.current?.click();
                            }}
                          >
                            <span>Upload Local File</span>
                            <i
                              className="fa-solid fa-upload"
                              style={{ position: "absolute", right: 16, color: "#aaa" }}
                            />
                            <input
                              ref={inputRef}
                              hidden={true}
                              multiple={true}
                              type="file"
                              onChange={(e) => {
                                setFiles([...files, ...Array.from(e.target.files)]);
                                handleCloseMenu();
                              }}
                            />
                          </MenuItem>
                          <MenuItem onClick={handleMenuClick}>
                            <span>Select PDF Template</span>
                            <ChevronRight sx={{ position: "relative", left: 4, color: "#aaa" }} />
                          </MenuItem>
                        </Menu>
                        <Menu
                          anchorEl={anchorMenuEl}
                          open={openSubMenu}
                          onClose={handleCloseSubMenu}
                          sx={{ "&>*": { color: "#555}" } }}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        >
                          {pdfTemplates.map((template, _i) => (
                            <span className="space_between vertical_center_align" key={template.templateName + _i}>
                              <MenuItem
                                onClick={async () => {
                                  handleCloseMenu();
                                  handleCloseSubMenu();
                                  await getPDFTemplateBody(template.templateId);
                                  setPdfFiles([template]);
                                }}
                                sx={{ width: "100%" }}
                              >
                                <span>{template.templateName}</span>
                                {/* <ChevronRight sx={{ position: "relative", left: 4, color: "#aaa" }} /> */}
                              </MenuItem>
                              <Delete
                                onClick={() => {
                                  setSelectedTemplateId(template?.templateId);
                                  setIsOpenDeleteModal(true);
                                }}
                              />
                            </span>
                          ))}
                          <MenuItem
                            key="Others"
                            value="Others+#Create template"
                            style={{ borderTop: "1px solid #ddd" }}
                            onClick={() => {
                              handleCloseSubMenu();
                              handleCloseMenu();
                              setSelectedTemplateName("");
                              handleClose();
                              setCreatePdfTemplate(true);
                              setOpenCreateEmailTemplate(true);
                              setOpenSendEmailOnCancel(true);
                            }}
                          >
                            + Create PDF template
                          </MenuItem>
                        </Menu>
                      </div>
                    </Grid>
                  </div>
                )}
                <div className="email_width">
                  <Grid item={true} xs={4} sm={4}>
                    <Autocomplete
                      size="small"
                      onChange={(_, value: any) => {
                        if (value !== null) {
                          navigator.clipboard.writeText(value);
                          // insertDynamiccontent(value);
                          setCopyAlert(true);
                          setTimeout(() => {
                            setCopyAlert(false);
                          }, 3000);
                        }
                      }}
                      options={dynamicContentOptions}
                      //   value={dynamicContent}
                      renderInput={(params) => (
                        <TextField {...params} label="Dynamic Content" placeholder="Dynamic Content" />
                      )}
                    />
                  </Grid>
                </div>
                <div className="email_width">
                  <Grid item={true} xs={4} sm={4}>
                    <TextField
                      select={true}
                      size="small"
                      label="Choose Template"
                      name="Choosetemplate"
                      variant="outlined"
                      fullWidth={true}
                      onChange={(e) => {
                        if (e.target.value === "Others+#Create template") {
                          setSelectedTemplateName("");
                          handleClose();
                          setOpenCreateEmailTemplate(true);
                          setOpenSendEmailOnCancel(true);
                        } else {
                          setSelectedTemplateName(e.target.value);
                          const filteredSelectedTemplate = emailTemplates.filter((item: EmailTemplate) => {
                            return item.templateName === e.target.value;
                          })[0];
                          setEmailTemplateContent(filteredSelectedTemplate);
                        }
                      }}
                      value={selectedTemplateName}
                    >
                      {emailTemplates?.length > 0 &&
                        emailTemplates.map((item: EmailTemplate, itemInd: number) => (
                          <MenuItem key={itemInd} value={item.templateName}>
                            {item.templateName}
                          </MenuItem>
                        ))}
                      <MenuItem
                        key="Others"
                        value="Others+#Create template"
                        style={{ borderTop: "1px solid #ddd" }}
                        onClick={() =>
                          uiLogger(uiLoggerName.ui_TemplateCreatedOnEmailModal, props.companyId, props.branchCode)
                        }
                      >
                        + Create template
                      </MenuItem>
                    </TextField>
                  </Grid>
                </div>
              </Grid>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="dialog_footer">
          <div className="email_dialog_footer_btn">
            <Button
              className="theme_outline_btn"
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              className="theme_btn"
              onClick={() => {
                if (props.withoutTemplateCheck) {
                  if (props.sendSoleOrBulkMail === EmailSendCount.Sole) {
                    sendEmail();
                  } else if (props.sendSoleOrBulkMail === EmailSendCount.Bulk) {
                    sendMailInBulk();
                  }
                } else checkIfUserEmailTemplateHasToUpdate();
              }}
              disabled={showLoadingIcon}
            >
              {<LoadingIcon loading={showLoadingIcon} />}
              Send
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog open={showUserEmailTemplateToBeUpdatedModal}>
        <DialogTitle className="dialog_header">
          <p className="fs_24">Update E-mail template</p>
          <button
            className="delete ml_20"
            aria-label="close"
            onClick={() => setShowUserEmailTemplateToBeUpdatedModal(false)}
          />
        </DialogTitle>
        <DialogContent>
          <div>
            <p className="mt_30 mb_25 ">Do you want to update the email template to use it in future?</p>
          </div>
        </DialogContent>
        <DialogActions className="dialog_footer space_between">
          <div>
            <Button
              className="theme_outline_btn"
              onClick={() => {
                if (props.sendSoleOrBulkMail === EmailSendCount.Sole) {
                  sendEmail();
                } else if (props.sendSoleOrBulkMail === EmailSendCount.Bulk) {
                  sendMailInBulk();
                }
                setShowUserEmailTemplateToBeUpdatedModal(false);
              }}
            >
              No
            </Button>
          </div>
          <div>
            <Button
              className="theme_btn"
              onClick={() => {
                updateUserEmailTemplate();
              }}
            >
              Yes
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      {openSendEmailTemplate && (
        <EmailTemplateDeleteWarningModal
          handleDelete={async () => {}}
          handleDeletePdf={async () => {
            if (openSendEmailTemplate) deletePDFTemplate(selectedTemplateId);
          }}
        />
      )}
    </>
  );
};

export default SendEmailTemplateBeta;
