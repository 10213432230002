/* eslint-disable @typescript-eslint/no-unused-vars */
import { Add, MoreOutlined } from "@mui/icons-material";
import { Box, Button, Card, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Loader from "src/Components/Common/Loader";
import useFetch from "src/Components/Common/useFetch";
import StackedEmail from "src/Graphics/StackedEmail.svg";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { CommsType } from "../../../BalanceConfirmationBeta/CommunicationTypes/CommunicationTypes";
import NewCommunicationType from "../../../BalanceConfirmationBeta/CommunicationTypes/NewAndEditCommunicationType";
import { TaskLabelAutocomplete } from "../../../Common/CommonComponents";
import { PartnerCommsContext } from "../../../Context/PartnerCommunicationContext";
import { NdButtonOutlined } from "../../../MsmePartnerPortal/CommonComponents";
import BcNewContext from "../BcNewContext";

const ChooseCommsType = () => {
  const [communicationTypes, setCommunicationTypes] = useState<CommsType[]>([]);
  const { companyId, branchCode, currentRow, listUserEmailTemplates } = useContext(BcNewContext);
  const { setCommunicationTypeId, setTaskLabel } = useContext(PartnerCommsContext);

  const [loading, setLoading] = useState(false);
  const [commsTypeId, setCommsTypeId] = useState<number | undefined>(undefined);

  const [openNewComms, setOpenNewComms] = useState(false);

  const [allTaskLabels, setAllTaskLabels] = useState<{ title: string }[]>([]);
  const [selectedTaskLabel, setSelectedTaskLabel] = useState<{ title: string }>({ title: "" });

  const ListCommunicationTypes = async () => {
    return useFetch<{ data: CommsType[] }>(API_ENDPOINTS.LIST_ALL_COMMUNICATION_TYPES.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_ALL_COMMUNICATION_TYPES.failureMessage,
      thenCallBack: (_res) => {
        if (_res.data?.data) setCommunicationTypes(_res.data?.data);
      },
    });
  };

  const GetAllTaskLabels = async () => {
    useFetch<{ allLabels: string[] }>(API_ENDPOINTS.GET_TASK_LABELS.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_TASK_LABELS.failureMessage,
      thenCallBack: (res) => {
        setAllTaskLabels(res.data.allLabels?.map((label) => ({ title: label })));
      },
    });
  };

  const GetTaskLabelValidForPartnerComms = async (label: string) => {
    return useFetch<{ isValid: boolean }>(API_ENDPOINTS.GET_TASK_LABEL_VALIDATION.url, "POST", {
      showSuccessToast: true,
      data: {
        businessPartnerIds: currentRow.businessPartnerId,
        taskLabel: label,
      },
      thenCallBack: (res) => {
        if (res.data?.isValid) {
          setTaskLabel({ title: label });
        } else {
          setTaskLabel({ title: "" });
          setSelectedTaskLabel({ title: "" });
        }
      },
      catchCallBack: (_err) => {
        setTaskLabel({ title: "" });
        setSelectedTaskLabel({ title: "" });
      },
    });
  };

  const fetchData = async () => {
    GetAllTaskLabels();
    setLoading(true);
    await ListCommunicationTypes();
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setCommunicationTypeId(commsTypeId);
  }, [commsTypeId]);

  useEffect(() => {
    if (selectedTaskLabel?.title) GetTaskLabelValidForPartnerComms(selectedTaskLabel.title);
    else {
      setTaskLabel({ title: "" });
    }
  }, [selectedTaskLabel]);

  if (loading) return <Loader />;

  return (
    <>
      {/* Top */}

      <Box
        className="space_between fade_up_t"
        sx={{
          alignItems: "center",
          padding: "16px 24px",
          bgcolor: "#fff",
          minWidth: "600px",
          zIndex: 1,
          boxShadow:
            "0px 1px 3px 0px rgba(0, 0, 0, 0.06), 0px 1px 1px 0px rgba(0, 0, 0, 0.07), 0px 2px 1px -1px rgba(0, 0, 0, 0.10)",
        }}
      >
        <Stack direction="row" spacing={2}>
          <img src={StackedEmail} height={40} width={40} />

          <Stack>
            <Typography
              variant="h6"
              sx={{
                color: "rgba(0, 0, 0, 0.87)",
              }}
            >
              Select Communication Type
            </Typography>

            <Typography
              variant="body2"
              sx={{
                color: "rgba(0, 0, 0, 0.6)",
              }}
            >
              You can select single or multiple partners to send mail
            </Typography>
          </Stack>
        </Stack>

        <NdButtonOutlined
          size="medium"
          startIcon={<Add />}
          onClick={() => {
            setCommsTypeId(0);
            setOpenNewComms(true);
          }}
        >
          Create
        </NdButtonOutlined>
      </Box>

      {/* Bottom */}
      <Box p={3} bgcolor="#FAFAFA" className="fade_up_t">
        <FormControl>
          {/* Main */}
          <Typography variant="subtitle2" sx={{ color: (t) => t.palette.text.secondary }}>
            Select from below
          </Typography>

          <RadioGroup
            defaultValue={"bcf"}
            onChange={(_e, val) => {
              setCommsTypeId(Number(val));
            }}
          >
            <Stack direction={"row"} flexWrap={"wrap"} gap={2} py={2}>
              {communicationTypes.map((commType) => (
                <Card
                  className="vertical_center_align"
                  key={commType.id}
                  variant="outlined"
                  sx={{ p: 2, boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.08)", width: 400 }}
                >
                  <FormControlLabel
                    value={commType.id}
                    control={<Radio checked={commsTypeId === commType.id} />}
                    label={commType.name}
                  />
                  <Button
                    variant="text"
                    size="small"
                    sx={{ color: "primary.main" }}
                    onClick={(_e) => {
                      // uiLogger(
                      //   uiLoggerNamesPartnerCommunication.UI_PC_BCB_BULK_MAIL_VIEW_COMMUNICATION_CLICK.functionName,
                      //   companyId,
                      //   branchCode,
                      //   {
                      //     message: uiLoggerNamesPartnerCommunication.UI_PC_BCB_BULK_MAIL_VIEW_COMMUNICATION_CLICK.message,
                      //   }
                      // );
                      setCommsTypeId(commType.id);
                      setOpenNewComms(true);
                    }}
                  >
                    VIEW
                  </Button>
                </Card>
              ))}
            </Stack>
          </RadioGroup>

          {/* Label Batch */}
          <Box
            className="label_batch_container"
            sx={{
              width: "100%",
              bgcolor: "#ede7f6",
              borderRadius: "4px",
              border: "1px solid rgba(0,0,0,0.12)",
              p: 2,
              minHeight: "88px",
              display: "flex",
              gap: 2,
            }}
          >
            <Stack className="label_content" sx={{ flex: 1 }}>
              <Box className="d_flex vertical_center_align" gap={1}>
                <MoreOutlined sx={{ color: "#4527A0" }} />
                <Typography
                  variant="h6"
                  sx={{
                    color: "rgba(0,0,0,0.87)",
                  }}
                >
                  Label this batch
                </Typography>
              </Box>

              <Typography
                variant="body2"
                sx={{
                  mt: 1,
                  color: "rgba(0,0,0,0.6)",
                }}
              >
                This label is for internal record keeping and will not be sent in the mails.
              </Typography>
            </Stack>

            <TaskLabelAutocomplete
              taskLabel={selectedTaskLabel}
              setTaskLabel={setSelectedTaskLabel}
              allTaskLabels={allTaskLabels}
              AcProps={{
                sx: { "& fieldset": { borderRadius: "4px" }, minWidth: 280, bgcolor: "#fff", borderRadius: "4px" },
              }}
            />
          </Box>
        </FormControl>
      </Box>
      <NewCommunicationType
        open={openNewComms}
        setOpen={setOpenNewComms}
        communicationTypeId={commsTypeId}
        branchCode={branchCode}
        companyId={companyId}
        listUserEmailTemplates={listUserEmailTemplates}
        onClose={() => {
          ListCommunicationTypes();
        }}
      />
    </>
  );
};

export default ChooseCommsType;
