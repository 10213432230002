/* eslint-disable @typescript-eslint/no-unused-vars */
import { ArrowBack, AttachFile, Close, Download, Person } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Collapse,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  List,
  ListItemButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Buffer } from "buffer";
import saveAs from "file-saver";
import React, { useContext, useEffect, useState } from "react";
import useFetch from "src/Components/Common/useFetch";
import { userContext } from "src/Components/Contexts/userContext";
import { ListUserEmailSettingRes, MsmeMailThread, UseCaseType } from "src/entity/recon-entity/ReconInterfaces";
import { exists } from "src/Utils/Common/Validators";
import FormatDate from "src/Utils/DateUtils";
import { uiLoggerNameDisputeResolution } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { UpdateEmailTemplateDialog } from "../../PartnerCommunication/Common/CommonDialogs";
import EmailEditorWithTemplate from "../../PartnerCommunication/Common/EmailEditorWithTemplate";
import { EmailFields } from "../../PartnerCommunication/Common/ViewReplyMailThread";
import { DEFAULT_BRANCH_CODE, DEFAULT_COMPANY_ID } from "../../PartnerCommunication/CommonLegacy/CommonComponents";
import { IFrame } from "../../PartnerCommunication/CommonLegacy/MailThreadDialog";
import { PartnerCommsContext } from "../../PartnerCommunication/Context/PartnerCommunicationContext";
import { NdAsyncButton } from "../../PartnerCommunication/MsmePartnerPortal/CommonComponents";
import { BackdropLoader } from "../../PartnerCommunication/MsmePartnerPortal/PartnerPortal.common";
import { StateDispatch } from "../../Recon360";
import { EmailInput } from "../../ReconMIS/AutoCompletes";
import { ClosureTrackerContext } from "../ClosureTracker/ClosureTracker";
import { DisputeResolutionEmailStatuses } from "../DRInterfaces";

export interface ViewEmailThreadRes {
  emailThread: MsmeMailThread[];
  emailSubject: string;
  ownSideEmails: string[];
  toEmails: string[];
  ccEmails: string[];
}

interface ViewEmailThreadProps {
  setOpen: StateDispatch<boolean>;
}

export const ViewReplyMailThread = ({ setOpen }: ViewEmailThreadProps): JSX.Element => {
  const emailList: MsmeMailThread[] = [];

  //   const { emailBusinessPartnerId, businessPartnerName, openReplyEmail, setOpenReplyEmail } =
  //     useContext(PartnerCommsContext);

  const {
    businessPartnerId,
    mappingId,
    businessPartnerName,
    companyId,
    branchCode,
    currentRow,
    listAllBusinessPartnersWSR,
  } = useContext(ClosureTrackerContext);

  const [openReplyEmail, setOpenReplyEmail] = useState<boolean>(false);

  const [mailThreads, setMailThreads] = useState<MsmeMailThread[]>(emailList);
  const [emailSubject, setEmailSubject] = useState<string>("");
  const [emailsLists, setEmailsLists] = useState<{
    ownSideEmails: string[];
    toEmails: string[];
    ccEmails: string[];
  }>({
    ownSideEmails: [],
    toEmails: [],
    ccEmails: [],
  });

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [openBackDrop, setOpenBackDrop] = useState<boolean>(false);

  const handleListItemClick = (_e: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    setSelectedIndex(index);
  };

  const ViewEmailThread = () => {
    // TODO: get from new API
    return useFetch<ViewEmailThreadRes>("/api/DisputeResolutionEmail/ViewEmailThread", "GET", {
      config: {
        params: {
          companyId: companyId,
          mappingId: mappingId,
          branchCode: branchCode,
          businessPartnerId: businessPartnerId,
          newMailInitiationCount: null, // TODO: Get New Mail Initiation Count
          ticketId: currentRow?.ticketId,
        },
      },
      thenCallBack: (res) => {
        if (res.data) {
          setMailThreads(res.data?.emailThread?.length > 0 ? res.data?.emailThread : emailList);
          setEmailSubject(res.data.emailSubject || "");
          setEmailsLists({
            ownSideEmails: res.data.ownSideEmails || [],
            toEmails: res.data.toEmails || [],
            ccEmails: res.data.ccEmails || [],
          });
        }
      },
    });
  };

  useEffect(() => {
    const loadMailThread = async () => {
      setOpenBackDrop(true);
      await ViewEmailThread();
      setOpenBackDrop(false);
    };
    loadMailThread();
  }, []);

  const GetAttachmentsZip = async () => {
    return useFetch<{ buffer: string; fileName: string }>("/api/DisputeResolutionEmail/GetAttachmentsZip", "GET", {
      config: {
        params: {
          companyId: companyId,
          branchCode: branchCode,
          businessPartnerId: businessPartnerId,
          mappingId: mappingId,
          ticketId: currentRow?.ticketId,
          mailThreadIndex: selectedIndex,
        },
      },
      thenCallBack: (res) => {
        const zipData = Buffer.from(res.data.buffer, "base64");
        const fileType = "application/x-zip-compressed";
        const blob = new Blob([zipData], { type: fileType });
        saveAs(blob, res.data.fileName);
      },
    });
  };

  return (
    <>
      <Button
        variant="outlined"
        color="inherit"
        sx={{
          borderRadius: "32px",
          border: "1px solid #111",
          background: "#FFF",
        }}
        onClick={() => setOpen(false)}
      >
        Back
      </Button>

      <Box display="flex" minHeight={"80vh"} p={2} gap={2} justifyContent="center" flexWrap="wrap">
        {/* Left Menu */}
        <Paper sx={{ width: "100%", maxWidth: 360, maxHeight: "80vh" }}>
          <List component="nav" aria-label="main email list">
            {mailThreads?.map((item, index) => (
              <React.Fragment key={index}>
                <ListItemButton
                  selected={selectedIndex === index}
                  onClick={(event) => handleListItemClick(event, index)}
                >
                  <Avatar
                    sx={{ bgcolor: emailsLists.ownSideEmails.includes(item.from) ? "#2296f3" : "#29b17c", mr: 2 }}
                  >
                    <Person />
                  </Avatar>
                  <Box className="textOverflow_hidden">
                    <Typography className="textOverflow_hidden" title={item.from} variant="body1" color="textPrimary">
                      {item.from}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {FormatDate(item.emailTime, "DD/MM/YYYY HH:mm:ss").toString()}
                    </Typography>
                  </Box>
                </ListItemButton>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </Paper>

        {/* Right Section */}
        <Paper
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            // maxHeight: "100vh",
            overflow: "auto",
          }}
        >
          {/* Subject, BusinessPartnerName and Reply Button */}
          <Stack
            sx={{
              gap: 2,
              p: 2,
              bgcolor: "#fff",
              borderBottom: 1,
              borderColor: "#dedede",
            }}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h6" color="textPrimary">
                {emailSubject}
              </Typography>
              <Button
                variant="outlined"
                startIcon={openReplyEmail ? <Close /> : <ArrowBack />}
                size="small"
                sx={{ borderRadius: "4px !important" }}
                onClick={() => setOpenReplyEmail(!openReplyEmail)}
                color={openReplyEmail ? "error" : "primary"}
              >
                {openReplyEmail ? "DISCARD" : "REPLY"}
              </Button>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <Typography variant="body2" color="textSecondary">
                Business Partner:
              </Typography>
              <Typography variant="subtitle2" color="textPrimary">
                {businessPartnerName}
              </Typography>
            </Box>
          </Stack>

          {/* Reply Email Section */}
          <Collapse in={openReplyEmail}>
            <ReplyEmail
              setOpenReplyEmail={setOpenReplyEmail}
              emailsList={emailsLists}
              emailBusinessPartnerId={businessPartnerId}
              mappingId={mappingId}
              afterSendEmail={() => {
                setOpenReplyEmail(false);
                setTimeout(() => {
                  ViewEmailThread();
                }, 5_000);
                setTimeout(() => {
                  ViewEmailThread();
                }, 10_000);
                listAllBusinessPartnersWSR();
              }}
            />
          </Collapse>

          {/* Viewing Email Section  */}
          {/* Email from, to, cc and date section */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              p: 2,
              bgcolor: "#f8f8f8",
              borderBottom: 1,
              borderTop: openReplyEmail ? 1 : 0,
              borderColor: "#dedede",
            }}
          >
            <Avatar sx={{ bgcolor: "grey.300" }}>
              <Person />
            </Avatar>
            <Box flex={1}>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="body1" color="textPrimary">
                  {mailThreads[selectedIndex]?.from}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {FormatDate(mailThreads[selectedIndex]?.emailTime, "DD/MM/YYYY HH:mm:ss").toString()}
                </Typography>
              </Box>
              {/* Mail Thread To Cc  */}
              <Box display="flex" gap={2}>
                <Typography variant="body2" color="textPrimary">
                  To:
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {mailThreads[selectedIndex]?.to?.join(", ")}
                </Typography>
                <Typography variant="body2" color="textPrimary">
                  CC:
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {mailThreads[selectedIndex]?.cc?.join(", ")}
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* Download List Section */}
          <Box
            className="download_list_section"
            sx={{ p: 2, border: 1, borderColor: "#dfdfdf", borderTop: "0px", bgcolor: "#fff" }}
          >
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
              {mailThreads[selectedIndex]?.attachments?.map((name, index) => (
                <Chip
                  key={index}
                  avatar={
                    <Avatar>
                      <AttachFile fontSize="small" sx={{ color: "#fff" }} />
                    </Avatar>
                  }
                  label={name}
                  onDelete={() => {
                    // Download Attachment
                    const fileName = mailThreads[selectedIndex]?.attachments[index];
                    const fileUri = mailThreads[selectedIndex]?.s3Link[index];

                    const link = document.createElement("a");
                    link.href = fileUri;
                    link.download = fileName;
                    link.style.display = "none";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }}
                  deleteIcon={
                    <IconButton size="small">
                      <Download fontSize="small" color="info" />
                    </IconButton>
                  }
                  className="download-item"
                />
              ))}
            </Box>
            {mailThreads[selectedIndex]?.attachments?.length > 0 && (
              <Button className="download-all-btn" sx={{ mt: 2 }} color="primary" onClick={GetAttachmentsZip}>
                Download All
              </Button>
            )}
          </Box>
          {/* Email Body Section */}
          <Box p={2} flex={1} bgcolor="white">
            <IFrame referrerPolicy="no-referrer" content="abc" style={{ width: "100%", minHeight: 300 }}>
              <div
                className="unsafeHtmlBody"
                dangerouslySetInnerHTML={{ __html: mailThreads[selectedIndex]?.body }}
                style={{ fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', color: "#000C" }}
              />
            </IFrame>
          </Box>
        </Paper>
      </Box>
      <BackdropLoader openBackDrop={openBackDrop} loadingText={""} />
    </>
  );
};

interface ReplyEmailProps {
  setOpenReplyEmail: StateDispatch<boolean>;
  emailsList: { ownSideEmails: string[]; toEmails: string[]; ccEmails: string[] };
  emailBusinessPartnerId: number;
  mappingId: number;
  afterSendEmail: () => void;
}

export const ReplyEmail = ({
  setOpenReplyEmail,
  emailsList,
  emailBusinessPartnerId,
  mappingId,
  afterSendEmail,
}: ReplyEmailProps) => {
  const { actor } = useContext(userContext);
  const [userEmailSetting, setUserEmailSetting] = useState<ListUserEmailSettingRes["userEmailSetting"]>();

  const [files, setFiles] = useState<File[]>([]);

  const { branchCode, companyId, listUserEmailTemplates, currentRow } = useContext(ClosureTrackerContext);
  const { emailTemplates } = useContext(PartnerCommsContext);
  const [emailSubject, setEmailSubject] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const [emailTemplateId, setEmailTemplateId] = useState(null);
  const [templateError, setTemplateError] = useState(false);

  const [toEmails, setToEmails] = useState<string[]>(emailsList.toEmails);
  const [ccEmails, setCcEmails] = useState<string[]>(emailsList.ccEmails);

  useEffect(() => {
    setToEmails(emailsList.toEmails);
    setCcEmails(emailsList.ccEmails);
  }, [emailsList]);

  const emailTemplateName = emailTemplates.find((item) => item.templateId === emailTemplateId)?.templateName;
  //   emailTemplateContent?.templateName;

  // update email Template section
  const [openUpdateEmailTemplate, setOpenUpdateEmailTemplate] = useState(false);

  // User Display Name and Email Id
  const getListUserEmailSetting = async () => {
    useFetch<ListUserEmailSettingRes>("/api/partnerCommunication/ListUserEmailSetting", "GET", {
      config: {
        params: {
          userId: actor.userId,
        },
      },
      thenCallBack: (response) => {
        if (exists(response.data.userEmailSetting)) {
          setUserEmailSetting(response.data.userEmailSetting);
        }
      },
    });
  };

  useEffect(() => {
    getListUserEmailSetting();
  }, []);

  const checkIfUserEmailTemplateHasToUpdate = async () => {
    if (emailSubject.trim() === "") {
      setTemplateError(true);
      return;
    }

    const bodyFormData = new FormData();
    bodyFormData.append("emailBody", editorContent);
    bodyFormData.append("emailSubject", emailSubject);
    bodyFormData.append("templateType", UseCaseType.disputeResolution);
    bodyFormData.append("templateName", emailTemplateName);

    return new Promise<void>((resolve, reject) => {
      useFetch("/api/partnerCommunication/CheckIfEmailTemplateNeedsToBeAdded", "POST", {
        data: bodyFormData,
        config: {
          headers: { "Content-Type": "multipart/form-data" },
        },
        thenCallBack: async (response) => {
          // check response here
          if (response.data.isTemplateUpdated) {
            setOpenUpdateEmailTemplate(true);
            resolve();
          } else {
            await sendEmail();
            resolve();
          }
        },
        catchCallBack: reject,
        errorCallback: reject,
      });
    });
  };

  const updateUserEmailTemplate = async () => {
    setOpenUpdateEmailTemplate(false);

    if (emailSubject.trim() === "") return;

    const bodyFormData = new FormData();
    bodyFormData.append("receiverId", emailBusinessPartnerId?.toString());
    bodyFormData.append("emailBody", editorContent);
    bodyFormData.append("emailSubject", emailSubject);
    bodyFormData.append("templateName", emailTemplateName);
    bodyFormData.append("templateType", UseCaseType.disputeResolution);
    bodyFormData.append("senderCompanyId", companyId);

    if (files !== null && files.length > 0) {
      for (const item of files) {
        bodyFormData.append("file", item);
      }
    }

    return new Promise<void>((resolve, reject) => {
      useFetch("/api/partnerCommunication/AddUserEmailTemplate", "POST", {
        showSuccessToast: true,
        data: bodyFormData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
        thenCallBack: async () => {
          await sendEmail();

          // reload templates after Adding/Updating User Email Template
          listUserEmailTemplates();

          resolve();

          // props.setLoader(true);
        },
        catchCallBack: reject,
        errorCallback: reject,
      });
    });
  };

  const sendEmail = async () => {
    // const EndPointLR = "/api/ledgerRequestPortal/SendEmailForLedgerRequest";
    const EndPointBC = "/api/DisputeResolutionEmail/SendEmail";

    if (emailSubject.trim() === "") {
      setTemplateError(true);
      return;
    }

    const bodyFormData = new FormData();
    bodyFormData.append("businessPartnerId", emailBusinessPartnerId?.toString());
    bodyFormData.append("mappingId", mappingId?.toString());
    bodyFormData.append("emailSubject", emailSubject);
    bodyFormData.append("emailBody", editorContent);
    bodyFormData.append("companyId", companyId || DEFAULT_COMPANY_ID);
    bodyFormData.append("branchCode", branchCode || DEFAULT_BRANCH_CODE);
    bodyFormData.append("to", toEmails.join(","));
    bodyFormData.append("cc", ccEmails.join(","));
    // bodyFormData.append("pdfTemplateIds", pdfTemplateIds.join(","));
    bodyFormData.append("ticketId", currentRow?.ticketId?.toString());

    if (files !== null && files.length > 0) {
      for (const item of files) {
        bodyFormData.append("file", item);
      }
    }

    return await useFetch(EndPointBC, "POST", {
      showSuccessToast: true,
      data: bodyFormData,
      thenCallBack: (_res) => {
        afterSendEmail();
      },
      catchCallBack: (_err) => {
        // setShowLoadingIcon(false);
      },
    });
  };

  return (
    <>
      <Stack>
        <Box
          sx={{
            p: 2,
            bgcolor: "#f8f8f8",
            borderBottom: 1,
            borderColor: "#dedede",
          }}
        >
          <Stack spacing={2}>
            <EmailFields label="From:" emails={[userEmailSetting?.from_email]} isSender />

            <FormControl>
              <Box display={"inline-flex"} gap={4} alignItems="center">
                <FormLabel className="" sx={{ width: 40 }}>
                  To :
                </FormLabel>
                <EmailInput
                  emails={toEmails}
                  setEmails={setToEmails}
                  label=""
                  limitTags={3}
                  sx={{ width: "100%" }}
                  textFieldProps={{
                    onClick: () => {
                      uiLogger(
                        uiLoggerNameDisputeResolution.UI_DR_CT_ToEmail_Click.functionName,
                        companyId,
                        branchCode,
                        {
                          message: uiLoggerNameDisputeResolution.UI_DR_CT_ToEmail_Click.message,
                        }
                      );
                    },
                  }}
                />
              </Box>
            </FormControl>

            <FormControl>
              <Box display={"inline-flex"} gap={4} alignItems="center">
                <FormLabel className="" sx={{ width: 40 }}>
                  CC :
                </FormLabel>
                <EmailInput
                  emails={ccEmails}
                  setEmails={setCcEmails}
                  label=""
                  limitTags={3}
                  sx={{ width: "100%" }}
                  textFieldProps={{
                    onClick: () => {
                      uiLogger(
                        uiLoggerNameDisputeResolution.UI_DR_CT_CcEmail_Click.functionName,
                        companyId,
                        branchCode,
                        {
                          message: uiLoggerNameDisputeResolution.UI_DR_CT_CcEmail_Click.message,
                        }
                      );
                    },
                  }}
                />
              </Box>
            </FormControl>

            {/* <EmailFields label="From:" emails={[userEmailSetting?.from_email]} isSender />
            <EmailFields label="To:" emails={emailsList.toEmails} />
            <EmailFields label="CC:" emails={emailsList.ccEmails} /> */}
          </Stack>
        </Box>

        {/* Email Editor With Template */}
        <EmailEditorWithTemplate
          inputEditorState={{
            editorContent: editorContent || "",
            emailSubject: emailSubject || "",
            templateError: templateError,
          }}
          returnEditorState={(emailSubject, editorContent, templateId, files) => {
            setEmailSubject(emailSubject);
            setEditorContent(editorContent);
            setEmailTemplateId(templateId);
            setFiles(files);
          }}
          sectionsCustomizations={{
            subject: currentRow?.emailStatus === DisputeResolutionEmailStatuses.EmailNotSent,
          }}
          otherProps={{
            listUserEmailTemplates: listUserEmailTemplates,
            templateType: UseCaseType.disputeResolution,
            companyId: companyId,
            branchCode: branchCode,
          }}
        />

        <Box display="flex" justifyContent={"flex-end"} alignItems="center" bgcolor="white" p={2} gap={2} boxShadow={4}>
          <Button variant="text" color="inherit" onClick={() => setOpenReplyEmail(false)}>
            CANCEL
          </Button>
          <NdAsyncButton
            className="theme_btn"
            buttonComponent={Button}
            onClick={async () => {
              uiLogger(
                uiLoggerNameDisputeResolution.UI_DR_CT_SendViewMail_Send_Click.functionName,
                companyId,
                branchCode,
                {
                  message: uiLoggerNameDisputeResolution.UI_DR_CT_SendViewMail_Send_Click.message,
                }
              );

              await checkIfUserEmailTemplateHasToUpdate();
            }}
          >
            SEND EMAIL
          </NdAsyncButton>
        </Box>
      </Stack>

      {/* Update Email Template Dialog */}
      <UpdateEmailTemplateDialog
        open={openUpdateEmailTemplate}
        setOpen={setOpenUpdateEmailTemplate}
        yesAction={async () => {
          await updateUserEmailTemplate();
          // uiLogger(uiLoggerName.ui_UpdatedEmailTemplate, companyId, branchCode);
        }}
        noAction={async () => {
          await sendEmail();
        }}
      />
    </>
  );
};
