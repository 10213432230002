/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChevronLeft, Edit, Person, Settings } from "@mui/icons-material";
import { Avatar, Box, Button, IconButton, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useFetch from "src/Components/Common/useFetch";
import { userContext } from "src/Components/Contexts/userContext";
import { ListUserEmailSettingRes, UseCaseType } from "src/entity/recon-entity/ReconInterfaces";
import {
  updateBulkMailedTimeClosureTracker,
  updateIsSendingBulkMailsClosureTracker,
  updateMailSentPartnerCountClosureTracker,
} from "src/slices/partnerCommunication/bulkMailsSlice";
import { exists } from "src/Utils/Common/Validators";
import { uiLoggerNameDisputeResolution } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { UpdateEmailTemplateDialog } from "../../PartnerCommunication/Common/CommonDialogs";
import EmailEditorWithTemplate from "../../PartnerCommunication/Common/EmailEditorWithTemplate";
import { EmailFields } from "../../PartnerCommunication/Common/ViewReplyMailThread";
import { DEFAULT_BRANCH_CODE, DEFAULT_COMPANY_ID } from "../../PartnerCommunication/CommonLegacy/CommonComponents";
import { PartnerCommsContext } from "../../PartnerCommunication/Context/PartnerCommunicationContext";
import { NdAsyncButton } from "../../PartnerCommunication/MsmePartnerPortal/CommonComponents";
import { ClosureTrackerContext } from "../ClosureTracker/ClosureTracker";
import { DisputeResolutionEmailStatuses } from "../DRInterfaces";

interface BulkMailProps {
  handleBack: () => void;
}

const BulkMailing: React.FC<BulkMailProps> = ({ handleBack }) => {
  const { actor } = useContext(userContext);
  const { selectedRow, setIntervalClosureTracker, branchCode, companyId, listUserEmailTemplates } =
    useContext(ClosureTrackerContext);
  const { emailBusinessPartnerId, emailTemplates, emailTemplateContent } = useContext(PartnerCommsContext);

  const dispatch = useDispatch();

  const [files, setFiles] = useState<File[]>([]);

  const [userEmailSetting, setUserEmailSetting] = useState<ListUserEmailSettingRes["userEmailSetting"]>({} as any);

  const [emailSubject, setEmailSubject] = useState(emailTemplateContent?.emailSubject || "");
  const [editorContent, setEditorContent] = useState(emailTemplateContent?.emailBody || "");
  const [emailTemplateId, setEmailTemplateId] = useState(emailTemplateContent?.templateId || null);
  const [templateError, setTemplateError] = useState(false);

  const emailTemplateName = emailTemplates.find((item) => item.templateId === emailTemplateId)?.templateName;

  // update email Template section
  const [openUpdateEmailTemplate, setOpenUpdateEmailTemplate] = useState(false);

  useEffect(() => {
    // GetCommunicationTypeDetail(communicationTypeId);
  }, []);

  // User Display Name and Email Id
  const getListUserEmailSetting = async () => {
    return useFetch<ListUserEmailSettingRes>("/api/partnerCommunication/ListUserEmailSetting", "GET", {
      config: {
        params: {
          userId: actor.userId,
        },
      },
      thenCallBack: (response) => {
        if (exists(response.data.userEmailSetting)) {
          setUserEmailSetting(response.data.userEmailSetting);
        }
      },
    });
  };

  useEffect(() => {
    getListUserEmailSetting();
  }, []);

  const handleClose = () => {
    handleBack();
  };

  const checkIfUserEmailTemplateHasToUpdate = async () => {
    if (emailSubject.trim() === "") {
      setTemplateError(true);
      return;
    }

    const bodyFormData = new FormData();
    bodyFormData.append("emailBody", editorContent);
    bodyFormData.append("emailSubject", emailSubject);
    bodyFormData.append("templateType", UseCaseType.disputeResolution);
    bodyFormData.append("templateName", emailTemplateName);

    return new Promise<void>((resolve, reject) => {
      useFetch("/api/partnerCommunication/CheckIfEmailTemplateNeedsToBeAdded", "POST", {
        data: bodyFormData,
        config: {
          headers: { "Content-Type": "multipart/form-data" },
        },
        thenCallBack: async (response) => {
          // check response here
          if (response.data.isTemplateUpdated) {
            setOpenUpdateEmailTemplate(true);
            resolve();
            // console.log("so why not open");
          } else {
            await sendMailInBulk();
            resolve();
          }
        },
        catchCallBack: reject,
        errorCallback: reject,
      });
    });
  };

  const updateUserEmailTemplate = async () => {
    setOpenUpdateEmailTemplate(false);

    if (emailSubject.trim() === "") {
      setTemplateError(true);
      return;
    }

    const bodyFormData = new FormData();
    bodyFormData.append("receiverId", emailBusinessPartnerId?.toString());
    bodyFormData.append("emailBody", editorContent);
    bodyFormData.append("emailSubject", emailSubject);
    bodyFormData.append("templateName", emailTemplateName);
    bodyFormData.append("templateType", UseCaseType.disputeResolution);
    bodyFormData.append("senderCompanyId", companyId);

    if (files !== null && files.length > 0) {
      for (const item of files) {
        bodyFormData.append("file", item);
      }
    }

    return new Promise<void>((resolve, reject) => {
      useFetch("/api/partnerCommunication/AddUserEmailTemplate", "POST", {
        showSuccessToast: true,
        data: bodyFormData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
        thenCallBack: async () => {
          await sendMailInBulk();

          // reload templates after Adding/Updating User Email Template
          listUserEmailTemplates();

          resolve();

          // props.setLoader(true);
        },
        catchCallBack: reject,
        errorCallback: reject,
      });
    });
  };

  const sendMailInBulk = async () => {
    const EndPointBC = "/api/DisputeResolutionEmail/SendBulkEmail";

    const combinedBpAndMpId = selectedRow.map((item) => ({
      businessPartnerId: item?.businessPartnerId,
      mappingId: item?.mappingId,
    }));

    dispatch(updateIsSendingBulkMailsClosureTracker(true));

    const bodyFormData = new FormData();
    bodyFormData.append("businessPartnerDetails", JSON.stringify(combinedBpAndMpId));
    bodyFormData.append("emailSubject", emailSubject);
    bodyFormData.append("emailBody", editorContent);
    bodyFormData.append("companyId", companyId || DEFAULT_COMPANY_ID);
    bodyFormData.append("branchCode", branchCode || DEFAULT_BRANCH_CODE);

    if (files !== null && files.length > 0) {
      for (const item of files) {
        bodyFormData.append("file", item);
      }
    }

    return await useFetch<{
      bulkEmailStatus: {
        isComplete: boolean;
        totalPartnerCount: number;
        timeStamp: Date;
      };
    }>(EndPointBC, "POST", {
      showSuccessToast: true,
      data: bodyFormData,
      thenCallBack: (_res) => {
        // setShowLoadingIcon(false);
        setIntervalClosureTracker();
        handleClose();

        // set bulk mailed rows and time
        dispatch(updateMailSentPartnerCountClosureTracker(selectedRow.length || 0));
        dispatch(updateBulkMailedTimeClosureTracker(new Date()));

        // go back to main page
        handleBack();
      },
      catchCallBack: () => {
        dispatch(updateIsSendingBulkMailsClosureTracker(false));
        handleClose();
      },
    });
  };

  return (
    <>
      <Stack gap={2} mb={"90px"}>
        {/* Top Bar */}

        {/* Main Setup Section */}
        <Box component="section" className="emailSetupSection" sx={{ bgcolor: "#fff" }}>
          {/* Setup Email Header Bar */}
          <Box
            className="header_bar space_between"
            alignItems="center"
            p={2}
            sx={{ bgcolor: "#fff", borderBottom: 1, borderColor: "#dedede" }}
          >
            <Typography variant="h6" fontWeight="600">
              Setup Email
            </Typography>
            <IconButton size="small" disabled>
              <Settings />
            </IconButton>
          </Box>

          <EmailRecipientDetails fromEmail={userEmailSetting.from_email} bpCount={selectedRow.length} />

          <EmailEditorWithTemplate
            inputEditorState={{ emailTemplateId: emailTemplateId, templateError: templateError }}
            returnEditorState={(emailSubject, editorContent, templateId, files) => {
              setEmailSubject(emailSubject);
              setEditorContent(editorContent);
              setEmailTemplateId(templateId);

              setFiles(files);
            }}
            sectionsCustomizations={{
              subject:
                selectedRow.length > 0 && selectedRow[0].emailStatus === DisputeResolutionEmailStatuses.EmailNotSent,
            }}
            otherProps={{
              companyId: companyId,
              branchCode: branchCode,
              listUserEmailTemplates: listUserEmailTemplates,
              templateType: UseCaseType.disputeResolution,
            }}
          />
        </Box>
      </Stack>

      {/* Bottom Navigation Bar */}
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: -2,
          width: "100%",
          boxShadow: "0px -2px 4px 0px rgba(0, 0, 0, 0.08)",
          background: "#fff",
        }}
      >
        <Stack
          direction={"row"}
          sx={{
            justifyContent: "space-between",
            maxWidth: "900px",
            marginX: "auto",
            padding: "24px",
          }}
        >
          <Stack sx={{ display: "flex", flexDirection: "row", gap: "16px" }}>
            <Button color="inherit" onClick={handleBack} startIcon={<ChevronLeft />}>
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />

            <NdAsyncButton
              className="theme_btn"
              buttonComponent={Button}
              onClick={async () => {
                uiLogger(
                  uiLoggerNameDisputeResolution.UI_DR_CT_BulkMail_Send_Click.functionName,
                  companyId,
                  branchCode,
                  {
                    message: uiLoggerNameDisputeResolution.UI_DR_CT_BulkMail_Send_Click.message,
                  }
                );

                await checkIfUserEmailTemplateHasToUpdate();
              }}
            >
              Send Email
            </NdAsyncButton>
          </Stack>
        </Stack>
      </Box>

      {/* Update Email Template Dialog */}
      <UpdateEmailTemplateDialog
        open={openUpdateEmailTemplate}
        setOpen={setOpenUpdateEmailTemplate}
        yesAction={async () => {
          await updateUserEmailTemplate();
        }}
        noAction={async () => {
          await sendMailInBulk();
        }}
      />
    </>
  );
};

const EmailRecipientDetails = ({ fromEmail, bpCount }) => {
  return (
    <Box
      sx={{
        p: 2,
        bgcolor: "#f9f9f9",
        borderBottom: 1,
        borderColor: "#dedede",
      }}
    >
      <Stack width={"500px"} position={"relative"} gap={2}>
        <EmailFields label="From:" emails={[fromEmail]} isSender />
        <IconButton sx={{ position: "absolute", right: -50, top: -2 }}>
          <Edit />
        </IconButton>

        {/* To Email Field */}
        <Box className="email_field" sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="subtitle2" sx={{ color: "#00000061", mr: 2, width: 54 }}>
            To:
          </Typography>

          <Box
            className="d_flex vertical_center_align"
            sx={{
              bgcolor: "background.paper",
              borderRadius: 1,
              p: 0.5,
              flex: 1,
              border: 1,
              borderColor: "#dfdfdf",
            }}
          >
            <Stack direction="row" spacing={2} className="vertical_center_align" sx={{ width: "100%" }}>
              <Box className="avatar-group" sx={{ display: "flex", alignItems: "center", mr: 1 }}>
                <AvatarGroup />
              </Box>
              <Typography fontWeight="normal" fontSize={14} color="textPrimary" sx={{ lineHeight: "143%" }}>
                {bpCount} Business Partners
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

const AvatarGroup: React.FC = () => {
  const count = 5;
  return (
    <Box display="flex" alignItems="center" gap={-1}>
      {Array<number>(count)
        .fill(0)
        .map((_, index) => (
          <Avatar
            key={index}
            sx={{
              bgcolor: "#bdbdbd",
              border: "2px solid #fff",
              height: 21,
              width: 21,
              ml: index === 0 ? 0 : -2,
              zIndex: count - index,
            }}
          >
            {index < count - 1 ? <Person sx={{ fontSize: 14 }} /> : " "}
          </Avatar>
        ))}
    </Box>
  );
};

export default BulkMailing;
