import DeleteIcon from "@mui/icons-material/Delete";

import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
// import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  Paper,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import CustomToast from "src/Components/CustomToast";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { DOCUMENT_TYPES_OPTIONS_CUSTOM_GAP } from "src/Utils/Recon/Recon360/Constants";
import { CUSTOM_GAP_DES_COLUMNS } from "src/Utils/Recon/ReconSetting/Constants";
import { uiLoggerNamesCustomGapDescription } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import MonetaryInput from "../../Common/MonetaryInput";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DynamicContentImg from "../../../Graphics/dynamic_content.svg";
import NoGapDescriptionFoundImg from "../../../Graphics/No Gap Description found.svg";

const CustomGapDescription = () => {
  const [showLoadingIcon, setShowLoadingIcon] = useState(false);
  const [openCreateNewGapDescription, setOpenCreateNewGapDescription] = useState(false);
  const [data, setData] = useState<any>({});
  const [expandedSections, setExpandedSections] = useState<{ [key: string]: boolean }>({});
  const sectionRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const [editedSections, setEditedSections] = useState<{ [key: string]: boolean }>({});

  const [openRemoveConditionModal, setOpenRemoveConditionModal] = useState<boolean>(false);
  const [conditionToDelete, setConditionToDelete] = useState<{
    categoryKey: string;
    sectionIndex: number;
    conditionIndex: number;
  } | null>(null);

  const [openRemoveSectionModal, setOpenRemoveSectionModal] = useState<boolean>(false);
  const [sectionToDelete, setSectionToDelete] = useState<{ categoryKey: string; sectionIndex: number } | null>(null);

  const [selectedOptions, setSelectedOptions] = useState<string[]>(["All"]);
  const [conditions, setConditions] = useState<any[]>([]);
  const [gapDescriptionValOfModal, setGapDescriptionValOfModal] = useState<string>("");
  const [actionOwnerValOfModal, setActionOwnerValOfModal] = useState<string>("Own");
  const [selectedReconStatus, setSelectedReconStatus] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState(null);
  const isPopoverOpen = Boolean(anchorEl);

  const dynamicContentOptions = ["{Own Name}", "{Partner Name}", "{Group Name}"];

  const columnsOptionsAll = [
    "Document Type (Own)",
    "Document Type (Partner)",
    "Amount Difference",
    "Amount (Own)",
    "Amount (Partner)",
    "Entry Type (Own)",
    "Entry Type (Partner)",
    "Group Name (Own)",
    "Group Name (Partner)",
  ];
  const columnsOptionsAllOwn = ["Document Type (Own)", "Amount (Own)", "Entry Type (Own)", "Group Name (Own)"];
  const columnsOptionsPartner = [
    "Document Type (Partner)",
    "Amount (Partner)",
    "Entry Type (Partner)",
    "Group Name (Partner)",
  ];
  const reconStatusColumnMapping = {
    // All
    invoiceMismatchOther: columnsOptionsAll,
    paymentMismatchOther: columnsOptionsAll,
    dncnMismatchOther: columnsOptionsAll,
    suggestedTDSMatches: columnsOptionsAll,
    miscellneousOwn: columnsOptionsAll,
    msicelleneousBusinessPartner: columnsOptionsAll,
    manualMisMatch: columnsOptionsAll,
    ownSubsequentPeriodReconciliation: columnsOptionsAll,
    partnerSubsequentPeriodReconciliation: columnsOptionsAll,

    // Partner-related columns

    invoiceNotPresentInOwn: columnsOptionsPartner, // Partner-related columns
    paymentNotPresentInOwn: columnsOptionsPartner, // Partner-related columns
    dncnNotPresentInOwn: columnsOptionsPartner, // Partner-related columns
    tdsNotPresentInOwn: columnsOptionsPartner, // Partner-related columns
    partnerSubsequentPeriodReversal: columnsOptionsPartner, // Partner-related columns

    // Own-related columns

    invoiceNotPresentInBusinessPartner: columnsOptionsAllOwn, // Own-related columns
    paymentNotPresentBusinessPartner: columnsOptionsAllOwn, // Own-related columns
    dncnNotPresentBusinessPartner: columnsOptionsAllOwn, // Own-related columns
    tdsNotPresentBusinessPartner: columnsOptionsAllOwn, // Own-related columns
    ownSubsequentPeriodReversal: columnsOptionsAllOwn, // Own-related columns
  };

  const operatorsMap = {
    "Document Type (Own)": ["Exact Text"],
    "Document Type (Partner)": ["Exact Text"],
    "Amount Difference": ["Smaller Than (<)", "More Than (>)"],
    "Amount (Own)": ["Smaller Than (<)", "More Than (>)"],
    "Amount (Partner)": ["Smaller Than (<)", "More Than (>)"],
    "Entry Type (Own)": ["Exact Text"],
    "Entry Type (Partner)": ["Exact Text"],
    "Group Name (Own)": ["Exact Text"],
    "Group Name (Partner)": ["Exact Text"],
  };

  const actionOwnerOptions = ["Own", "Partner", "No Action (Own)", "No Action (Partner)"];

  const reconStatus = {
    invoiceMismatchOther: "Invoice Mismatch Other",
    invoiceNotPresentInBusinessPartner: "Invoice Not Present In Partner",
    invoiceNotPresentInOwn: "Invoice Not Present In Own",
    paymentMismatchOther: "Payment Mismatch Other",
    paymentNotPresentBusinessPartner: "Payment Not Present Partner",
    paymentNotPresentInOwn: "Payment Not Present In Own",
    dncnMismatchOther: "DNCN Mismatch Other",
    dncnNotPresentBusinessPartner: "DNCN Not Present Partner",
    dncnNotPresentInOwn: "DNCN Not Present In Own",
    suggestedTDSMatches: "Suggested TDS Matches",
    tdsNotPresentBusinessPartner: "TDS Not Present Partner",
    tdsNotPresentInOwn: "TDS Not Present In Own",
    miscellneousOwn: "Miscellaneous Own",
    msicelleneousBusinessPartner: "Miscellaneous Partner",
    manualMisMatch: "Manual MisMatch",
    ownSubsequentPeriodReversal: "Subsequent Reversal of Own Entries",
    partnerSubsequentPeriodReversal: "Subsequent Reversal of Partner Entries",
    ownSubsequentPeriodReconciliation: "Subsequent Reconciliation of Own Entries",
    partnerSubsequentPeriodReconciliation: "Subsequent Reconciliation of Partner Entries",
  };

  useEffect(() => {
    getCustomSummaryDescription();
    // eslint-disable-next-line
  }, []);

  const getCustomSummaryDescription = () => {
    useFetch(API_ENDPOINTS.GET_CUSTOM_SUMMARY_DESCRIPTION.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_CUSTOM_SUMMARY_DESCRIPTION.failureMessage,
      thenCallBack: (response) => {
        setData(response.data?.data || {});
      },
    });
  };

  const replaceWordStatusName = (word: string) => {
    const modifiedWord = reconStatus[word as keyof typeof reconStatus];
    return modifiedWord;
  };

  const toggleExpandCollapse = (key: string) => {
    setExpandedSections((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const updateColumnSelection = (categoryKey: string, sectionIndex: number, conditionIndex: number, value: string) => {
    const updatedData = { ...data };
    updatedData[categoryKey][sectionIndex].logic[conditionIndex].column = value;
    updatedData[categoryKey][sectionIndex].logic[conditionIndex].operator = operatorsMap[value][0];
    updatedData[categoryKey][sectionIndex].logic[conditionIndex].condition = "";
    setData(updatedData);
    setEditedSections((prev) => ({ ...prev, [`${categoryKey}-${sectionIndex}`]: true }));
  };

  const updateOperatorSelection = (
    categoryKey: string,
    sectionIndex: number,
    conditionIndex: number,
    value: string
  ) => {
    const updatedData = { ...data };
    updatedData[categoryKey][sectionIndex].logic[conditionIndex].operator = value;
    setData(updatedData);
    setEditedSections((prev) => ({ ...prev, [`${categoryKey}-${sectionIndex}`]: true }));
  };

  const updateConditionValue = (categoryKey: string, sectionIndex: number, conditionIndex: number, value) => {
    const updatedData = { ...data };
    updatedData[categoryKey][sectionIndex].logic[conditionIndex].condition = value;
    setData(updatedData);
    setEditedSections((prev) => ({ ...prev, [`${categoryKey}-${sectionIndex}`]: true }));
  };

  const updateActionOwner = (categoryKey: string, sectionIndex: number, value: string) => {
    const updatedData = { ...data };
    updatedData[categoryKey][sectionIndex].actionOwner = value;
    setData(updatedData);
    setEditedSections((prev) => ({ ...prev, [`${categoryKey}-${sectionIndex}`]: true }));
  };

  const updateGapDescription = (categoryKey: string, sectionIndex: number, value: string) => {
    const updatedData = { ...data };
    updatedData[categoryKey][sectionIndex].gapDescription = value;
    setData(updatedData);
    setEditedSections((prev) => ({ ...prev, [`${categoryKey}-${sectionIndex}`]: true }));
  };

  const removeLogicCondition = (categoryKey: string, sectionIndex: number, conditionIndex: number) => {
    setConditionToDelete({ categoryKey, sectionIndex, conditionIndex });
    setOpenRemoveConditionModal(true);
  };

  const confirmRemoveLogicCondition = () => {
    if (conditionToDelete) {
      const { categoryKey, sectionIndex, conditionIndex } = conditionToDelete;
      const updatedData = { ...data };
      updatedData[categoryKey][sectionIndex].logic.splice(conditionIndex, 1);

      setData(updatedData);
      setEditedSections((prev) => ({ ...prev, [`${categoryKey}-${sectionIndex}`]: true }));
      setOpenRemoveConditionModal(false);
      setConditionToDelete(null);
    }
  };

  const handleDeleteSection = (categoryKey: string, sectionIndex: number) => {
    setSectionToDelete({ categoryKey, sectionIndex });
    setOpenRemoveSectionModal(true);
  };

  const confirmRemoveSection = () => {
    if (sectionToDelete) {
      const { categoryKey, sectionIndex } = sectionToDelete;
      const updatedData = { ...data };
      updatedData[categoryKey].splice(sectionIndex, 1);
      setData(updatedData);
      insertAddCreateNewGapDescription(updatedData);
      setOpenRemoveSectionModal(false);
      setSectionToDelete(null);
    }
  };

  const addNewCondition = (categoryKey: string, sectionIndex: number) => {
    const updatedData = { ...data };
    updatedData[categoryKey][sectionIndex].logic.push({
      column: "",
      operator: "",
      condition: "",
    });
    setData(updatedData);
    setEditedSections((prev) => ({ ...prev, [`${categoryKey}-${sectionIndex}`]: true }));
  };

  const handleSaveSection = (categoryKey: string, sectionIndex: number) => {
    const section = data[categoryKey][sectionIndex];

    // Validation
    if (
      !section.gapDescription.trim() ||
      !section.actionOwner ||
      section.logic.some((item) => !item.column || !item.operator || (item.condition === "string" && !item.condition))
    ) {
      toast.error(<CustomToast message="Please fill in all logic fields & Gap description before saving." />);
      return;
    }

    insertAddCreateNewGapDescription(data);
    setEditedSections({});
  };

  const addNewCard = (categoryKey: string) => {
    const updatedData = { ...data };
    const newCard = {
      logic: [
        // {
        //   column: "",
        //   operator: "",
        //   condition: "",
        // },
      ],
      actionOwner: "Own",
      gapDescription: "",
    };
    updatedData[categoryKey].push(newCard);
    setData(updatedData);

    // Expand the new card
    setExpandedSections((prev) => ({ ...prev, [`${categoryKey}-${updatedData[categoryKey]?.length - 1}`]: true }));

    // Scroll to the new card with 80px space at the top
    setTimeout(() => {
      const sectionRef = sectionRefs.current[`${categoryKey}-${updatedData[categoryKey].length - 1}`];
      if (sectionRef) {
        const topOffset = sectionRef.getBoundingClientRect().top + window.scrollY - 80;
        window.scrollTo({ top: topOffset, behavior: "smooth" });
      }
    }, 0);
  };

  const handleAddCondition = () => {
    setConditions([...conditions, { column: "", operator: "", condition: "" }]);
  };

  const handleDeleteConditionOfModal = (index: number) => {
    const newConditions = conditions.filter((_, i) => i !== index);
    setConditions(newConditions);
  };

  const handleGapDescriptionChangeOfModal = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGapDescriptionValOfModal(event.target.value);
  };

  const handleActionOwnerChangeOfModal = (_: any, value: string) => {
    setActionOwnerValOfModal(value);
  };

  const reconStatusOptions = Object.entries(reconStatus).map(([key, value]) => ({
    key,
    label: value,
  }));

  const handleAddCreateNewGapDescription = () => {
    // Validation
    if (
      !gapDescriptionValOfModal.trim() ||
      !selectedReconStatus ||
      conditions.some((item) => !item.column || !item.operator || (item.condition === "string" && !item.condition))
    ) {
      toast.error(<CustomToast message="Please fill in all logic fields & Gap description before saving." />);
      return;
    }

    const newEntry = {
      logic: conditions,
      actionOwner: actionOwnerValOfModal,
      gapDescription: gapDescriptionValOfModal,
    };

    const updatedData = {
      ...data,
      [selectedReconStatus]: [...(data[selectedReconStatus] || []), newEntry],
    };

    insertAddCreateNewGapDescription(updatedData);
  };

  const insertAddCreateNewGapDescription = (dataPayload) => {
    setShowLoadingIcon(true);
    useFetch(API_ENDPOINTS.INSERT_CUSTOM_SUMMARY_DESCRIPTION.url, "POST", {
      failureMessage: API_ENDPOINTS.INSERT_CUSTOM_SUMMARY_DESCRIPTION.failureMessage,
      showSuccessToast: true,
      data: { customGapDescription: dataPayload },
      thenCallBack: () => {
        setShowLoadingIcon(false);
        setData(dataPayload); // Use dataPayload directly
        handleCloseCreateNewGapDescription();
        uiLogger(
          uiLoggerNamesCustomGapDescription.UI_RL_ReconSummaryCalculation_CustomGapDescription_Save_Click.functionName,
          null,
          null,
          {
            message:
              uiLoggerNamesCustomGapDescription.UI_RL_ReconSummaryCalculation_CustomGapDescription_Save_Click.message,
          }
        );
      },
      catchCallBack: () => {
        setShowLoadingIcon(false);
      },
    });
  };

  const handleCloseCreateNewGapDescription = () => {
    setOpenCreateNewGapDescription(false);
    setConditions([]);
    setSelectedReconStatus("");
    setGapDescriptionValOfModal("");
    setActionOwnerValOfModal("Own");
  };

  const handleOpenCreateNewGapDescription = () => {
    setOpenCreateNewGapDescription(true);
    setConditions([{ column: "", operator: "", condition: "" }]); // Add a default condition row
  };

  // Add "All" option to the list of options
  const optionsWithAll = [{ key: "All", label: "All" }, ...reconStatusOptions];

  // Sort options in descending order based on the count of elements in `data`
  const sortedOptions = optionsWithAll
    .filter((option) => option.key !== "All") // Exclude "All" from sorting
    .sort((a, b) => (data[b.key]?.length || 0) - (data[a.key]?.length || 0)); // Sort by length in descending order

  // Add "All" option at the start
  const finalOptions = [optionsWithAll.find((option) => option.key === "All"), ...sortedOptions];

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const handleDynamicContentItemClick = (item) => {
    navigator.clipboard.writeText(item); // Copy text to clipboard
    toast.success(<CustomToast message={`Dynamic content ${item} copied`} />);
    handlePopoverClose(); // Close the popover
  };

  const noCustomGapDescription = (data, selectedOptions) => {
    if (selectedOptions.includes("All")) {
      // Check if all keys in the data object are empty arrays
      return Object.values(data).every((keyData) => Array.isArray(keyData) && keyData.length === 0);
    } else {
      // Check if all selected keys are empty arrays
      return selectedOptions.every((key) => Array.isArray(data[key]) && data[key].length === 0);
    }
  };

  return (
    <>
      <Grid sx={{ mb: 3 }}>
        <Paper sx={{ p: 2 }}>
          <div className="vertical_center_align">
            <div style={{ width: "90%" }}>
              <div className="vertical_center_align">
                <div className="fs_20 fw_600">Custom Gap Description</div>
                <div className="ml_15">
                  <Autocomplete
                    className="update-custom-border-radius"
                    limitTags={1}
                    multiple
                    options={finalOptions}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option, { selected }) => (
                      <li {...props} style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Checkbox style={{ marginRight: 8 }} checked={selected} />
                          {option.label}
                        </div>
                        {/* Only show Chip if the option is not "All" */}
                        {option.key !== "All" && (
                          <Chip
                            color={data[option.key]?.length > 0 ? "primary" : "default"}
                            size="small"
                            label={data[option.key]?.length || 0} // Display the count
                            style={{ marginLeft: "auto" }} // Position Chip at the end
                          />
                        )}
                      </li>
                    )}
                    style={{ width: 500 }}
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                    value={optionsWithAll.filter((option) => selectedOptions.includes(option.key))}
                    onChange={(_event, newValue) => {
                      const newSelectedKeys = newValue.map((option) => option.key);

                      if (newSelectedKeys.includes("All") && newSelectedKeys.length === 1) {
                        // Case 1: If "All" is selected, keep only "All"
                        setSelectedOptions(["All"]);
                      } else if (selectedOptions.includes("All") && newSelectedKeys.length > 1) {
                        // Case 2: If "All" is already selected and another option is selected, deselect "All"
                        setSelectedOptions(newSelectedKeys.filter((key) => key !== "All"));
                      } else if (newSelectedKeys.includes("All") && selectedOptions.length > 0) {
                        // Case 3: If other options are selected and then "All" is selected, deselect all others and keep "All"
                        setSelectedOptions(["All"]);
                      } else {
                        // Normal case: Update the selected options
                        setSelectedOptions(newSelectedKeys);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="vertical_center_align">
              <Button
                variant="contained"
                onClick={handleOpenCreateNewGapDescription}
                style={{ width: "280px" }}
                className="purple_btn"
              >
                Create new gap description
              </Button>
            </div>
          </div>
        </Paper>
      </Grid>

      <Grid container spacing={2}>
        {noCustomGapDescription(data, selectedOptions) ? (
          <Paper
            sx={{
              mt: 5,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              borderRadius: "4px",
              ml: 2,
              p: "40px 20px",
            }}
          >
            <Box
              component="img"
              src={NoGapDescriptionFoundImg}
              sx={{
                width: "358px",
                height: "219px",
                objectFit: "contain",
              }}
            />

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "24px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "8px",
                  mt: 4,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "Roboto",
                    fontWeight: 500,
                    fontSize: "20px",
                    letterSpacing: "0.15px",
                    lineHeight: "160%",
                    color: "rgba(0,0,0,0.87)",
                    textAlign: "center",
                  }}
                >
                  No Gap Description found
                </Typography>

                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    fontSize: "16px",
                    letterSpacing: "0.15px",
                    lineHeight: "150%",
                    color: "rgba(0,0,0,0.6)",
                    textAlign: "center",
                  }}
                >
                  Click the button below to get started!
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: "16px",
                  alignItems: "center",
                }}
              >
                <Button
                  startIcon={<PlayCircleOutlineIcon />}
                  variant="outlined"
                  style={{ border: "1px solid rgba(84, 28, 76, 0.50)", borderRadius: "4px", color: "#541C4C" }}
                >
                  Demo
                </Button>

                <Button
                  variant="contained"
                  onClick={handleOpenCreateNewGapDescription}
                  style={{ width: "280px" }}
                  className="purple_btn"
                >
                  Create new gap description
                </Button>
              </Box>
            </Box>
          </Paper>
        ) : (
          Object.entries(data).map(([key, sections]: any) => {
            if (selectedOptions.includes("All") || selectedOptions.includes(key)) {
              return (
                <Grid item xs={12} key={key}>
                  <Grid container className="mb_23 mt_4" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">{replaceWordStatusName(key)}</Typography>
                    <Button
                      variant="outlined"
                      style={{ border: "1px solid rgba(84, 28, 76, 0.50)", borderRadius: "4px", color: "#541C4C" }}
                      onClick={() => addNewCard(key)}
                    >
                      Add
                    </Button>
                  </Grid>
                  <Grid container spacing={2}>
                    {sections.map((section: any, index: number) => (
                      <Grid item xs={12} sm={6} key={index} ref={(el) => (sectionRefs.current[`${key}-${index}`] = el)}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            // maxWidth: '601px',
                            bgcolor: "#fff",
                            borderRadius: "4px",
                            // padding: '16px',
                            border: "1px solid #0000001f",
                          }}
                        >
                          {/* Header Section */}
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              borderBottom: "1px solid #0000001f",
                              p: 1.5,
                            }}
                          >
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                              {/* <IconButton>
                                <DragIndicatorIcon />
                              </IconButton> */}
                              <Tooltip title="This is the order of the gap description" arrow placement="right">
                                <Chip
                                  label={index + 1}
                                  size="small"
                                  sx={{
                                    bgcolor: "#2e7d32",
                                    color: "#fff",
                                    padding: "3px 4px",
                                    height: "24px",
                                    "& .MuiChip-label": {
                                      px: "6px",
                                      fontSize: "13px",
                                      lineHeight: "18px",
                                    },
                                  }}
                                />
                              </Tooltip>
                            </Box>
                            <Box sx={{ display: "flex", gap: 1 }}>
                              <Button
                                variant="contained"
                                size="small"
                                className={editedSections[`${key}-${index}`] ? "purple_btn" : "grey_btn"}
                                disabled={!editedSections[`${key}-${index}`]}
                                onClick={() => handleSaveSection(key, index)}
                              >
                                Save
                              </Button>
                              <IconButton onClick={() => handleDeleteSection(key, index)}>
                                <DeleteIcon />
                              </IconButton>
                              <IconButton onClick={() => toggleExpandCollapse(`${key}-${index}`)}>
                                {expandedSections[`${key}-${index}`] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                              </IconButton>
                            </Box>
                          </Box>

                          {/* Gap Description Section */}
                          <Box sx={{ padding: "16px 20px", borderBottom: "1px solid #0000001f" }}>
                            <Grid container className="d_flex">
                              <Grid item xs={9}>
                                <Typography variant="subtitle2" sx={{ mb: 1 }}>
                                  Gap Description
                                </Typography>
                                <TextField
                                  className="update-custom-border-radius"
                                  size="small"
                                  placeholder="Enter Text"
                                  fullWidth
                                  value={section.gapDescription}
                                  onChange={(e) => updateGapDescription(key, index, e.target.value)}
                                  InputProps={{
                                    endAdornment: (
                                      <Tooltip title="Dynamic Content" arrow>
                                        <img
                                          src={DynamicContentImg}
                                          alt="icons"
                                          width={24}
                                          height={24}
                                          onClick={handlePopoverOpen}
                                          style={{ cursor: "pointer" }}
                                        />
                                      </Tooltip>
                                    ),
                                  }}
                                />
                              </Grid>
                              <Grid xs={3} className="pl_16">
                                <Typography variant="subtitle2" sx={{ mb: 1 }}>
                                  Action Owner
                                </Typography>
                                <Autocomplete
                                  sx={{ minWidth: "81px" }}
                                  className="update-custom-border-radius"
                                  size="small"
                                  disableClearable={true}
                                  options={actionOwnerOptions}
                                  value={section.actionOwner}
                                  onChange={(_, value) => updateActionOwner(key, index, value)}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                              </Grid>
                            </Grid>
                          </Box>

                          {/* Conditions Section */}
                          {expandedSections[`${key}-${index}`] && (
                            <Box sx={{ padding: "16px 20px" }}>
                              <Typography variant="subtitle2" className="mb_8">
                                Conditions
                              </Typography>
                              {section.logic.map((condition, idx) => (
                                <Grid container key={idx} sx={{ display: "flex", marginTop: "6px" }}>
                                  <Grid
                                    item
                                    xs={0.2}
                                    style={{ display: "flex", justifyContent: "center", alignItems: "flex-end" }}
                                  >
                                    <Typography variant="body1">{idx > 0 ? "&" : " "}</Typography>
                                  </Grid>

                                  <Grid item xs={4.8} className="pl_8">
                                    <Autocomplete
                                      className="update-custom-border-radius"
                                      onChange={(_, value) => updateColumnSelection(key, index, idx, value)}
                                      options={reconStatusColumnMapping[key] || columnsOptionsAll}
                                      size="small"
                                      getOptionLabel={(option) => option}
                                      value={condition.column}
                                      disableClearable={true}
                                      renderInput={(params) => <TextField {...params} label="Column" />}
                                    />
                                  </Grid>

                                  <Grid item xs={3} className="pl_8">
                                    <Autocomplete
                                      className="update-custom-border-radius"
                                      onChange={(_, value) => updateOperatorSelection(key, index, idx, value)}
                                      options={operatorsMap[condition.column] || []} // Use the selected column to fetch operator options
                                      size="small"
                                      getOptionLabel={(option) => option}
                                      value={condition.operator}
                                      disableClearable={true}
                                      renderInput={(params) => <TextField {...params} placeholder="Operator" />}
                                    />
                                  </Grid>

                                  <Grid item xs={3} className="pl_8">
                                    {(condition.column === CUSTOM_GAP_DES_COLUMNS.DOC_TYPE_PARTNER ||
                                      condition.column === CUSTOM_GAP_DES_COLUMNS.DOC_TYPE_OWN ||
                                      condition.column === CUSTOM_GAP_DES_COLUMNS.GROUP_NAME_OWN ||
                                      condition.column === CUSTOM_GAP_DES_COLUMNS.GROUP_NAME_PARTNER) &&
                                    condition.operator === "Exact Text" ? (
                                      <TextField
                                        className="update-custom-border-radius"
                                        placeholder="Condition"
                                        size="small"
                                        type="text"
                                        value={condition.condition}
                                        onChange={(e) => updateConditionValue(key, index, idx, e.target.value)}
                                      />
                                    ) : condition.column === CUSTOM_GAP_DES_COLUMNS.ENTRY_TYPE_PARTNER ||
                                      condition.column === CUSTOM_GAP_DES_COLUMNS.ENTRY_TYPE_OWN ? (
                                      <div>
                                        <Autocomplete
                                          className="update-custom-border-radius"
                                          freeSolo={false}
                                          options={DOCUMENT_TYPES_OPTIONS_CUSTOM_GAP.map(
                                            (name) => name[0].toUpperCase() + name.slice(1)
                                          )}
                                          renderInput={(params) => <TextField {...params} placeholder="Condition" />}
                                          fullWidth={true}
                                          size="small"
                                          value={condition.condition}
                                          onChange={(_, val) => updateConditionValue(key, index, idx, val)}
                                        />
                                      </div>
                                    ) : (
                                      <MonetaryInput
                                        className="update-custom-border-radius"
                                        size="small"
                                        key={condition.condition}
                                        value={
                                          !isNaN(parseFloat(condition.condition as string))
                                            ? parseFloat(condition.condition as string).toLocaleString("en-IN")
                                            : condition.condition || ""
                                        }
                                        placeholder="Value"
                                        setValue={(value) => {
                                          if (
                                            (value.toString() !== condition?.condition?.toString() && value) ||
                                            value === 0
                                          )
                                            updateConditionValue(key, index, idx, value);
                                        }}
                                        InputProps={{
                                          startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                                        }}
                                        sx={{
                                          "input::placeholder": {
                                            fontSize: "14px",
                                          },
                                        }}
                                      />
                                    )}
                                  </Grid>

                                  <Grid item xs={1} className="pl_8">
                                    <IconButton color="error" onClick={() => removeLogicCondition(key, index, idx)}>
                                      <DeleteIcon />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              ))}
                              <Button
                                sx={{
                                  alignSelf: "flex-start",
                                  color: "#1976d2",
                                  textTransform: "none",
                                  fontSize: "16px",
                                  marginTop: "8px",
                                }}
                                onClick={() => addNewCondition(key, index)}
                              >
                                Add condition
                              </Button>
                            </Box>
                          )}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              );
            }
          })
        )}
      </Grid>
      <>
        {/* add new gap desc */}
        <Dialog
          // onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openCreateNewGapDescription}
          maxWidth="md"
          fullWidth
          sx={{ width: "664px", margin: "auto" }}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Add Gap Description
          </DialogTitle>
          <IconButton
            disabled={showLoadingIcon}
            aria-label="close"
            onClick={handleCloseCreateNewGapDescription}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            {" "}
            <CloseIcon />
          </IconButton>
          <DialogContent dividers style={{ padding: "0px" }}>
            {/* Gap Description Section */}
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box className="border-bottom " style={{ padding: "16px 24px" }}>
                <Typography variant="subtitle2" className="mb_8">
                  Select Recon Status
                </Typography>
                <Autocomplete
                  className="update-custom-border-radius"
                  size="small"
                  options={reconStatusOptions}
                  getOptionLabel={(option) => option.label}
                  value={reconStatusOptions.find((option) => option.key === selectedReconStatus) || null}
                  onChange={(_, value) => setSelectedReconStatus(value?.key || "")}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
              <Box className="border-bottom" style={{ padding: "16px 24px" }}>
                <Grid container className="d_flex">
                  <Grid item xs={9}>
                    <Typography variant="subtitle2" className="mb_8">
                      Gap Description
                    </Typography>
                    <TextField
                      className="update-custom-border-radius"
                      size="small"
                      placeholder="Enter Text"
                      fullWidth
                      value={gapDescriptionValOfModal}
                      onChange={handleGapDescriptionChangeOfModal}
                      InputProps={{
                        endAdornment: (
                          <Tooltip title="Dynamic Content" arrow>
                            <img
                              src={DynamicContentImg}
                              alt="icons"
                              width={24}
                              height={24}
                              onClick={handlePopoverOpen}
                              style={{ cursor: "pointer" }}
                            />
                          </Tooltip>
                        ),
                      }}
                    />
                  </Grid>
                  {/* <Grid xs={0.3}></Grid> */}
                  <Grid xs={3} className="pl_16">
                    <Typography variant="subtitle2" className="mb_8">
                      Action Owner
                    </Typography>
                    <Autocomplete
                      className="update-custom-border-radius"
                      size="small"
                      disableClearable={true}
                      options={actionOwnerOptions}
                      value={actionOwnerValOfModal}
                      onChange={handleActionOwnerChangeOfModal}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>

            {/* Conditions Section */}
            <Box style={{ padding: "16px 24px" }}>
              <Typography variant="subtitle2" className="mb_8">
                Conditions
              </Typography>
              {conditions.map((condition, index) => (
                <Grid container key={index} sx={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                  <Grid item xs={4}>
                    <Autocomplete
                      className="update-custom-border-radius"
                      onChange={(_, value) => {
                        const newConditions = [...conditions];
                        newConditions[index].column = value;
                        newConditions[index].operator = operatorsMap[value][0];
                        newConditions[index].condition = "";
                        setConditions(newConditions);
                      }}
                      options={reconStatusColumnMapping[selectedReconStatus] || []}
                      size="small"
                      getOptionLabel={(option) => option}
                      value={condition.column}
                      disableClearable={true}
                      renderInput={(params) => <TextField {...params} label="Column" />}
                    />
                  </Grid>

                  <Grid item xs={4} className="pl_10">
                    <Autocomplete
                      className="update-custom-border-radius"
                      onChange={(_, value) => {
                        const newConditions = [...conditions];
                        newConditions[index].operator = value;
                        setConditions(newConditions);
                      }}
                      options={operatorsMap[condition.column] || []}
                      size="small"
                      getOptionLabel={(option) => option}
                      value={condition.operator}
                      disableClearable={true}
                      renderInput={(params) => <TextField {...params} placeholder="Operator" />}
                    />
                  </Grid>

                  <Grid item xs={3} className="pl_10">
                    {(condition.column === CUSTOM_GAP_DES_COLUMNS.DOC_TYPE_PARTNER ||
                      condition.column === CUSTOM_GAP_DES_COLUMNS.DOC_TYPE_OWN ||
                      condition.column === CUSTOM_GAP_DES_COLUMNS.GROUP_NAME_OWN ||
                      condition.column === CUSTOM_GAP_DES_COLUMNS.GROUP_NAME_PARTNER) &&
                    condition.operator === "Exact Text" ? (
                      <TextField
                        className="update-custom-border-radius"
                        size="small"
                        placeholder="Condition"
                        value={condition.condition}
                        onChange={(e) => {
                          const newConditions = [...conditions];
                          newConditions[index].condition = e.target.value;
                          setConditions(newConditions);
                        }}
                      />
                    ) : condition.column === CUSTOM_GAP_DES_COLUMNS.ENTRY_TYPE_PARTNER ||
                      condition.column === CUSTOM_GAP_DES_COLUMNS.ENTRY_TYPE_OWN ? (
                      <div>
                        <Autocomplete
                          className="update-custom-border-radius"
                          freeSolo={false}
                          options={DOCUMENT_TYPES_OPTIONS_CUSTOM_GAP.map(
                            (name) => name[0].toUpperCase() + name.slice(1)
                          )}
                          renderInput={(params) => <TextField {...params} placeholder="Condition" />}
                          fullWidth={true}
                          size="small"
                          value={condition.condition}
                          onChange={(_, val) => {
                            const newConditions = [...conditions];
                            newConditions[index].condition = val;
                            setConditions(newConditions);
                          }}
                        />
                      </div>
                    ) : (
                      <MonetaryInput
                        className="update-custom-border-radius"
                        placeholder="Value"
                        size="small"
                        value={condition.condition}
                        setValue={(value) => {
                          const newConditions = [...conditions];
                          newConditions[index].condition = value;
                          setConditions(newConditions);
                        }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={1} className="pl_10">
                    <IconButton color="error" onClick={() => handleDeleteConditionOfModal(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
              <Button onClick={handleAddCondition} sx={{ mt: 2 }}>
                Add Condition
              </Button>
            </Box>
          </DialogContent>
          <DialogActions sx={{ p: 2 }} className="bg_footer">
            <Button
              className={"purple_btn"}
              disabled={showLoadingIcon}
              startIcon={<LoadingIcon loading={showLoadingIcon} />}
              onClick={() => {
                //   handleClose();
                handleAddCreateNewGapDescription();
              }}
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          aria-labelledby="customized-dialog-title"
          open={openRemoveConditionModal}
          maxWidth="md"
          fullWidth
          sx={{ width: "598px", margin: "auto" }}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Are you sure?
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setOpenRemoveConditionModal(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers sx={{ p: "16px 24px" }}>
            <Alert severity="error">
              <Typography variant="body2">
                This will delete the condition inside the gap description and it will not be recovered.
              </Typography>
            </Alert>
          </DialogContent>
          <DialogActions sx={{ p: 2 }} className="bg_footer">
            <Button variant="text" onClick={() => setOpenRemoveConditionModal(false)}>
              Cancel
            </Button>
            <Button
              variant="contained"
              className="red_btn"
              // style={{ width: "143px" }}
              onClick={confirmRemoveLogicCondition}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        {/* Confirmation Dialog for Section Deletion */}
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={openRemoveSectionModal}
          maxWidth="md"
          fullWidth
          sx={{ width: "598px", margin: "auto" }}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Are you sure?
            <IconButton
              aria-label="close"
              onClick={() => setOpenRemoveSectionModal(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers sx={{ p: "16px 24px" }}>
            <Alert severity="error">
              <Typography variant="body2">
                This will delete the whole gap description and it will not be recovered.
              </Typography>
            </Alert>
          </DialogContent>
          <DialogActions sx={{ p: 2 }} className="bg_footer">
            <Button variant="text" onClick={() => setOpenRemoveSectionModal(false)}>
              Cancel
            </Button>
            <Button variant="contained" className="red_btn" onClick={confirmRemoveSection}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Popover
          id="dynamic-content-popover"
          open={isPopoverOpen}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <List>
            {dynamicContentOptions.map((item, idx) => (
              <ListItem
                key={idx}
                onClick={() => handleDynamicContentItemClick(item)}
                className={idx < dynamicContentOptions.length - 1 ? "border-bottom" : ""}
                style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              >
                <span style={{ flexGrow: 1 }}>{item}</span>
                <ContentCopyIcon style={{ marginLeft: "5px", color: "grey" }} />
              </ListItem>
            ))}
          </List>
        </Popover>
      </>
    </>
  );
};

export default CustomGapDescription;
