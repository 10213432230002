/* eslint-disable @typescript-eslint/no-unused-vars */
import { CallMade, DownloadingSharp, HistoryOutlined, MailOutline, Unsubscribe } from "@mui/icons-material";
import { ListItemIcon, MenuItem, Stack } from "@mui/material";
import { MRT_Row } from "material-react-table";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import useFetch from "src/Components/Common/useFetch";
import ReactTable, { ColDef } from "src/Components/ReactTable/ReactTable";
import TasksHistoryModal from "src/Components/Recon360/Tickets/TasksHistoryModal";
import { BusinessPartnerListForBcBeta } from "src/entity/recon-entity/ReconInterfaces";
import { BalanceConfirmationBetaStatuses } from "src/Utils/PartnerCommunication";
import { StopAutoReminder, UpdateResponseDialog } from "../../../Common/CommonDialogs";
import { DEFAULT_BRANCH_CODE, DEFAULT_COMPANY_ID } from "../../../CommonLegacy/CommonComponents";
import { PartnerCommsContext } from "../../../Context/PartnerCommunicationContext";
import ColumnDefinitions from "../../Common/ColumnDefinitions";
import { ColorToggleButton, CustomBulkActionAlert, NoCommunication } from "../../Common/Components";
import BcNewContext from "../BcNewContext";
import MultipleCommunicationTypes from "../Mailing/MultipleCommunicationTypes";

type NotReceivedQF = "all" | "issue raised";

const ResponseNotReceivedTab = () => {
  const { setEmailBusinessPartnerId, setMappingId, setBusinessPartnerName, setCommunicationTypeId } =
    useContext(PartnerCommsContext);
  const {
    setTabChipLabel,
    rowsDataBcBeta,
    companyId,
    branchCode,
    loader,
    currentRow,
    setCurrentRow,
    setMailingRows,
    setOpenBcBetaMailThread,
    setOpenBcNewMailing,
    setActiveStep,
    listAllBusinessPartners,
  } = useContext(BcNewContext);

  const [columnDefinitions, setColumnDefinitions] = useState<ColDef<BusinessPartnerListForBcBeta>[]>([]);
  const [selectedRow, setSelectedRow] = useState<BusinessPartnerListForBcBeta[]>([]);
  const [selectedQF, setSelectedQF] = useState<NotReceivedQF>("all");
  const pendingRows: BusinessPartnerListForBcBeta[] = rowsDataBcBeta.filter((row) => row.statusOverview === "Pending");
  const finalQuickFilteredRows = pendingRows.filter((row) =>
    selectedQF === "issue raised" ? row.issueRaised !== "-" : true
  );
  const NO_COMMUNICATION = !loader && pendingRows.length === 0;

  // States for Table
  const [openUpdateResDialog, setOpenUpdateResDialog] = useState(false);
  const [openStopReminder, setOpenStopReminder] = useState(false);
  const [openMultipleCommsType, setOpenMultipleCommsType] = useState(false);

  const ASYNC_INTERVAL_OPs_IN_PROGRESS = false;

  const handleTabChipLabel = useCallback(() => {
    setTabChipLabel((prev) => ({ ...prev, responseNotReceived: pendingRows.length }));
  }, [pendingRows]);

  useEffect(() => {
    handleTabChipLabel();
  }, [rowsDataBcBeta]);

  const RowActionMenuItems = ({ row, closeMenu }: { row: MRT_Row<BusinessPartnerListForBcBeta>; closeMenu: any }) => {
    return {
      // {/* Send/View Email Button */}
      sendViewMail: (
        <MenuItem
          disabled={ASYNC_INTERVAL_OPs_IN_PROGRESS}
          key={"sendViewMail"}
          onClick={async () => {
            setEmailBusinessPartnerId(row.original.businessPartnerId);
            setMappingId(row.original.mappingId);
            setBusinessPartnerName(row.original.businessPartnerName);
            setCurrentRow(row.original);
            setOpenBcBetaMailThread(true);

            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <MailOutline sx={{ fill: "#541c4c" }} />
          </ListItemIcon>
          Send/View Email
        </MenuItem>
      ),
      // {/* Update Response */}
      updatedResponse: (
        <MenuItem
          disabled={
            ASYNC_INTERVAL_OPs_IN_PROGRESS ||
            row.original.status === BalanceConfirmationBetaStatuses.RequestBalanceConfirmation
          }
          key={"updatedResponse"}
          onClick={async () => {
            // logic
            setCurrentRow(row.original);
            setOpenUpdateResDialog(true);
            // close Menu
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <CallMade sx={{ fill: "#541c4c" }} />
          </ListItemIcon>
          Update Response
        </MenuItem>
      ),
      // {/* Stop Auto Reminder */}
      stopAutoReminder: (
        <MenuItem
          hidden={import.meta.env.VITE_APP_NAKAD_ENV === "test" ? false : true}
          disabled={
            ASYNC_INTERVAL_OPs_IN_PROGRESS ||
            row.original.status === BalanceConfirmationBetaStatuses.RequestBalanceConfirmation
          }
          key={"stopAutoReminder"}
          onClick={async () => {
            // logic
            setCurrentRow(row.original);
            setOpenStopReminder(true);
            // close Menu
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <Unsubscribe sx={{ fill: "#541c4c" }} />
          </ListItemIcon>
          Stop Auto-reminder
        </MenuItem>
      ),
      // {/* View History */}
      viewHistory: (
        <MenuItem
          disabled={
            ASYNC_INTERVAL_OPs_IN_PROGRESS ||
            row.original.ticketId === null ||
            row.original.status === BalanceConfirmationBetaStatuses.RequestBalanceConfirmation
          }
          key={"viewHistory"}
          onClick={() => {
            currentSelectedTaskDetails.current = {
              businessPartnerName: row.original.businessPartnerName,
              taskId: row.original.ticketId,
            };
            setShowTasksHistoryModal(true);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <HistoryOutlined sx={{ fill: "#541c4c" }} />
          </ListItemIcon>
          View History
        </MenuItem>
      ),
    };
  };

  const StopAutoReminderForPartner = async (businessPartnerId: number) => {
    return useFetch<{ message: string }>("/api/AutoReminder/StopActiveAutoReminderForGivenPartner", "POST", {
      failureMessage: "Failed to stop auto reminder",
      showSuccessToast: true,
      data: {
        companyId: companyId || DEFAULT_COMPANY_ID,
        branchCode: branchCode || DEFAULT_BRANCH_CODE,
        businessPartnerId,
      },
      thenCallBack: (_res) => {
        listAllBusinessPartners();
      },
    });
  };

  // START space for bulk tasks

  const [showTasksHistoryModal, setShowTasksHistoryModal] = useState<boolean>(false);
  const currentSelectedTaskDetails = useRef({ taskId: null, businessPartnerName: null });

  // END space for bulk tasks

  useEffect(() => {
    setMailingRows(selectedRow);
  }, [selectedRow]);

  // Use useCallback to memoize the callback function
  const handleColDefsData = useCallback((data: React.SetStateAction<ColDef<BusinessPartnerListForBcBeta>[]>) => {
    setColumnDefinitions(data); // Update state with data from child
  }, []);

  return NO_COMMUNICATION ? (
    <NoCommunication />
  ) : (
    <>
      <ColumnDefinitions
        props={{
          notRecMailsDeps: [],
          setNotRecMailsDefs: handleColDefsData,
          type: "notRecMails",
        }}
        otherProps={{
          isSyncingMailStatus: false,
        }}
      />
      <Stack className="no_response_section" p={2} sx={{ gap: 1 }}>
        <CustomBulkActionAlert
          alertTitle="Response not received"
          alertText="Accept responses if you think these are correct or incorrect"
          severity="lightgray"
          icon={<DownloadingSharp fontSize="medium" sx={{ color: "#fff" }} />}
          actionName="Send Reminder Mail"
          actionFn={
            selectedRow.length
              ? () => {
                  // check if all rows have same communication type
                  const firstFoundCommType = selectedRow.find((row) => row.communicationTypeId);
                  const allRowsSameCommsType = selectedRow.every(
                    (row) => row?.communicationTypeId === firstFoundCommType?.communicationTypeId
                  );
                  setMailingRows(selectedRow);

                  // setCommunicateTypeId if same for all or show the dialogue for choosing communication type and set comms type
                  // then set mailing Rows based on the communication type

                  if (firstFoundCommType && allRowsSameCommsType === false) {
                    setOpenMultipleCommsType(true);
                  } else {
                    // next page in the Bulk Mailing stepper if the communication type is preset else first page to select comms type
                    setCommunicationTypeId(firstFoundCommType?.communicationTypeId || null);
                    setActiveStep(firstFoundCommType?.communicationTypeId ? 2 : 0);
                    setOpenBcNewMailing(true);
                  }
                  // setCommunicationTypeId(selectedRow[0].communicationTypeId);
                }
              : undefined
          }
        />

        <ColorToggleButton<NotReceivedQF> allQFValues={["all", "issue raised"]} setSelectedQF={setSelectedQF} />

        <ReactTable
          // key={JSON.stringify(selectedRow)}
          columns={columnDefinitions}
          rows={finalQuickFilteredRows}
          setSelectedRow={setSelectedRow}
          loading={loader}
          enableGrouping={true}
          positionToolbarAlertBanner={"top"}
          renderRowActionMenuItems={({ row, closeMenu }) => {
            const { sendViewMail, updatedResponse, stopAutoReminder, viewHistory } = RowActionMenuItems({
              row,
              closeMenu,
            });
            return [sendViewMail, updatedResponse, stopAutoReminder, viewHistory];
          }}
          initialStateColumnVisibility={{
            "Response Status": false,
            "Response Approved": false,
          }}
        />
      </Stack>

      <UpdateResponseDialog open={openUpdateResDialog} setOpen={setOpenUpdateResDialog} link={currentRow?.portalLink} />

      <MultipleCommunicationTypes
        open={openMultipleCommsType}
        setOpen={setOpenMultipleCommsType}
        setOpenBcNewMailing={setOpenBcNewMailing}
        setActiveStep={setActiveStep}
      />

      <StopAutoReminder
        open={openStopReminder}
        setOpen={setOpenStopReminder}
        stopAction={() => StopAutoReminderForPartner(currentRow?.businessPartnerId)}
        partnerName={currentRow?.businessPartnerName}
      />

      {showTasksHistoryModal && (
        <TasksHistoryModal
          showTasksHistoryModal={showTasksHistoryModal}
          setShowTasksHistoryModal={setShowTasksHistoryModal}
          taskId={currentSelectedTaskDetails.current.taskId}
          businessPartnerName={currentSelectedTaskDetails.current.businessPartnerName}
        />
      )}
    </>
  );
};

export default ResponseNotReceivedTab;
