//CLOSURE TRACKER
export enum PartnerRelationships {
  Vendor = "Vendor",
  Customer = "Customer",
}

type OtherFormat = string & NonNullable<unknown>;
type colorMap = { color?: string; bgColor?: string };

export enum DisputeResolutionEmailStatuses {
  EmailNotSent = "Email Not Sent",
  EmailSent = "Email Sent",
  ReminderSent = "Reminder Sent",
  CheckPartnerReply = "Check Partner Reply",
  CheckTeamReply = "Check Team Reply",
}

export const DisputeResolutionEmailStatusesColorMap: Record<DisputeResolutionEmailStatuses | OtherFormat, colorMap> = {
  [DisputeResolutionEmailStatuses.EmailNotSent]: {
    color: "#000",
    bgColor: "#D6D7D6",
  },
  [DisputeResolutionEmailStatuses.EmailSent]: {
    color: "#000",
    bgColor: "#E5A0DB",
  },
  [DisputeResolutionEmailStatuses.ReminderSent]: {
    color: "#000",
    bgColor: "#2096F2",
  },
  [DisputeResolutionEmailStatuses.CheckPartnerReply]: {
    color: "#000",
    bgColor: "#DFAE39",
  },
  [DisputeResolutionEmailStatuses.CheckTeamReply]: {
    color: "#000",
    bgColor: "#6CEFFF",
  },
};

export interface ListForDRClosureTracker {
  businessPartnerId: number;
  mappingId: number;
  emailStatus: string;
  vendorCode: string;
  category: string[];
  location: string;
  relationship: string;
  businessPartnerName: string;
  emailStatusChangeDate: Date;
  ledgerReceivedDate: Date;
  reconSentToPartnerDate: Date;
  emailStatusWithReminderCount: string;
  currency: string;
  ticketId: number;
  status: string;
  lastStatusChangeTime: Date;
  priority: string;
  remarks: string;
  ticketCreationDate: Date;
  reconPeriodFrom: Date;
  reconPeriodTo: Date;
  reconStartDate: Date;
  clientClosingBalance: number;
  partnerClosingBalance: number;
  closingBalanceDifference: number;
  openingBalanceGap: number;
  maker: string;
  checker: string;
  approver: string;
  makerId: number;
  checkerId: number;
  approverId: number;
  dueDate: Date;
  metaStatus: string;
  threadId: string | null;
  statusId: number;
}
