import { Autocomplete, Button, TextField } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import MonetaryInput from "src/Components/Common/MonetaryInput";
import {
  BusinessPartnerListForBcBeta,
  BusinessPartnersListForBcBetaHistory,
} from "src/entity/recon-entity/ReconInterfaces";
import { BalanceConfirmationBetaStatuses } from "src/Utils/PartnerCommunication";
import { uiLoggerNamesPartnerCommunication } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { TagsInputUniqueKeys } from "../../ReconMIS/AutoCompletes";
import "../Styles/LedgerRequestFilter.scss";

type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;

type setAppliedFilters = StateDispatch<{
  [key: string]: string;
}>;

interface BalanceConfirmationFilterProps {
  allCategories: string[];
  storeRowsDataBalanceConfirmation: BusinessPartnerListForBcBeta[];
  rowsDataBalanceConfirmation: BusinessPartnerListForBcBeta[];
  setRowsDataBalanceConfirmation: StateDispatch<BusinessPartnerListForBcBeta[]>;
  setAppliedFilters: setAppliedFilters;
  setIsFilterAppliedBalanceConfirmation: StateDispatch<boolean>;
  openBalanceConfirmationFilter: boolean;
  setOpenBalanceConfirmationFilter: StateDispatch<boolean>;
  reFilter: boolean;
  setReFilter: StateDispatch<boolean>;
  historyConsumer?: boolean;
  companyId?: string;
  branchCode?: string;
}

export function NumberFilter<T>(minVal: number, maxVal: number, rowData: T[], key: keyof T) {
  if (minVal !== null && maxVal !== null) {
    rowData = rowData?.filter((item) => {
      if (item[key]) {
        return Number(item[key]) <= Number(maxVal) && Number(item[key]) >= Number(minVal);
      }
      return false;
    });
  }
  if (minVal !== null && maxVal === null) {
    rowData = rowData?.filter((item) => {
      if (item[key]) {
        return Number(item[key]) >= Number(minVal);
      }
      return false;
    });
  }
  if (minVal === null && maxVal !== null) {
    rowData = rowData?.filter((item) => {
      if (item[key]) {
        return Number(item[key]) <= Number(maxVal);
      }
      return false;
    });
  }

  return rowData;
}

export function DateFilter<T>(minDate: Date | string, maxDate: Date | string, rowsData: T[], key: keyof T) {
  if (minDate && maxDate) {
    rowsData = rowsData?.filter((item) => {
      if (item[key]) {
        return moment(item[key])?.format("YYYY-MM-DD") <= maxDate && moment(item[key])?.format("YYYY-MM-DD") >= minDate;
      }
      return false;
    });
  }
  if (minDate && !maxDate) {
    rowsData = rowsData?.filter((item) => {
      if (item[key]) {
        return moment(item[key])?.format("YYYY-MM-DD") >= minDate;
      }
      return false;
    });
  }
  if (!minDate && maxDate) {
    rowsData = rowsData?.filter((item) => {
      if (item[key]) {
        return moment(item[key])?.format("YYYY-MM-DD") <= maxDate;
      }
      return false;
    });
  }

  return rowsData;
}

const BalanceConfirmationBetaFilter = (props: BalanceConfirmationFilterProps) => {
  const [selectedCategory, setSelectedCategory] = useState<string[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);

  const [fromDateOfMailInitiation, setFromDateOfMailInitiation] = useState<string>(null);
  const [toDateOfMailInitiation, setToDateOfMailInitiation] = useState<string>(null);
  const [fromDateOfClosingBalance, setFromDateOfClosingBalance] = useState<string>(null);
  const [toDateOfClosingBalance, setToDateOfClosingBalance] = useState<string>(null);
  const [fromDateAsOfLastBalanceConfirmed, setFromDateAsOfLastBalanceConfirmed] = useState<string>(null);
  const [toDateAsOfLastBalanceConfirmed, setToDateAsOfLastBalanceConfirmed] = useState<string>(null);
  const [partnerNames, setPartnerNames] = useState<string[]>([]);
  const [vendorCodes, setVendorCodes] = useState<string[]>([]);

  const BalanceConfirmationStatus = Array.from(new Set(Object.values(BalanceConfirmationBetaStatuses))); // only unique values

  type keys = "partnerNames" | "category" | "status" | "vendorCodes";

  const [filterObj, setFilterObj] = useState<{ [k in keys]: any }>(null);

  const handleClose = () => {
    props.setOpenBalanceConfirmationFilter(false);
  };

  const filterBalanceConfirmation = () => {
    let tempRowData = props.storeRowsDataBalanceConfirmation;

    if (partnerNames?.length) {
      tempRowData = tempRowData?.filter((partnerRow) => {
        let found = false;

        partnerNames.forEach((name) => {
          const partnerNameWithoutMultiSpace = name.replace(/\s+/g, " ");
          const partnerNameStr = partnerNameWithoutMultiSpace.toLowerCase();
          props.setIsFilterAppliedBalanceConfirmation(true);
          if (partnerRow.businessPartnerName?.replace(/\s+/g, " ").toLowerCase() === partnerNameStr?.trim()) {
            found = true;
          }
        });
        return found;
      });
    }

    if (vendorCodes?.length) {
      tempRowData = tempRowData?.filter((partnerRow) => {
        let found = false;

        vendorCodes.forEach((name) => {
          const vendorCodeWithoutMultiSpace = name.replace(/\s+/g, " ");
          const vendorCodeStr = vendorCodeWithoutMultiSpace.toLowerCase();
          props.setIsFilterAppliedBalanceConfirmation(true);
          if (partnerRow.vendorCode?.replace(/\s+/g, " ").toLowerCase() === vendorCodeStr?.trim()) {
            found = true;
          }
        });
        return found;
      });
    }

    if (selectedCategory.length > 0) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = tempRowData?.filter((item) => {
        return selectedCategory?.every((val) => item.category?.includes(val));
      });
    }
    if (selectedStatus.length > 0) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = tempRowData?.filter((item) => {
        return selectedStatus?.some((val) => item.status?.toLowerCase()?.includes(val?.toLowerCase()));
      });
    }

    if (props.historyConsumer && (fromDateOfMailInitiation || toDateOfMailInitiation)) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = DateFilter<BusinessPartnersListForBcBetaHistory>(
        fromDateOfMailInitiation,
        toDateOfMailInitiation,
        tempRowData,
        "emailInitiationDate"
      );
    }

    if (fromDateOfClosingBalance || toDateOfClosingBalance) {
      props.setIsFilterAppliedBalanceConfirmation(true);
      tempRowData = DateFilter<BusinessPartnerListForBcBeta>(
        fromDateOfClosingBalance,
        toDateOfClosingBalance,
        tempRowData,
        "closingBalanceDate"
      );
    }

    setFilterObj({
      partnerNames: partnerNames,
      category: selectedCategory,
      status: selectedStatus,
      vendorCodes: vendorCodes,
    });

    props.setAppliedFilters({
      "Partner Names": partnerNames?.map((name) => name?.trim()).join(", "),
      "Vendor Codes": vendorCodes?.map((code) => code?.trim()).join(", "),
      "Selected Category": selectedCategory.join(","),
      "Selected Status": selectedStatus.join(", "),
      "Date of Closing balance":
        fromDateOfClosingBalance && toDateOfClosingBalance
          ? [fromDateOfClosingBalance, toDateOfClosingBalance].join(" - ")
          : fromDateOfClosingBalance
          ? `${fromDateOfClosingBalance} Onwards`
          : toDateOfClosingBalance
          ? `Till ${toDateOfClosingBalance}`
          : "",
      "Date As of Last Balance Confirmed":
        fromDateAsOfLastBalanceConfirmed && toDateAsOfLastBalanceConfirmed
          ? [fromDateAsOfLastBalanceConfirmed, toDateAsOfLastBalanceConfirmed].join(" - ")
          : fromDateAsOfLastBalanceConfirmed
          ? `${fromDateAsOfLastBalanceConfirmed} Onwards`
          : toDateAsOfLastBalanceConfirmed
          ? `Till ${toDateAsOfLastBalanceConfirmed}`
          : "",
    });

    props.setRowsDataBalanceConfirmation(tempRowData);
    handleClose();
  };

  useEffect(() => {
    if (props.reFilter) {
      filterBalanceConfirmation();
      props?.setReFilter(false);
    }
    // eslint-disable-next-line
  }, [props.reFilter]);

  const clearAllAppliedFilters = () => {
    setSelectedCategory([]);
    setSelectedStatus([]);

    // All the Date states reset
    setFromDateOfClosingBalance(null);
    setToDateOfClosingBalance(null);
    setFromDateAsOfLastBalanceConfirmed(null);
    setToDateAsOfLastBalanceConfirmed(null);

    setPartnerNames([]);
    setVendorCodes([]);

    props.setAppliedFilters({});

    handleClose();
    setTimeout(() => {
      props.setOpenBalanceConfirmationFilter(true);
      props.setRowsDataBalanceConfirmation(props.storeRowsDataBalanceConfirmation);
      props.setIsFilterAppliedBalanceConfirmation(false);
    }, 700);
  };

  return (
    <div>
      <Dialog
        open={props.openBalanceConfirmationFilter}
        aria-labelledby="BalanceConfirmationFilter"
        className="ledgerRequestFilter"
      >
        <DialogTitle className="dialog_header">
          <p className="fs_24">Apply Filter</p>
          <button
            className="delete ml_20"
            aria-label="close"
            onClick={() => {
              setPartnerNames(filterObj?.partnerNames || []);
              setVendorCodes(filterObj?.vendorCodes || []);
              setSelectedCategory(filterObj?.category || []);
              setSelectedStatus(filterObj?.status || []);
              handleClose();
            }}
          />
        </DialogTitle>
        <DialogContent>
          <div>
            <div className="d_flex m_15">
              <div className="w_300 vertical_center_align fw_600">Partner Names :</div>
              <div className="w_400 d_flex category_textFeild_width_390 ">
                <TagsInputUniqueKeys
                  tags={partnerNames}
                  setTags={setPartnerNames}
                  className="partnerNamesAutocomplete"
                  fullWidth={true}
                  label=""
                  placeholder="Newline separated Names"
                  splitter="newline"
                  limitTags={3}
                  textFieldProps={{ multiline: true, maxRows: 4 }}
                  options={props.storeRowsDataBalanceConfirmation}
                  accessor={"businessPartnerName"}
                  uniqueKeyAccessor={"businessPartnerId"}
                />
              </div>
            </div>
            <div className="d_flex m_15">
              <div className="w_300 vertical_center_align fw_600">Vendor Codes :</div>
              <div className="w_400 d_flex category_textFeild_width_390 ">
                <TagsInputUniqueKeys
                  tags={vendorCodes}
                  setTags={setVendorCodes}
                  className="partnerNamesAutocomplete"
                  fullWidth={true}
                  label=""
                  placeholder="Newline separated Codes"
                  splitter="newline"
                  limitTags={3}
                  textFieldProps={{ multiline: true, maxRows: 4 }}
                  options={props.storeRowsDataBalanceConfirmation.filter(
                    (item) => !(item.vendorCode === null || item.vendorCode === "")
                  )}
                  accessor={"vendorCode"}
                  uniqueKeyAccessor={"businessPartnerId"}
                />
              </div>
            </div>
            <div className="d_flex m_15 ">
              <div className="w_300 vertical_center_align fw_600">Category :</div>
              <div className="w_400 category_textFeild_width_390">
                <Autocomplete
                  // limitTags={3}
                  value={selectedCategory}
                  fullWidth={true}
                  onChange={(_, value) => {
                    setSelectedCategory(value);
                  }}
                  size="small"
                  multiple={true}
                  id="tags-outlined"
                  options={props.allCategories || []}
                  getOptionLabel={(option) => option}
                  // defaultValue={[top100Films[13]]}
                  filterSelectedOptions={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select category"
                      // placeholder="Favorites"
                    />
                  )}
                />
              </div>
            </div>
            <div className="d_flex m_15 ">
              <div className="w_300 vertical_center_align fw_600">Status :</div>
              <div className="w_400 category_textFeild_width_390">
                <Autocomplete
                  // limitTags={3}
                  value={selectedStatus}
                  fullWidth={true}
                  onChange={(_, value) => {
                    setSelectedStatus(value);
                  }}
                  size="small"
                  multiple={true}
                  id="tags-outlined"
                  options={BalanceConfirmationStatus || []}
                  getOptionLabel={(option) => option}
                  // defaultValue={[top100Films[13]]}
                  filterSelectedOptions={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select status"
                      // placeholder="Favorites"
                    />
                  )}
                />
              </div>
            </div>

            {props.historyConsumer && (
              <div className="d_flex m_15">
                <div className="w_300 vertical_center_align fw_600">Date of Email Initiation :</div>
                <div className="w_400 d_flex textFeild_width_185">
                  <TextField
                    className="mr_20"
                    label="From"
                    InputLabelProps={{ shrink: true }}
                    id="outlined-size-small"
                    size="small"
                    type="date"
                    value={fromDateOfMailInitiation || ""}
                    onChange={(e) => {
                      setFromDateOfMailInitiation(e.target.value);
                    }}
                  />
                  <TextField
                    label="To"
                    InputLabelProps={{ shrink: true }}
                    id="outlined-size-small"
                    size="small"
                    type="date"
                    value={toDateOfMailInitiation || ""}
                    onChange={(e) => {
                      setToDateOfMailInitiation(e.target.value);
                    }}
                  />
                </div>
              </div>
            )}
            <div className="d_flex m_15">
              <div className="w_300 vertical_center_align fw_600">Date of closing balance :</div>
              <div className="w_400 d_flex textFeild_width_185">
                <TextField
                  className="mr_20"
                  label="From"
                  InputLabelProps={{ shrink: true }}
                  id="outlined-size-small"
                  size="small"
                  type="date"
                  value={fromDateOfClosingBalance || ""}
                  onChange={(e) => {
                    setFromDateOfClosingBalance(e.target.value);
                  }}
                />
                <TextField
                  label="To"
                  InputLabelProps={{ shrink: true }}
                  id="outlined-size-small"
                  size="small"
                  type="date"
                  value={toDateOfClosingBalance || ""}
                  onChange={(e) => {
                    setToDateOfClosingBalance(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="d_flex m_15"></div>
          </div>
        </DialogContent>
        <DialogActions className="dialog_footer space_between">
          <div>
            <Button
              className="theme_outline_btn"
              onClick={() => {
                clearAllAppliedFilters();
              }}
            >
              Clear All
            </Button>
          </div>
          <div>
            <Button
              className="theme_btn"
              id="filterBalanceConfirmation_button"
              onClick={() => {
                if (!props.historyConsumer) {
                  uiLogger(
                    uiLoggerNamesPartnerCommunication.UI_PC_BCB_FILTER_APPLY_CLICK.functionName,
                    props.companyId,
                    props.branchCode,
                    {
                      message: uiLoggerNamesPartnerCommunication.UI_PC_BCB_FILTER_APPLY_CLICK.message,
                    }
                  );
                }
                filterBalanceConfirmation();
              }}
            >
              Apply
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const NumberFieldFilter = ({
  label,
  minNumber,
  setMinNumber,
  maxNumber,
  setMaxNumber,
}: {
  label: string;
  minNumber: number;
  setMinNumber: StateDispatch<number>;
  maxNumber: number;
  setMaxNumber: StateDispatch<number>;
}) => {
  return (
    <>
      <div className="d_flex m_15">
        <div className="w_300 vertical_center_align fw_600">{label}</div>
        <div className="w_400 d_flex textFeild_width_185">
          <MonetaryInput
            className="mr_20"
            label="Min"
            size="small"
            value={minNumber}
            setValue={(value) => {
              setMinNumber(value);
            }}
            returnNull={true}
          />
          <MonetaryInput
            label="Max"
            size="small"
            value={maxNumber}
            setValue={(value) => {
              setMaxNumber(value);
            }}
            returnNull={true}
          />
        </div>
      </div>
    </>
  );
};

export default BalanceConfirmationBetaFilter;
