import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { TicketMetaStatuses } from "../../Tickets/ViewTickets";

export default function DiscardTaskModal({ open, setOpen, changeStatus, currentTaskDetails }) {
  const [isDiscardingTask, setIsDiscardingTask] = useState(false);
  return (
    <Dialog open={open}>
      <DialogTitle>Are you sure you want to discard the current recon</DialogTitle>
      <DialogContent>
        <Alert severity="warning">
          Discarded reconciliation:
          <p> 1. Will not be visible in Recon Task Dashboard.</p>
          <p> 2. Will not be used for future Reconciliation Gap Justifications</p>
        </Alert>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button
          variant="text"
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={isDiscardingTask}
          variant="contained"
          color="warning"
          onClick={async () => {
            setIsDiscardingTask(true);
            await changeStatus(TicketMetaStatuses.Discarded, currentTaskDetails);
            setIsDiscardingTask(false);
          }}
          sx={{ borderRadius: "4px !important" }}
        >
          {isDiscardingTask && <LoadingIcon loading={isDiscardingTask} />}
          Discard
        </Button>
      </DialogActions>
    </Dialog>
  );
}
