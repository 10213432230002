/* eslint-disable @typescript-eslint/no-unused-vars */
import { AccessTimeSharp, CallMade, DriveFileMoveSharp, HistoryOutlined, MailOutline } from "@mui/icons-material";
import { ListItemIcon, MenuItem, Stack } from "@mui/material";
import { MRT_Row } from "material-react-table";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import useFetch from "src/Components/Common/useFetch";
import ReactTable, { ColDef } from "src/Components/ReactTable/ReactTable";
import TasksHistoryModal from "src/Components/Recon360/Tickets/TasksHistoryModal";
import { BusinessPartnerListForBcBeta } from "src/entity/recon-entity/ReconInterfaces";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { BalanceConfirmationBetaStatuses } from "src/Utils/PartnerCommunication";
import MoveTaskToReconModal from "../../../BalanceConfirmationBeta/MoveTaskToReconModal";
import ReviewMoveBulkTasksModal from "../../../BalanceConfirmationBeta/ReviewMoveBulkTasksModal";
import { ApproveResponseDialog, UpdateResponseDialog } from "../../../Common/CommonDialogs";
import { DEFAULT_BRANCH_CODE, DEFAULT_COMPANY_ID } from "../../../CommonLegacy/CommonComponents";
import { PartnerCommsContext } from "../../../Context/PartnerCommunicationContext";
import ColumnDefinitions from "../../Common/ColumnDefinitions";
import {
  ColorToggleButton,
  CustomBulkActionAlert,
  GetQuickFilterOptions,
  NoCommunication,
  QuickFilterRows,
  ReceivedQF,
} from "../../Common/Components";
import BcNewContext from "../BcNewContext";

const ResponseReceivedTab = () => {
  const { setEmailBusinessPartnerId, setMappingId, setBusinessPartnerName } = useContext(PartnerCommsContext);

  const {
    setTabChipLabel,
    rowsDataBcBeta,
    companyId,
    branchCode,
    loader,
    currentRow,
    setCurrentRow,
    setMailingRows,
    setOpenBcBetaMailThread,
    listAllBusinessPartners,
  } = useContext(BcNewContext);

  const [columnDefinitions, setColumnDefinitions] = useState<ColDef<BusinessPartnerListForBcBeta>[]>([]);
  const [selectedPdRow, setSelectedPdRow] = useState<BusinessPartnerListForBcBeta[]>([]);
  const [selectedAvRow, setSelectedAvRow] = useState<BusinessPartnerListForBcBeta[]>([]);
  const [selectedPdQF, setSelectedPdQF] = useState<ReceivedQF>("all");
  const [selectedAvQF, setSelectedAvQF] = useState<ReceivedQF>("all");
  const pendingApprovalRows: BusinessPartnerListForBcBeta[] = rowsDataBcBeta.filter(
    (row) => row.approvedStatus === "Pending"
  );
  const tasksApprovedRows: BusinessPartnerListForBcBeta[] = rowsDataBcBeta.filter(
    (row) => row.approvedStatus === "Approved"
  );
  const pdQuickFilterOptions = GetQuickFilterOptions(pendingApprovalRows);
  const avQuickFilterOptions = GetQuickFilterOptions(tasksApprovedRows);

  const finalQuickFilteredPdRows = QuickFilterRows(pendingApprovalRows, selectedPdQF);
  const finalQuickFilteredAvRows = QuickFilterRows(tasksApprovedRows, selectedAvQF);

  const NO_COMMUNICATION = !loader && pendingApprovalRows.length === 0 && tasksApprovedRows.length === 0;

  // States for Table
  const [clearRows, setClearRows] = useState(false);
  const [openUpdateResDialog, setOpenUpdateResDialog] = useState(false);
  const [openApproveDialog, setOpenApproveDialog] = useState(false);

  const ASYNC_INTERVAL_OPs_IN_PROGRESS = false;

  const handleTabChipLabel = useCallback(() => {
    setTabChipLabel((prev) => ({ ...prev, responseReceived: pendingApprovalRows.length + tasksApprovedRows.length }));
  }, [pendingApprovalRows, tasksApprovedRows]);

  useEffect(() => {
    handleTabChipLabel();
  }, [rowsDataBcBeta]);

  const RowActionMenuItems = ({ row, closeMenu }: { row: MRT_Row<BusinessPartnerListForBcBeta>; closeMenu: any }) => {
    return {
      // {/* Send/View Email Button */}
      sendViewMail: (
        <MenuItem
          disabled={ASYNC_INTERVAL_OPs_IN_PROGRESS}
          key={"sendViewMail"}
          onClick={async () => {
            setEmailBusinessPartnerId(row.original.businessPartnerId);
            setMappingId(row.original.mappingId);
            setBusinessPartnerName(row.original.businessPartnerName);
            setCurrentRow(row.original);
            setOpenBcBetaMailThread(true);

            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <MailOutline sx={{ fill: "#541c4c" }} />
          </ListItemIcon>
          Send/View Email
        </MenuItem>
      ),
      // {/* Update Response */}
      updatedResponse: (
        <MenuItem
          disabled={
            ASYNC_INTERVAL_OPs_IN_PROGRESS ||
            row.original.status === BalanceConfirmationBetaStatuses.RequestBalanceConfirmation
          }
          key={"updatedResponse"}
          onClick={async () => {
            // logic
            setCurrentRow(row.original);
            setOpenUpdateResDialog(true);
            // close Menu
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <CallMade sx={{ fill: "#541c4c" }} />
          </ListItemIcon>
          Update Response
        </MenuItem>
      ),
      // {/* View History */}
      viewHistory: (
        <MenuItem
          disabled={
            ASYNC_INTERVAL_OPs_IN_PROGRESS ||
            row.original.ticketId === null ||
            row.original.status === BalanceConfirmationBetaStatuses.RequestBalanceConfirmation
          }
          key={"viewHistory"}
          onClick={() => {
            currentSelectedTaskDetails.current = {
              businessPartnerName: row.original.businessPartnerName,
              taskId: row.original.ticketId,
            };
            setShowTasksHistoryModal(true);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <HistoryOutlined sx={{ fill: "#541c4c" }} />
          </ListItemIcon>
          View History
        </MenuItem>
      ),
    };
  };

  const approveResponse = async (rows: BusinessPartnerListForBcBeta[]) => {
    const businessPartnerDetails = rows.map((row) => ({
      businessPartnerId: row.businessPartnerId,
      mappingId: row.mappingId,
    }));

    useFetch<{ message: string }>(API_ENDPOINTS.APPROVE_STATUS.url, "POST", {
      failureMessage: API_ENDPOINTS.APPROVE_STATUS.failureMessage,
      showSuccessToast: true,
      data: {
        companyId: companyId || DEFAULT_COMPANY_ID,
        branchCode: branchCode || DEFAULT_BRANCH_CODE,
        businessPartnerDetails: businessPartnerDetails,
      },
      thenCallBack: (_res) => {
        setClearRows(true);
        listAllBusinessPartners();
      },
    });
  };

  // START space for bulk tasks

  type bpWithTasksId = {
    ticketId: number;
    businessPartnerId: number;
    mappingId: number;
  };

  const [showReviewMoveBulkTasksModal, setShowReviewMoveBulkTasksModal] = useState<boolean>(false);
  const [showMoveTaskToReconModal, setShowMoveTaskToReconModal] = useState<boolean>(false);
  const [moveTaskToReconModalTitle, setMoveTaskToReconModalTitle] = useState<string>("");
  const [tasksToMoveCount, setTasksToMoveCount] = useState<number>(0);

  const [showTasksHistoryModal, setShowTasksHistoryModal] = useState<boolean>(false);

  const [moveToReconTaskDetails, setMoveToReconTaskDetails] = useState<bpWithTasksId[]>([]);
  const currentSelectedTaskDetails = useRef({ taskId: null, businessPartnerName: null });
  const bpIdForCheckingAlreadyExistingTask = useRef<number>(null);
  const mappingIdForCheckingAlreadyExistingTask = useRef<number>(null);
  const [isMovingBulkTasks, setIsMovingBulkTasks] = useState<boolean>(false);
  const [businessPartnerDetailsForWhichTaskAlreadyExists, setBusinessPartnerDetailsForWhichTaskAlreadyExists] =
    useState<bpWithTasksId[]>([]);
  const [isLoadingAlreadyExistingTasks, setIsLoadingAlreadyExistingTasks] = useState(false);
  const taskAlreadyExistForSingleMoveToRecon = useRef<boolean>(false);

  const getAlreadyExistingTasks = async (forBulk: boolean) => {
    setIsLoadingAlreadyExistingTasks(true);

    let businessPartnerData = [];
    if (forBulk === false)
      businessPartnerData = [
        {
          businessPartnerId: bpIdForCheckingAlreadyExistingTask.current,
          mappingId: mappingIdForCheckingAlreadyExistingTask.current,
        },
      ];
    else {
      businessPartnerData = selectedAvRow.map((task) => {
        return {
          businessPartnerId: task.businessPartnerId,
          mappingId: task.mappingId,
        };
      });
    }

    await useFetch(API_ENDPOINTS.GET_EXISTING_TICKETS.url, "POST", {
      failureMessage: API_ENDPOINTS.GET_EXISTING_TICKETS.failureMessage,
      data: {
        businessPartnerData: businessPartnerData,
        companyId: companyId || DEFAULT_COMPANY_ID,
        branchCode: branchCode || DEFAULT_BRANCH_CODE,
      },
      thenCallBack: (response) => {
        setBusinessPartnerDetailsForWhichTaskAlreadyExists(response.data.businessPartnerWiseTickets);
        if (forBulk === false && response.data.count === 1) taskAlreadyExistForSingleMoveToRecon.current = true;

        setIsLoadingAlreadyExistingTasks(false);
      },
      catchCallBack: () => {
        setIsLoadingAlreadyExistingTasks(false);
      },
    });
  };

  // END space for bulk tasks

  useEffect(() => {
    setMailingRows(selectedPdRow);
  }, [selectedPdRow]);

  useEffect(() => {
    setMailingRows(selectedAvRow);
  }, [selectedAvRow]);

  // Use useCallback to memoize the callback function
  const handleColDefsData = useCallback((data: React.SetStateAction<ColDef<BusinessPartnerListForBcBeta>[]>) => {
    setColumnDefinitions(data); // Update state with data from child
  }, []);

  return NO_COMMUNICATION ? (
    <NoCommunication />
  ) : (
    <>
      <ColumnDefinitions
        props={{
          recMailsDeps: [],
          setRecMailsDefs: handleColDefsData,
          type: "recMails",
        }}
        otherProps={{
          isSyncingMailStatus: false,
        }}
      />
      <Stack p={2} gap={3}>
        <Stack className="pending_approval_section" sx={{ gap: 1 }}>
          <CustomBulkActionAlert
            alertTitle="Responses pending for approval"
            alertText="Accept and reject responses if you think these are correct or incorrect"
            severity="warning"
            icon={<AccessTimeSharp fontSize="medium" sx={{ color: "#fff" }} />}
            actionName="Approve"
            actionFn={
              selectedPdRow.length > 0
                ? () => {
                    /* Bulk Approve Response logic */
                    // setCurrentSelectedRows(selectedRow);
                    setOpenApproveDialog(true);
                  }
                : undefined
            }
          />

          <ColorToggleButton allQFValues={pdQuickFilterOptions} setSelectedQF={setSelectedPdQF} />

          <ReactTable
            // key={JSON.stringify(selectedRow)}
            columns={columnDefinitions}
            rows={finalQuickFilteredPdRows}
            setSelectedRow={setSelectedPdRow}
            loading={loader}
            enableGrouping={true}
            clearRowsSelection={clearRows}
            setClearRowsSelection={setClearRows}
            positionToolbarAlertBanner={"top"}
            renderRowActionMenuItems={({ row, closeMenu }) => {
              const { sendViewMail, updatedResponse, viewHistory } = RowActionMenuItems({ row, closeMenu });
              return [sendViewMail, updatedResponse, viewHistory];
            }}
            initialStateColumnVisibility={{
              "Approved Date": false,
              "Response Status": false,
              "Response Approved": false,
            }}
          />
        </Stack>
        <Stack className="tasks_approved_section" sx={{ gap: 1 }}>
          <CustomBulkActionAlert
            alertTitle="Tasks ready to move to recon"
            alertText="Responses for which can now move to recon"
            severity="success"
            icon={<DriveFileMoveSharp fontSize="medium" sx={{ color: "#fff" }} />}
            actionName="Move Task"
            actionFn={
              selectedAvRow.length > 0
                ? () => {
                    /* Bulk Move Task to Recon logic */
                    setShowReviewMoveBulkTasksModal(true);
                    setMoveTaskToReconModalTitle("Move Bulk Tasks");
                    setIsMovingBulkTasks(true);
                  }
                : undefined
            }
          />

          <ColorToggleButton allQFValues={avQuickFilterOptions} setSelectedQF={setSelectedAvQF} />

          <ReactTable
            // key={JSON.stringify(selectedRow)}
            columns={columnDefinitions}
            rows={finalQuickFilteredAvRows}
            setSelectedRow={setSelectedAvRow}
            loading={loader}
            enableGrouping={true}
            positionToolbarAlertBanner={"top"}
            renderRowActionMenuItems={({ row, closeMenu }) => {
              const { sendViewMail, viewHistory } = RowActionMenuItems({ row, closeMenu });
              return [sendViewMail, viewHistory];
            }}
          />
        </Stack>

        <UpdateResponseDialog
          open={openUpdateResDialog}
          setOpen={setOpenUpdateResDialog}
          link={currentRow?.portalLink}
        />

        <ApproveResponseDialog
          open={openApproveDialog}
          setOpen={setOpenApproveDialog}
          partnerCount={selectedPdRow.length}
          approveAction={async () => {
            await approveResponse(selectedPdRow);
          }}
        />

        {showTasksHistoryModal && (
          <TasksHistoryModal
            showTasksHistoryModal={showTasksHistoryModal}
            setShowTasksHistoryModal={setShowTasksHistoryModal}
            taskId={currentSelectedTaskDetails.current.taskId}
            businessPartnerName={currentSelectedTaskDetails.current.businessPartnerName}
          />
        )}

        {showReviewMoveBulkTasksModal && (
          <ReviewMoveBulkTasksModal
            open={showReviewMoveBulkTasksModal}
            setOpen={setShowReviewMoveBulkTasksModal}
            selectedTasks={selectedAvRow}
            setShowMoveTaskToReconModal={setShowMoveTaskToReconModal}
            setMoveToReconTaskDetails={setMoveToReconTaskDetails}
            setTasksToMoveCount={setTasksToMoveCount}
            businessPartnerDetailsForWhichTaskAlreadyExists={businessPartnerDetailsForWhichTaskAlreadyExists}
            getAlreadyExistingTasks={getAlreadyExistingTasks}
            isLoadingAlreadyExistingTasks={isLoadingAlreadyExistingTasks}
            companyId={companyId}
            branchCode={branchCode}
          />
        )}

        {showMoveTaskToReconModal && (
          <MoveTaskToReconModal
            open={showMoveTaskToReconModal}
            setOpen={setShowMoveTaskToReconModal}
            title={moveTaskToReconModalTitle}
            tasksToMoveCount={tasksToMoveCount}
            isMovingBulkTasks={isMovingBulkTasks}
            moveToReconTaskDetails={moveToReconTaskDetails}
            companyId={companyId}
            branchCode={branchCode}
            ListAllBusinessPartnerForBcBeta={listAllBusinessPartners}
            setClearRows={setClearRows}
          />
        )}
      </Stack>
    </>
  );
};

export default ResponseReceivedTab;
