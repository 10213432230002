import Axios from "axios";
import React, { useEffect, useState } from "react";
import "react-bulma-components/dist/react-bulma-components.min.css";
import { clarity } from "react-microsoft-clarity";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import packageJson from "../package.json";
import PartnerCommunicationAnalytics from "./Components/Admin/Analytics/PartnerCommunicationAnalytics";
import ReconAnalytics from "./Components/Admin/Analytics/ReconAnalytics";
import TaskManagementAnalytics from "./Components/Admin/Analytics/TaskManagementAnalytics";
import CBUpdate from "./Components/Admin/CBUpdate";
import ConfigTemplates from "./Components/Admin/ConfigTemplates";
import LedgerData from "./Components/Admin/LedgerData";
import PaymentDetails from "./Components/Admin/PaymentDetails";
import Register from "./Components/Admin/Register";
import SapData from "./Components/Admin/SapData";
import TallyLedger from "./Components/Admin/TallyLedger";
import TallyVoucher from "./Components/Admin/TallyVoucher";
import UnpaidInvoice from "./Components/Admin/UnpaidInvoice";
import Userlist from "./Components/Admin/Userlist";
import "./Components/Common/Common.scss";
import SideBar from "./Components/Common/SideBar";
import { userContext } from "./Components/Contexts/userContext";
import CustomToast from "./Components/CustomToast";
import Login from "./Components/Login/Login";
import ClosureTracker from "./Components/Recon360/DisputeResolution/ClosureTracker/ClosureTracker";
import Faq from "./Components/Recon360/Faq/Faq";
import Ledger from "./Components/Recon360/LedgerAnalytics/Ledger";
import LedgerPreprocessingPortal from "./Components/Recon360/LedgerPreprocessingPortal/LedgerPreprocessingPortal";
import AuditCommunication from "./Components/Recon360/PartnerCommunication/AuditCommunication/AuditCommunication";
import BalanceConfirmation from "./Components/Recon360/PartnerCommunication/BalanceConfirmation/BalanceConfirmation";
import BalanceConfirmationBeta from "./Components/Recon360/PartnerCommunication/BalanceConfirmationBeta/BalanceConfirmationBeta";
import CommonReport from "./Components/Recon360/PartnerCommunication/Common/CommonReport";
import ConfirmBalanceFromMail from "./Components/Recon360/PartnerCommunication/ConfirmBalanceFromMail/ConfirmBalanceFromMail";
import PartnerCommunicationContext from "./Components/Recon360/PartnerCommunication/Context/PartnerCommunicationContext";
import LedgerRequest from "./Components/Recon360/PartnerCommunication/LedgerRequest/LedgerRequest";
import LedgerRequestBeta from "./Components/Recon360/PartnerCommunication/LedgerRequestBeta/LedgerRequestBeta";
import MsmeConfirmation from "./Components/Recon360/PartnerCommunication/MsmeConfirmation/MsmeConfirmation";
import PartnerPortal from "./Components/Recon360/PartnerCommunication/MsmePartnerPortal";
import PartnerPortalWrapper from "./Components/Recon360/PartnerCommunication/PartnerPortal/PartnerPortalWrapper";
import PartnerMaster from "./Components/Recon360/PartnerMaster/PartnerMaster";
import AnnotationPage from "./Components/Recon360/PDFAnnotate";
import Recon360 from "./Components/Recon360/Recon360";
import MailMISReports from "./Components/Recon360/ReconMIS/MailMISReports/MailMISReports";
import PartnerRiskManagement from "./Components/Recon360/ReconMIS/PartnerRiskManagement/PartnerRiskManagement";
import ReconSettings from "./Components/Recon360/ReconSettings/ReconSettings";
import SupplierChainDashboard from "./Components/Recon360/SupplierChainDashboard/SupplierChainDashboard";
import TaskTracker from "./Components/Recon360/TaskTracker/TaskTracker";
import ChangePassword from "./Components/ResetPassword/ChangePassword";
import ResetPassword from "./Components/ResetPassword/ResetPassword";
import { FrontendActor, UserTypes } from "./entity/models/FrontendActor";
import { UseCaseType } from "./entity/recon-entity/ReconInterfaces";
import { ENV_NAME } from "./Utils/Recon/ENV/Constants";
import { RECON360_SUB_TAB } from "./Utils/Recon/Recon360/Constants";

const NON_APP_PATHS = [
  { path: "/", exact: true },
  { path: "/reset", exact: false },
  { path: "confirmBalanceFromMail", exact: false },
  { path: "/annotate", exact: true },
  { path: "/partnerPortal", exact: false },
  { path: "/balanceConfirmationPortal", exact: false },
  { path: "/balanceConfirmReport", exact: false },
  { path: "/ledgerRequestPortal", exact: false },
  { path: "/ledgerRequestReport", exact: false },
  { path: "/preprocessing", exact: false },
];

export const isShownSideBar = (path: string) => {
  let isNonAppPath = false;
  NON_APP_PATHS.forEach((p) => {
    if (p.exact) {
      if (path === p.path) isNonAppPath = true;
    } else if (!p.exact) {
      if (path.includes(p.path)) isNonAppPath = true;
    }
  });
  // if non App path don't show side bar
  return isNonAppPath ? false : true;
};

export const handleUnauthenticatedUser = (error) => {
  // Check if the error is a 401 status
  if (error.response?.status === 401) {
    toast.error(<CustomToast message={"Session expired. Please log in again"} />);
    // Delay the execution of clearing storage and redirection
    setTimeout(() => {
      localStorage.clear(); // Clear all local storage
      window.location.href = "/"; // Redirect to the home page
    }, 1000); // 1000ms = 1 seconds
  }
};

const App = () => {
  const [actor, setActor] = useState<FrontendActor>(() => {
    const localData = localStorage.getItem("actor");
    try {
      return localData ? JSON.parse(localData) : null;
    } catch (e) {
      return null;
    }
  });

  if (
    actor &&
    actor?.userType !== UserTypes.Admin &&
    actor?.userType !== UserTypes.Sales &&
    actor?.zsMonitorAccount &&
    import.meta.env.VITE_APP_NAKAD_ENV === ENV_NAME.production
  ) {
    console.log("Tracking enabled for user");
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.innerHTML = `
      zapscale.init("660a5c46e4b3e5c5362401d9", {
        product_name: "ARP",
        organization_id:  "${actor?.clientId}",
        organization_name: "${actor?.actorName}",
        unique_organization_id: "${actor?.crmId ? actor?.crmId : ""}",
        user_id: "${actor?.userId}",
        user_name: "${actor?.userName}",
        user_email:  "${actor?.userEmail}",
        role_id: "${actor?.userType === UserTypes.CustomerSuccess ? "internal user" : "external user"}", 
        role_name:  "${actor?.userType === UserTypes.CustomerSuccess ? "internal user" : "external user"}", 
      }, {
        development: false
      });
    `;

    document.body.appendChild(script);
  }
  // Usetiful tags
  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = `
        window.usetifulTags = {
          userId: "${actor?.userId}",
          name: "${actor?.userName}",
          ActorId: "${actor?.id}",
          ActorName: "${actor?.actorName}",
          ActorType:  "${actor?.actorType}",
          ClientType:  "${actor?.clientType}",
          ClientID:  "${actor?.clientId}",
          AccountType:  "${actor?.accountType}",
          UserType:  "${actor?.userType}",
          UserRole:  "${actor?.userRole}",
          UserAgeInDays:  "${actor?.userAgeInDays}",
          ActorAgeInDays:  "${actor?.actorAgeInDays}",
        };
    `;
    document.head.appendChild(script);
  }, []);

  useEffect(() => {
    // Function to fetch the backend version
    const fetchBackendVersion = () => {
      const localData = localStorage.getItem("actor");
      try {
        Axios.get("/api/version", {
          params: {
            userId: JSON.parse(localData)?.userId ? JSON.parse(localData)?.userId : null,
          },
        })
          .then((response) => {
            const backendVersion = response.data.version;
            // Access the version property from package.json
            const frontendVersion = packageJson.version;
            // Compare frontend and backend versions
            if (backendVersion && frontendVersion !== backendVersion) {
              // Perform a hard refresh
              // Reload the page only in production mode to ensure users have the latest version

              if (import.meta.env.PROD) {
                let refreshCount = parseInt(localStorage.getItem("refreshCount")) || 0;

                if (refreshCount < 3) {
                  refreshCount += 1;
                  localStorage.setItem("refreshCount", refreshCount.toString());
                  window.location.reload(); // Hard refresh
                } else {
                  localStorage.removeItem("refreshCount");
                  toast.error(
                    <CustomToast message="The application is updating to the latest version. Please wait a moment and refresh the page to access the latest features." />
                  );
                }
              }
            }
          })
          .catch((error) => {
            handleUnauthenticatedUser(error);
          });
      } catch (error) {
        console.log(error);
      }
    };

    // Polling interval (every 1 minutes in this example)
    const pollingInterval = 1 * 60 * 1000; // 1 minutes in milliseconds

    // Initial fetch
    fetchBackendVersion();

    // Set up interval for periodic polling
    const intervalId = setInterval(fetchBackendVersion, pollingInterval);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  const AdminRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) => {
          if (actor && actor.actorType === "Admin") {
            return <Component {...props} />;
          } else {
            return <Redirect to="/" />;
          }
        }}
      />
    );
  };

  useEffect(() => {
    const clarityProjectIDs = {
      production: "inqdavk56u",
      staging: "inqcy754wj",
      test: "inq7p42w47",
    };
    clarity.init(clarityProjectIDs[import.meta.env.VITE_APP_NAKAD_ENV]);
  }, []);

  return (
    <BrowserRouter>
      <userContext.Provider value={{ actor, setActor }}>
        <SideBar />
        <Switch>
          <Route exact={true} path="/">
            <Login />
          </Route>
          <Route exact={true} path="/reset/:token/:accountType">
            <ChangePassword />
          </Route>
          <Route exact={true} path="/annotate">
            <AnnotationPage />
          </Route>
          <Route exact={true} path="/reset">
            <ResetPassword />
          </Route>
          <Route exact={true} path="/confirmBalanceFromMail">
            <ConfirmBalanceFromMail />
          </Route>
          <Route exact={true} path="/partnerPortal">
            <PartnerPortal />
          </Route>
          <Route exact={false} path="/balanceConfirmationPortal">
            <PartnerPortalWrapper />
          </Route>
          <Route exact={false} path="/balanceConfirmReport">
            <CommonReport useCaseType={UseCaseType.balanceConfirmationBeta} />
          </Route>
          <Route exact={true} path="/ledgerRequestPortal">
            <PartnerPortalWrapper />
          </Route>
          <Route exact={false} path="/ledgerRequestReport">
            <CommonReport useCaseType={UseCaseType.ledgerRequestBeta} />
          </Route>
          <Route exact={true} path="/preprocessing/:scriptName">
            <LedgerPreprocessingPortal />
          </Route>
          <Route exact={true} path="/faq">
            <Faq />
          </Route>
          {/* Recon360 paths */}
          {import.meta.env.VITE_APP_NAKAD_ENV === "test" ? (
            <Route exact={true} path="/:actorName/recon360/:pathParam?/PaymentAdvice">
              <Recon360 subTab={RECON360_SUB_TAB.PAYMENT_ADVICE} />
            </Route>
          ) : null}
          <Route exact={true} path="/:actorName/recon360/:pathParam?/Ledger">
            <Recon360 subTab={RECON360_SUB_TAB.LEDGER} />
          </Route>
          {actor?.integration && (
            <Route exact={true} path="/:actorName/LedgerAnalytics/Ledger">
              <Ledger />
            </Route>
          )}
          {import.meta.env.VITE_APP_NAKAD_ENV !== "production" ? (
            <Route exact={true} path="/:actorName/supplierchaindashboard">
              <SupplierChainDashboard />
            </Route>
          ) : null}
          <Route exact={true} path="/:actorName/reconSettings">
            <ReconSettings />
          </Route>
          <Route exact={true} path="/:actorName/taskBoard">
            <TaskTracker />
          </Route>
          <Route exact={true} path="/:actorName/partnerCommunication/ledgerRequest">
            <PartnerCommunicationContext>
              <LedgerRequest />
            </PartnerCommunicationContext>
          </Route>
          <Route exact={true} path="/:actorName/partnerCommunication/balanceConfirmation">
            <PartnerCommunicationContext>
              <BalanceConfirmation />
            </PartnerCommunicationContext>
          </Route>
          <Route exact={true} path="/:actorName/partnerCommunication/msmeConfirmation">
            <PartnerCommunicationContext>
              <MsmeConfirmation />
            </PartnerCommunicationContext>
          </Route>
          <Route exact={true} path="/:actorName/partnerCommunication/balanceConfirmBeta">
            <PartnerCommunicationContext>
              <BalanceConfirmationBeta />
            </PartnerCommunicationContext>
          </Route>
          <Route exact={true} path="/:actorName/partnerCommunication/balanceConfirmV2">
            <PartnerCommunicationContext>
              <AuditCommunication />
            </PartnerCommunicationContext>
          </Route>
          <Route exact={true} path="/:actorName/partnerCommunication/ledgerReqBeta">
            <PartnerCommunicationContext>
              <LedgerRequestBeta />
            </PartnerCommunicationContext>
          </Route>
          <Route exact={true} path="/:actorName/partnerMaster">
            <PartnerMaster />
          </Route>
          <Route exact={true} path="/:actorName/disputeResolution/closureTracker">
            <PartnerCommunicationContext>
              <ClosureTracker />
            </PartnerCommunicationContext>
          </Route>
          <Route exact={true} path="/:actorName/ReconMIS/partnerRiskManagement">
            <PartnerRiskManagement />
          </Route>
          <Route exact={true} path="/:actorName/ReconMIS/reportsMailAutomation">
            <MailMISReports />
          </Route>
          {/* AdminRoute for admin paths */}
          <AdminRoute exact={true} path="/admin/userlist" component={Userlist} />
          <AdminRoute exact={true} path="/admin/sapdata" component={SapData} />
          <AdminRoute exact={true} path="/admin/ledgerdata" component={LedgerData} />
          <AdminRoute exact={true} path="/admin/configtemplates" component={ConfigTemplates} />
          <AdminRoute exact={true} path="/admin/tallyvoucher" component={TallyVoucher} />
          <AdminRoute exact={true} path="/admin/tallyledger" component={TallyLedger} />
          <AdminRoute exact={true} path="/admin/CBUpdate" component={CBUpdate} />
          <AdminRoute exact={true} path="/admin/UnpaidInvoice" component={UnpaidInvoice} />
          <AdminRoute exact={true} path="/admin/PaymentDetails" component={PaymentDetails} />
          <AdminRoute exact={true} path="/admin/Register" component={Register} />
          <AdminRoute exact={true} path="/admin/reconAnalytics" component={ReconAnalytics} />
          <AdminRoute
            exact={true}
            path="/admin/partnerCommunicationAnalytics"
            component={PartnerCommunicationAnalytics}
          />
          <AdminRoute exact={true} path="/admin/taskManagement" component={TaskManagementAnalytics} />
          <Redirect to="/" />
        </Switch>
      </userContext.Provider>
      <ToastContainer autoClose={5000} icon={false} closeButton={false} draggable limit={6} stacked />
    </BrowserRouter>
  );
};

export default App;
