import {
  Autocomplete,
  AutocompleteProps,
  createFilterOptions,
  FormControlLabel,
  TextField,
  Tooltip,
} from "@mui/material";
import React from "react";
import { Android12Switch } from "src/Components/Common/Loader";
import { UseCaseType } from "src/entity/recon-entity/ReconInterfaces";
import { uiLoggerNamesPartnerCommunication } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";

export type StateDispatch<T> = React.Dispatch<React.SetStateAction<T>>;

interface PageSelectModeToggleProps {
  pageSelectMode: boolean;
  setPageSelectMode: (value: boolean) => void;
  useCaseType: UseCaseType;
  companyId?: string;
  branchCode?: string;
}

export const PageSelectModeToggle = ({
  pageSelectMode,
  setPageSelectMode,
  useCaseType,
  companyId,
  branchCode,
}: PageSelectModeToggleProps) => {
  return (
    <>
      <FormControlLabel
        control={
          <Tooltip
            title="When this toggle is enabled, use 'select all checkbox' to select/deselect all partners in the current page."
            arrow
          >
            <Android12Switch
              onChange={() => setPageSelectMode(!pageSelectMode)}
              onClick={(ev) => {
                if (useCaseType === UseCaseType.balanceConfirmationBeta) {
                  uiLogger(
                    uiLoggerNamesPartnerCommunication.UI_PC_BCB_PAGE_SELECT_MODE_CLICK.functionName,
                    companyId,
                    branchCode,
                    {
                      message: uiLoggerNamesPartnerCommunication.UI_PC_BCB_PAGE_SELECT_MODE_CLICK.message,
                    }
                  );
                }
                (ev.target as HTMLInputElement).checked ? setPageSelectMode(false) : setPageSelectMode(true);
              }}
              checked={pageSelectMode}
            />
          </Tooltip>
        }
        label="Page Select Mode"
      />
    </>
  );
};

const filter = createFilterOptions<any>();

type LabelType = { title: string; inputValue?: string };

interface TaskLabelAcProps {
  taskLabel: LabelType;
  setTaskLabel: StateDispatch<LabelType>;
  allTaskLabels: LabelType[];
  AcProps?: Partial<AutocompleteProps<LabelType, false, false, true>>;
}

export const TaskLabelAutocomplete = ({ taskLabel, setTaskLabel, allTaskLabels, AcProps }: TaskLabelAcProps) => {
  return (
    <>
      <Autocomplete<LabelType, false, false, true>
        sx={{ "& fieldset": { borderRadius: "4px" }, width: "100%" }}
        value={taskLabel}
        onChange={async (_, newValue) => {
          if (typeof newValue === "string") {
            setTaskLabel({
              title: newValue,
            });
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setTaskLabel({
              title: newValue.inputValue,
            });
          } else {
            setTaskLabel(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          if (inputValue.trim().length === 0) {
            return filtered;
          }
          const isExisting = options.some((option) => inputValue.toLowerCase() === option?.title?.toLowerCase());

          if (inputValue !== "" && !isExisting) {
            filtered.push({
              inputValue, // inputValue key is added and is used to create a new option
              title: `Add "${inputValue}"`,
            });
          }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={allTaskLabels}
        getOptionLabel={(option: string | { inputValue?: string; title: string }) => {
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.title || "";
        }}
        renderOption={(props, option) => {
          const { ...optionProps } = props;
          if (typeof option === "string") {
            return <li {...optionProps}>{option}</li>;
          }
          return (
            <li key={option.title} {...optionProps}>
              {option.title}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField {...params} label="Start Typing or Select Label" size="medium" variant="outlined" />
        )}
        {...AcProps}
      />
    </>
  );
};
