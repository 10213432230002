import { Close } from "@mui/icons-material";
import { Box, Chip, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import styles from "src/Components/Recon360/TaskTracker/FilterAutocomplete.module.scss";
import { FilterKeysNameMap } from "../Components/BcNewFilter";

export default function FilterAutocomplete({ filterName, filteredValues, setFilterObj, setReFilter }) {
  const isDateRangeFilter = ["Date of Closing balance", "Date As of Last Balance Confirmed"].includes(filterName);
  const isNumberRangeFilter = [].includes(filterName);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    if ((event.target as HTMLElement).id !== "close-button") {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Box className={styles.container}>
        <Typography variant="caption" className={styles.caption}>
          {filterName}
        </Typography>
        <Box className={styles.autocomplete} onClick={handleMenuOpen}>
          {isDateRangeFilter || isNumberRangeFilter ? (
            <Stack direction="row" gap={1}>
              {filteredValues[0] && (
                <Chip
                  size="small"
                  label={
                    <span className="fs_13">
                      <span className="fs_13 fw_900">{isDateRangeFilter ? "Start:" : "Min:"}</span> {filteredValues[0]}
                    </span>
                  }
                />
              )}
              {filteredValues[1] && (
                <Chip
                  size="small"
                  label={
                    <span className="fs_13">
                      <span className="fs_13 fw_900">{isDateRangeFilter ? "End:" : "Max:"}</span> {filteredValues[1]}
                    </span>
                  }
                />
              )}
            </Stack>
          ) : (
            <Stack direction="row" gap={1} alignItems={"center"}>
              <Chip
                sx={{
                  ".MuiChip-label": {
                    fontSize: "13px",
                  },
                }}
                size="small"
                label={filteredValues.length > 0 ? (filteredValues[0] === "-" ? "None" : filteredValues[0]) : ""}
              />
              {filteredValues.length > 1 && (
                <Typography variant="body2" className={styles.more}>
                  +{filteredValues.length - 1}
                </Typography>
              )}
            </Stack>
          )}
          {!isDateRangeFilter ? (
            <IconButton
              size="small"
              id="close-button"
              onClick={(e) => {
                e.stopPropagation();

                setFilterObj((prev) => ({ ...prev, [FilterKeysNameMap[filterName]]: [] }));
                setReFilter(true);
              }}
            >
              <Close className={styles.icon} />
            </IconButton>
          ) : null}
        </Box>
        {!(isDateRangeFilter || isNumberRangeFilter) && (
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              style: {
                minWidth: anchorEl ? anchorEl.clientWidth : "auto",
              },
            }}
          >
            {filteredValues.map((value, index) => (
              <MenuItem key={index} onClick={handleClose} className={styles.menuItem}>
                {value === "-" ? "None" : value}
              </MenuItem>
            ))}
          </Menu>
        )}
      </Box>
    </>
  );
}
