import { ArrowRightRounded } from "@mui/icons-material";
import AttachFileSharpIcon from "@mui/icons-material/AttachFileSharp";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import SyncIcon from "@mui/icons-material/Sync";
import {
  Badge,
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import ERPDialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { Currency } from "dinero.js";
import { NestedMenuItem } from "mui-nested-menu";
import React, { useContext, useEffect, useRef, useState } from "react";
import { clarity } from "react-microsoft-clarity";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import useFetch from "src/Components/Common/useFetch";
import { amountEntryType, LedgerConfiguration } from "src/entity/recon-entity/ReconInterfaces";
import { ENV_NAME } from "src/Utils/Recon/ENV/Constants";
import uiLogger from "src/Utils/UiLogger";
import { ZapScaleEventsConstants } from "src/Utils/ZapscaleEvents/Contsants";
import Download_All_BP_Icon from "../../Graphics/AutomatedRecon/download_all_icon_bp.svg";
import Download_All_Own_Icon from "../../Graphics/AutomatedRecon/download_all_icon_own.svg";
import latest_entry from "../../Graphics/AutomatedRecon/latest_entry.svg";
import oldest_entry from "../../Graphics/AutomatedRecon/oldest_entry.svg";
import total_entries from "../../Graphics/AutomatedRecon/total_entries.svg";
import upload_icon from "../../Graphics/AutomatedRecon/upload_icon.svg";
import AvatarImg from "../../Graphics/Avatar_.png";
import obcb from "../../Graphics/obcb.svg";
import { Currencies } from "../../Utils/Common/Constants";
import { DefaultCurrency } from "../../Utils/MoneyUtils";
import { Customized, ledgerUploadName, UploadType } from "../../Utils/Recon/Recon360/Constants";
import { uiLoggerName, uiLoggerNamesCBBreakdown, uiLoggerNamesRecon } from "../../Utils/Recon/UiLogger/Constants";
import LoadingIcon from "../Common/LoadingIcon";
import MonetaryInput from "../Common/MonetaryInput";
import { userContext } from "../Contexts/userContext";
import CustomToast from "../CustomToast";
import { Dialog } from "../Dialog/Dialog";
import LedgerPdfUpload from "./LedgerPdfUpload";
import "./LedgerPdfUpload.scss";
import "./LedgerUpload.scss";
import { TallyOrSapCompanyId } from "./PartnerCommunication/CommonLegacy/CommonComponents";
import { Recon360Context } from "./Recon360";
import "./Recon360.scss";
import ClosingBalanceBreakdownModal from "./Recon360FileModal/ClosingBalanceBreakdownModal";
import SumOfEntriesModal from "./Recon360FileModal/SumOfEntriesModal/SumOfEntriesModal";
import { ConfigTemplateRes } from "./ReconHome";
import ColumnHeaderConfig from "./Transformer/ColumnHeaderConfig";
import DocumentTypeConfig from "./Transformer/DocumentTypeConfig";

interface LedgerUploadProps {
  excelUpload: (_e: React.ChangeEvent<HTMLInputElement>) => void;
  businessPartnerSelected: number;
  configList: {
    templateId: number;
    templateName: string;
    IsRuConfigTemplate?: boolean;
    IsPartnerConfigTemplate?: boolean;
    IsERPConfigTemplate?: boolean;
  }[];
  newMapping: boolean;
  name: "Own Documents" | "Business Partner Documents";
  dateSyncFromSAP: {
    lastTimeToSync: string; // "09:46 19/09"
    latestEntry: string; // "05/09/23"
    oldestEntry: string; // "18/02/20"
    overallEnteries: number; // 391
  };
  getConfigResponse: (businessPartnerId: number, afterConfigInsert?: boolean, reupload?: boolean) => Promise<void>;
  companyId: TallyOrSapCompanyId;
  branchCode: string;
  [k: string]: any;
}

const LedgerUpload = (props: LedgerUploadProps) => {
  const { actor } = useContext(userContext);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [allRules, setAllRules] = useState([]);
  const [headerNameChanged, setChangedHeaderName] = useState({} as LedgerConfiguration);
  const [templateNameChanged, setChangedTemplateName] = useState("");
  const [columnHeaderConfigDialog, setColumnHeaderConfigDialog] = useState<boolean>(true);
  const history = useHistory();
  const [accountingSign, setAccountingSign] = useState<string>("debit");
  const [balance, setBalance] = useState<number>((props.closingBalance as number) || 0);
  const [selectOpen, setSelectOpen] = useState<boolean>(false);
  const [onceFocused, setOnceFocused] = useState<boolean>(false);
  // const [openSelectTemplate, setOpenSelectTemplate] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState("Select Template");
  const [selectTemplateData, setSelectTemplateData] = useState<ConfigTemplateRes["template"]>(null);
  const [showCustomOption, setShowCustomOption] = useState<boolean>(false);
  const [fromCreateCustomTemplate, setFromCreateCustomTemplate] = useState<boolean>(false);
  const selectTemplateFromAddIcon = useRef(false);
  const [autoConfigSelected, setAutoConfigSelected] = useState(false);

  const {
    currency,
    updateClosingBalance,
    setOpenUnassignedDoctypedDialogFromView,
    businessPartnerSelectedRef,
    companyId,
    branchCode,
    downloadLedger,
    mappingId,
  } = useContext(Recon360Context);
  const [anchorEl, setAnchorEl] = useState(null);
  const openTemplate = Boolean(anchorEl);
  const [openClosingBalanceBreakdownModal, setOpenClosingBalanceBreakdownModal] = useState(false);
  const [openSumOfEntriesModal, setOpenSumOfEntriesModal] = useState(false);
  const [openingBalance, setOpeningBalance] = useState(null);
  const [sumOfEntries, setSumOfEntries] = useState(null);
  const [loaderClosingBalanceBreakdown, setLoaderClosingBalanceBreakdown] = useState(true);
  const [missingEntryNumbers, setMissingEntryNumbers] = useState([]);

  const handleClick = (event) => {
    return setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  useEffect(() => {
    const handleRouterChange = () => {
      setOpenDialog(false);
      setColumnHeaderConfigDialog(true);
      setOnceFocused(false);
      // setSelectOpen(true);
    };

    const unregisterListener = history.listen(handleRouterChange);

    return () => {
      unregisterListener();
    };
  }, [history]);

  useEffect(() => {
    let bal = Number(props.closingBalance) || 0;
    const accTerm = props.accountingTerminology;

    if (accTerm === amountEntryType.debitCredit) {
      bal = bal >= 0 ? bal : -1 * bal;
    }

    setBalance(bal || 0);

    setAccountingSign(props.closingBalance >= 0 ? "debit" : "credit");
  }, [props.accountingTerminology, props.closingBalance]);

  const getConfigTemplate = async (id: number) => {
    await useFetch(`/api/ListLedgerConfigTemplate/?templateId=${id}`, "GET", {
      thenCallBack: (response) => {
        setSelectTemplateData(response.data?.ledgerConfigTemplate);
        if (response.data.ledgerConfigTemplate.groups?.length > 0) {
          props.groupsListRef.current = response.data.ledgerConfigTemplate.groups;
        } else {
          props.groupsListRef.current = [];
        }
      },
    });
  };
  const getClosingBalanceBreakdown = async () => {
    await useFetch(`/api/GetClosingBalanceBreakdown`, "POST", {
      data: {
        businessPartnerId: businessPartnerSelectedRef.current,
        companyId: companyId.current,
        branchCode: branchCode.current,
        useCase: props.name === "Own Documents" ? "OWN" : "BP",
        manualClosingBalance: props.setClosingBalanceRef.current ? props.setClosingBalanceRef.current : 0,
      },
      thenCallBack: (response) => {
        setLoaderClosingBalanceBreakdown(false);
        setOpeningBalance(response.data.data.openingBalance);
        setSumOfEntries(response.data.data.sumOfEntries);
      },
    });
  };
  const getMissingEntryNumbers = async () => {
    await useFetch(`/api/GetMissingEntryNumbers`, "POST", {
      data: {
        businessPartnerId: businessPartnerSelectedRef.current,
        companyId: companyId.current,
        branchCode: branchCode.current,
        mappingId: mappingId.current,
        useCase: props.name === "Own Documents" ? "OWN" : "BP",
      },
      thenCallBack: (response) => {
        setMissingEntryNumbers(response.data.sortedMissingEntries);
      },
    });
  };

  const getRuConfigTemplate = (_templateId: string | number) => {
    useFetch<ConfigTemplateRes>("/api/GetRuConfigTemplate", "GET", {
      config: {
        params: {
          templateId: _templateId,
        },
      },
      thenCallBack: (response) => {
        setSelectTemplateData(response.data.template);
        if (response.data.template.groups?.length > 0) {
          props.groupsListRef.current = response.data.template.groups;
        } else {
          props.groupsListRef.current = [];
        }
      },
    });
  };

  const handleChangeSelectTemplate = (event: any) => {
    setAutoConfigSelected(false);
    setSelectTemplateData(null);
    setSelectedValue(event.target.value);
    if (event.target.value !== "Create Custom template") {
      const selectedTemplate = props.configList.find((template) => template.templateName === event.target.value);
      const selectedTemplateId = selectedTemplate.templateId;

      if (selectedTemplate.IsRuConfigTemplate) getRuConfigTemplate(selectedTemplateId);
      else getConfigTemplate(selectedTemplateId);
    }
  };
  const handleCloseSelectTemplate = () => {
    props.setOpenSelectTemplate(false);
    setSelectedValue("Select Template");
    setSelectTemplateData(null);
    setChangedTemplateName("");
    setTimeout(() => {
      selectTemplateFromAddIcon.current = false;
    }, 100);
    setAutoConfigSelected(false);
  };
  const getConfig = async () => {
    if (!fromCreateCustomTemplate) {
      await props.getConfigResponse(props.businessPartnerSelected);
    }
  };
  const removeExistingTemplate = () => {
    // setChangedHeaderName({} as LedgerConfiguration)
    props.setHeaderName({});
    props.setSanitizeCol([]);
    setAllRules([]);
    props.setDebitAmountSignReverseFlag(false);
    props.setCreditAmountSignReverseFlag(false);
    props.setTaxAmountSignReverseFlag(false);
    props.setTemplateName("");
  };
  return (
    <>
      <Grid container={true} className="center_align" sx={{ minWidth: 400, minHeight: "480px", alignContent: "start" }}>
        <div className="ledger_card">
          <p className="fw_600 fs_20">{props.cardTitle}</p>
        </div>
        <hr className="solid_divider" />

        <Grid item={true} xs={12} md={12} className="center_align ledger_upload_input">
          {props.integration ? (
            <>
              <Grid item={true} xs={12} md={12} className="center_align">
                <Button variant="contained" className="min_width_300" disabled={true}>
                  Synced from ERP
                </Button>
              </Grid>
            </>
          ) : (
            <label id="file-js-example" className="file cursor_pointer upload_ledger_label">
              <input
                disabled={
                  props.loadingConfig ||
                  props.businessPartnerSelected === null ||
                  props.disableUploadBothSide ||
                  props.pollingDownloadLedgersStatus
                }
                className="file-input"
                type="file"
                name="invoicefile"
                value={""}
                onChange={props.excelUpload}
                required={true}
                multiple={true}
                accept=".xlsx,.pdf"
                onClick={() => {
                  props.guidCommonTimestamp.current = Date.now()?.toString();
                  props.clearSheetSelectionStates();
                  uiLogger(
                    props.name === ledgerUploadName.ownSide
                      ? uiLoggerNamesRecon.UI_RL_UPLOAD_OWN_LEDGER_CLICK.functionName
                      : uiLoggerNamesRecon.UI_RL_UPLOAD_PARTNER_LEDGER_CLICK.functionName,
                    companyId.current,
                    branchCode.current,
                    {
                      message:
                        props.name === ledgerUploadName.ownSide
                          ? uiLoggerNamesRecon.UI_RL_UPLOAD_OWN_LEDGER_CLICK.message
                          : uiLoggerNamesRecon.UI_RL_UPLOAD_PARTNER_LEDGER_CLICK.message,
                      businessPartnerId: businessPartnerSelectedRef.current,
                    }
                  );
                }}
              />
              <span
                className={`file-cta upload_ledger_text ${
                  props.disableUploadBothSide || props.pollingDownloadLedgersStatus
                    ? "theme_btn_grey"
                    : props.name === "Own Documents"
                    ? "theme_btn"
                    : "theme_btn_blue"
                }`}
              >
                {props.showLoadingIcon ? null : <img src={upload_icon} alt="upload" />}
                {props.showLoadingIcon ? (
                  <span className="file-icon">
                    <i className="fas fa-circle-notch fa-spin" />
                  </span>
                ) : null}
                <span className="file-label fs_15 fw_500" style={{ marginLeft: "8px" }}>
                  {props.uploadSectionName}
                </span>
              </span>
            </label>
          )}

          {props.newMapping ? (
            <Tooltip title="Configuration">
              {props.loadingConfig ? (
                <IconButton
                  id="ConfigBtn" // configBtn ID to open the config dialog programmatically
                  style={{
                    color: "rgb(90 90 90)",
                    width: "60px",
                    height: "60px",
                    fontSize: "25px",
                    cursor: "pointer",
                    padding: "15px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <i className="fas fa-circle-notch fa-spin" />
                </IconButton>
              ) : (
                <Button
                  disabled={props.disableUploadBothSide || props.pollingDownloadLedgersStatus}
                  key="edit"
                  id="ConfigBtn"
                  variant="outlined"
                  className="fs_15 fw_500"
                  onClick={() => {
                    setOpenUnassignedDoctypedDialogFromView(false);
                    clarity.setTag(`${props.name === "Own Documents" ? "Own" : "BP"} Config`, `Open`);
                    if (
                      actor?.zsMonitorAccount &&
                      import.meta.env.VITE_APP_NAKAD_ENV === ENV_NAME.production &&
                      window.zapscale
                    ) {
                      window.zapscale.trackEvent(ZapScaleEventsConstants.CONFIG_OPEN);
                    }
                    setOpenDialog(true);
                    props.uiLogger(
                      props.name === ledgerUploadName.ownSide
                        ? uiLoggerName.ui_OwnConfigOpen
                        : uiLoggerName.ui_BpConfigOpen
                    );

                    props.name === ledgerUploadName.ownSide
                      ? window.clarity("event", uiLoggerNamesRecon.UI_RL_OWN_CONFIG_CLICK.functionName)
                      : window.clarity("event", uiLoggerNamesRecon.UI_RL_PARTNER_CONFIG_CLICK.functionName);

                    uiLogger(
                      props.name === ledgerUploadName.ownSide
                        ? uiLoggerNamesRecon.UI_RL_OWN_CONFIG_CLICK.functionName
                        : uiLoggerNamesRecon.UI_RL_PARTNER_CONFIG_CLICK.functionName,
                      companyId.current,
                      branchCode.current,
                      {
                        message:
                          props.name === ledgerUploadName.ownSide
                            ? uiLoggerNamesRecon.UI_RL_OWN_CONFIG_CLICK.message
                            : uiLoggerNamesRecon.UI_RL_PARTNER_CONFIG_CLICK.message,
                        businessPartnerId: businessPartnerSelectedRef.current,
                      }
                    );
                  }}
                  sx={{
                    paddingX: "30px",
                    height: "56px",
                    borderRadius: "10px !important",
                    color: props.name === "Own Documents" && "#27B27C",
                    borderColor: props.name === "Own Documents" && "#27B27C",
                    "&:hover, &.Mui-focusVisible": { borderColor: props.name === "Own Documents" && "#27B27C" },
                  }}
                >
                  CONFIG
                </Button>
              )}
            </Tooltip>
          ) : (
            <Button
              disabled={props.disableUploadBothSide || props.pollingDownloadLedgersStatus}
              key="add"
              variant="outlined"
              className="fs_16 fw_500"
              sx={{
                paddingX: "30px",
                height: "56px",
                borderRadius: "10px !important",
                color: props.name === "Own Documents" && "#27B27C",
                borderColor: props.name === "Own Documents" && "#27B27C",

                "&:hover, &.Mui-focusVisible": { borderColor: props.name === "Own Documents" && "#27B27C" },
              }}
              onClick={() => {
                if (props.businessPartnerSelected !== null && (props.newMappingOwn || props.name === "Own Documents")) {
                  setChangedHeaderName({} as LedgerConfiguration);
                  setChangedTemplateName("");
                  // setOpenDialog(true);
                  selectTemplateFromAddIcon.current = true;
                  props.setOpenSelectTemplate(true);
                } else {
                  if (props.businessPartnerSelected === null) {
                    toast.warning(<CustomToast message="Please select partner" />);
                  } else {
                    toast.warning(<CustomToast message="Please first create own config" />);
                  }
                }
              }}
            >
              CONFIG
            </Button>
          )}
        </Grid>
        {props.uploadedFileName !== null && props.uploadedFileName?.length > 0 && (
          <div className="files_info">
            <div className="center_align_ver_horiz fs_14">
              <CheckCircleRoundedIcon color={props.name === "Own Documents" ? "success" : "primary"} /> Files uploaded
            </div>
            <Chip
              icon={<AttachFileSharpIcon />}
              label={props.uploadedFileName[0]}
              title={props.uploadedFileName[0]}
              variant="outlined"
              sx={{
                borderRadius: "8px",
                maxWidth: props.uploadedFileName.length > 1 ? "168px" : "250px", // Adjust this width based on your font size and expected character length
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            />
            {props.uploadedFileName.length > 1 && (
              <Tooltip
                title={props.uploadedFileName?.map((fileItem: string, fileInd: number) => {
                  return (
                    fileInd !== 0 && (
                      <div key={fileInd} className="date_text">
                        {fileItem}
                      </div>
                    )
                  );
                })}
              >
                <Chip label={`+${props.uploadedFileName.length - 1} files`} sx={{ borderRadius: "8px" }} />
              </Tooltip>
            )}
            <Tooltip title="Download config applied, organised ledger" arrow>
              <Button
                className="download-button-all"
                startIcon={
                  props.pollingDownloadLedgersStatus ? (
                    <LoadingIcon loading={props.pollingDownloadLedgersStatus} />
                  ) : (
                    <img
                      src={props.name === ledgerUploadName.ownSide ? Download_All_Own_Icon : Download_All_BP_Icon}
                      alt="Download Icon"
                      className="download-button-all-icon"
                    />
                  )
                }
                style={{
                  background: props.pollingDownloadLedgersStatus
                    ? "#D7D7D7"
                    : props.name === ledgerUploadName.ownSide
                    ? "#d0f3e6"
                    : "#DDEDFF",
                  color: props.pollingDownloadLedgersStatus
                    ? "rgba(0, 0, 0, 0.60)"
                    : props.name === ledgerUploadName.ownSide
                    ? "#1b8159"
                    : "#2281CD",
                }}
                onClick={() => downloadLedger(props.name === ledgerUploadName.ownSide ? UploadType.OWN : UploadType.BP)}
                disabled={props.pollingDownloadLedgersStatus || props.showLoadingIcon}
              >
                Download ALL
              </Button>
            </Tooltip>
          </div>
        )}
        <hr className="solid_divider" />

        <div className="input_details">
          {props.subTab === "Ledger" && (
            <Grid item={true} xs={12} md={12} className="center_align">
              <Tooltip title={props.businessPartnerSelected !== null ? "" : "Please Select Business Partner"}>
                <FormControl style={{ width: "800px" }} disabled={!props.businessPartnerSelected}>
                  {/* <InputLabel htmlFor="outlined-adornment-closingBalance">Closing Balance</InputLabel> */}
                  <MonetaryInput
                    key={balance}
                    id="outlined-adornment-closingBalance"
                    disabled={!props.businessPartnerSelected}
                    label="Closing Balance as per Ledger Uploaded"
                    allowNegative={props.accountingTerminology !== amountEntryType.debitCredit}
                    value={balance}
                    setValue={(value: number) => {
                      if (props.accountingTerminology === amountEntryType.debitCredit) {
                        props.setClosingBalance(accountingSign === "debit" ? value : -1 * value);
                        props.setClosingBalanceRef.current = accountingSign === "debit" ? value : -1 * value;
                      } else {
                        props.setClosingBalance(value);
                        props.setClosingBalanceRef.current = value;
                      }
                      if (balance !== value)
                        updateClosingBalance(props.name === ledgerUploadName.ownSide ? "own" : "businessPartner");
                    }}
                    onBlur={(_e, _v) => {
                      if (!onceFocused) setSelectOpen(true);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {Currencies.find((cur) => cur.code === currency.current)?.code || DefaultCurrency.INR}
                        </InputAdornment>
                      ),
                      endAdornment:
                        props.accountingTerminology === amountEntryType.debitCredit ? (
                          <FormControl
                            sx={{
                              right: 16,
                              "& > .MuiOutlinedInput-root": {
                                background: "rgba(84, 28, 76, 0.1)",
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                              },
                            }}
                          >
                            <Select
                              labelId="accTerminology"
                              disabled={!props.businessPartnerSelected}
                              value={accountingSign}
                              open={selectOpen}
                              onClose={() => {
                                setOnceFocused(true);
                                setSelectOpen(false);
                              }}
                              onOpen={() => setSelectOpen(true)}
                              onChange={(e) => {
                                const validBalance = props.closingBalance;

                                setAccountingSign(e.target.value);

                                if (e.target.value === "debit") {
                                  props.setClosingBalance(validBalance >= 0 ? validBalance : -1 * validBalance);
                                  props.setClosingBalanceRef.current =
                                    validBalance >= 0 ? validBalance : -1 * validBalance;
                                } else {
                                  props.setClosingBalance(validBalance <= 0 ? validBalance : -1 * validBalance);
                                  props.setClosingBalanceRef.current =
                                    validBalance <= 0 ? validBalance : -1 * validBalance;
                                }
                              }}
                              sx={{
                                minWidth: "90px",
                                "& > fieldset": {
                                  borderColor: "transparent !important",
                                },
                              }}
                            >
                              <MenuItem value={"debit"}>Debit</MenuItem>
                              <MenuItem value={"credit"}>Credit</MenuItem>
                            </Select>
                          </FormControl>
                        ) : (
                          <></>
                        ),
                    }}
                    sx={{
                      minWidth: 330,
                      "&>.MuiOutlinedInput-root": {
                        p: 0,
                        pl: "16px",
                      },
                    }}
                    currency={(currency.current as Currency) || "INR"}
                  />
                </FormControl>
              </Tooltip>
              {!props.integration && (
                <Tooltip title="Closing Balance Breakdown" placement="bottom" arrow>
                  <IconButton
                    sx={{ marginLeft: 2 }}
                    onClick={() => {
                      uiLogger(
                        props.name === ledgerUploadName.ownSide
                          ? uiLoggerNamesCBBreakdown.UI_RL_OWN_CB_DETAILS_CLICK.functionName
                          : uiLoggerNamesCBBreakdown.UI_RL_BP_CB_DETAILS_CLICK.functionName,
                        companyId.current,
                        branchCode.current,
                        {
                          message:
                            props.name === ledgerUploadName.ownSide
                              ? uiLoggerNamesCBBreakdown.UI_RL_OWN_CB_DETAILS_CLICK.message
                              : uiLoggerNamesCBBreakdown.UI_RL_BP_CB_DETAILS_CLICK.message,
                          businessPartnerId: businessPartnerSelectedRef.current,
                        }
                      );

                      getClosingBalanceBreakdown();
                      getMissingEntryNumbers();
                      setOpenClosingBalanceBreakdownModal(true);
                    }}
                    disabled={
                      props.dateSyncFromSAP?.oldestEntry === null ||
                      props.dateSyncFromSAP?.latestEntry === null ||
                      props.disableUploadBothSide
                    }
                  >
                    {props.dateSyncFromSAP?.oldestEntry && props.dateSyncFromSAP?.latestEntry ? (
                      <Badge color="success" variant="dot">
                        <img src={obcb} alt="obcb" />
                      </Badge>
                    ) : (
                      <img src={obcb} alt="obcb" />
                    )}
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          )}
          {props.dateSyncFromSAP?.oldestEntry &&
            props.dateSyncFromSAP?.latestEntry &&
            props.dateSyncFromSAP?.lastTimeToSync &&
            props.businessPartnerSelected !== null && (
              <div className="entry_details">
                <div style={{ width: "50%", borderRight: "2px solid #e7e7e7", paddingRight: "10px" }}>
                  <div className="entry_details_item">
                    <div className="vertical_center_align fs_14 fw_500 gap_5">
                      <img src={oldest_entry} alt="oldest_entry" />
                      Oldest Entry
                    </div>
                    <div>
                      <p className="posting_date fs_14 fw_500 text_right">{props.dateSyncFromSAP.oldestEntry}</p>
                      {props.latestOldestEntryColumn && (
                        <p className="latestOldestEntryColumn">{props.latestOldestEntryColumn}</p>
                      )}
                    </div>
                  </div>
                  <hr className="solid_divider" />
                  <div className="entry_details_item">
                    <div className="vertical_center_align fs_14 fw_500 gap_5">
                      <img src={latest_entry} alt="latest_entry" />
                      Latest Entry
                    </div>
                    <div>
                      <p className="posting_date fs_14 fw_500 text_right">{props.dateSyncFromSAP.latestEntry}</p>
                      {props.latestOldestEntryColumn && (
                        <p className="latestOldestEntryColumn">{props.latestOldestEntryColumn}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div style={{ width: "50%", paddingLeft: "10px" }}>
                  <div className="entry_details_item">
                    <div className="vertical_center_align fs_14 fw_500 gap_5">
                      <img src={total_entries} alt="total_entries" />
                      Total Entries
                    </div>
                    <div>
                      <p className="posting_date fs_14 fw_500">
                        {props.dateSyncFromSAP?.overallEnteries &&
                          props.dateSyncFromSAP?.overallEnteries !== null &&
                          props.dateSyncFromSAP?.overallEnteries}
                      </p>
                    </div>
                  </div>
                  <hr className="solid_divider" />
                  <div className="entry_details_item">
                    <div className="vertical_center_align fs_14 fw_500 gap_5">
                      <SyncIcon />
                      Last Sync
                    </div>
                    <div>
                      <p className="posting_date fs_14 fw_500">{props.dateSyncFromSAP.lastTimeToSync}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          {(props.dateSyncFromSAP?.oldestEntry === null ||
            props.dateSyncFromSAP?.latestEntry === null ||
            props.dateSyncFromSAP?.lastTimeToSync === null) &&
          props.businessPartnerSelected !== null ? (
            <Grid className="no_ledger_entries">No ledger entries found in database</Grid>
          ) : null}
        </div>
      </Grid>
      <Dialog open={openDialog} onClose={() => setOpenDialog(true)} width70Per={true}>
        {columnHeaderConfigDialog ? (
          <ColumnHeaderConfig
            setColumnHeaderConfigDialog={setColumnHeaderConfigDialog}
            headerName={props.newMapping ? props.headerName : headerNameChanged || ({} as LedgerConfiguration)}
            setHeaderName={props.setHeaderName}
            rules={props.rulesArray}
            templateName={props.newMapping ? props.templateName : templateNameChanged || ""}
            setTemplateName={props.setTemplateName}
            sanitizeCol={props.sanitizeCol}
            template={true}
            newMapping={props.newMapping}
            setNewMapping={props.setNewMapping}
            heading="Column header mapping"
            openNextDialog={(value: any, header: LedgerConfiguration, template: string) => {
              setAllRules(value);
              props.setSanitizeCol(value);
              setChangedHeaderName(header);
              setChangedTemplateName(template);
              setColumnHeaderConfigDialog(false);
              props.name === ledgerUploadName.ownSide
                ? window.clarity("event", "UI_RL_OWN_CONFIG_NEXT_CLICK")
                : window.clarity("event", "UI_RL_PARTNER_CONFIG_NEXT_CLICK");
            }}
            showSettingsIcon={true}
            setOpenDialog={setOpenDialog}
            specificConfig={props.specificConfig}
            setSpecificConfig={props.setSpecificConfig}
            disableSpecificConfigCheckbox={props.disableSpecificConfigCheckbox}
            configList={props.configList}
            debitAmountSignReverseFlag={props.debitAmountSignReverseFlag}
            setDebitAmountSignReverseFlag={props.setDebitAmountSignReverseFlag}
            creditAmountSignReverseFlag={props.creditAmountSignReverseFlag}
            setCreditAmountSignReverseFlag={props.setCreditAmountSignReverseFlag}
            taxAmountSignReverseFlag={props.taxAmountSignReverseFlag}
            setTaxAmountSignReverseFlag={props.setTaxAmountSignReverseFlag}
            subTab={props.subTab}
            saveRulesApi={(header) => {
              props.saveRules(header, templateNameChanged || props.templateName, allRules);
              setOpenDialog(false);
              setColumnHeaderConfigDialog(true);
            }}
            name={props.name}
            getConfig={getConfig}
            showCustomOption={showCustomOption}
            setShowCustomOption={setShowCustomOption}
            removeExistingTemplate={removeExistingTemplate}
            integration={props.integration}
            uiLogger={(functionName: string) => props.uiLogger(functionName)}
            businessPartnerId={props.businessPartnerSelected}
            isUsedAsTemplate={props.isUsedAsTemplate}
            companyId={props.companyId}
            branchCode={props.branchCode}
            groupsListRef={props.groupsListRef}
          />
        ) : (
          <DocumentTypeConfig
            setColumnHeaderConfigDialog={setColumnHeaderConfigDialog}
            headerName={props.newMapping ? props.headerName : headerNameChanged || ({} as LedgerConfiguration)}
            setHeaderName={props.setHeaderName}
            name={props.name}
            heading="Document Type Key"
            backTemplate={() => {
              setColumnHeaderConfigDialog(true);
            }}
            setOpenDialog={setOpenDialog}
            saveRulesApi={(header) => {
              props.saveRules(header, templateNameChanged || props.templateName, allRules);
              setOpenDialog(false);
              setColumnHeaderConfigDialog(true);
            }}
            setDebitAmountSignReverseFlag={props.setDebitAmountSignReverseFlag}
            setCreditAmountSignReverseFlag={props.setCreditAmountSignReverseFlag}
            setTaxAmountSignReverseFlag={props.setTaxAmountSignReverseFlag}
            subTab={props.subTab}
            specificConfig={props.specificConfig}
            setSpecificConfig={props.setSpecificConfig}
            disableSpecificConfigCheckbox={props.disableSpecificConfigCheckbox}
            getConfig={getConfig}
            setShowCustomOption={setShowCustomOption}
            setTemplateName={props.setTemplateName}
            uiLogger={(functionName: string) => props.uiLogger(functionName)}
            setChangedTemplateName={setChangedTemplateName}
            groupsListRef={props.groupsListRef}
          />
        )}
      </Dialog>
      <div className="pdfUpload">
        <Dialog open={props.openPdfDialog} onClose={() => props.setOpenPdfDialog(true)} width70Per={true}>
          <LedgerPdfUpload
            pdfTableData={props.pdfTableData}
            setOpenPdfDialog={props.setOpenPdfDialog}
            setUploadedFileName={props.setUploadedFileName}
            setUploadedLastModifiedDate={props.setUploadedLastModifiedDate}
            integration={props.integration}
            businessPartnerAccountingSoftware={props.businessPartnerAccountingSoftware}
            businessPartnerSelectedCompanyId={props.businessPartnerSelectedCompanyId}
            getReconMetaData={props.getReconMetaData}
            businessPartnerSelected={props.businessPartnerSelected}
            getBusinessPartnerDateSyncFromSAP={props.getBusinessPartnerDateSyncFromSAP}
            getBusinessPartnerDataSyncFromTally={props.getBusinessPartnerDataSyncFromTally}
            getBusinessPartnerDateGetReconMetaData={props.getBusinessPartnerDateGetReconMetaData}
            headerName={props.headerName}
            setColumnHeaderArr={props.setColumnHeaderArr}
            setConfigUserType={props.setConfigUserType}
            setConfigUserName={props.setConfigUserName}
            setOpenColumnHeaderDialog={props.setOpenColumnHeaderDialog}
            setDocumentTypesArr={props.setDocumentTypesArr}
            assignBusinessPartnerName={props.assignBusinessPartnerName}
            // setFinaldocumentTypesObj={props.setFinaldocumentTypesObj}
            finaldocumentTypesObj={props.finaldocumentTypesObj}
            setOpenDocumentTypeDialog={props.setOpenDocumentTypeDialog}
            setShowUploadedSummary={props.setShowUploadedSummary}
            setShowUploadedSummaryObject={props.setShowUploadedSummaryObject}
            setEndDateOwn={props.setEndDateOwn}
            setEndDateBusinessPartner={props.setEndDateBusinessPartner}
            selectedPdfConverter={props.selectedPdfConverter}
            isPdfOptionConfirm={props.isPdfOptionConfirm}
            onChangePdfConversionMethod={props.onChangePdfConversionMethod}
            disablePdfTableOptionForMultiplePdf={props.disablePdfTableOptionForMultiplePdf}
            selectedBusinessPartnerName={props.selectedBusinessPartnerName}
            downloadUploadedPdfName={props.downloadUploadedPdfName}
            pdfConversionLoader={props.pdfConversionLoader}
            setPdfConversionLoader={props.setPdfConversionLoader}
            subTab={props.subTab}
          />
        </Dialog>
      </div>
      <ERPDialog open={props.openSelectTemplate} disableEnforceFocus={true}>
        <DialogTitle className="dialog_header">
          <p className="fs_24">Make Selection</p>
          <button className="delete ml_20" aria-label="close" onClick={() => handleCloseSelectTemplate()} />
        </DialogTitle>
        <DialogContent>
          <div className="mt_20 w_100_per">
            <FormControl className="w_100_per" size="small">
              {/* <InputLabel id="my-select-label">Select Template</InputLabel> */}
              <Select
                labelId="my-select-label"
                id="my-select"
                value={selectedValue}
                open={openTemplate}
                onOpen={handleClick}
                onClose={handleClose}
                onChange={null}
                fullWidth={true}
                // className="mw_250"
              >
                <MenuItem value="Select Template" disabled>
                  Select Template
                </MenuItem>
                {props.configList?.map((option: any) => (
                  <MenuItem
                    key={option.templateId + option.templateName}
                    value={option.templateName}
                    sx={{
                      display: "none",
                    }}
                  >
                    {option.templateName}
                  </MenuItem>
                ))}

                <NestedMenuItem label={"ERP Templates"} parentMenuOpen={openTemplate} rightIcon={<ArrowRightRounded />}>
                  {(props.configList?.length === 0 || props.configList?.every((item) => !item.IsERPConfigTemplate)) && (
                    <MenuItem disabled style={{ minWidth: "220px" }}>
                      No Templates
                    </MenuItem>
                  )}
                  {props.configList
                    .filter((item) => item.IsERPConfigTemplate) // Filter items with IsERPConfigTemplate true
                    .map((item) => (
                      <MenuItem
                        key={item.templateId + item.templateName}
                        value={item.templateName}
                        onClick={(_evt) => {
                          handleChangeSelectTemplate({ target: { value: item.templateName } });
                          handleClose();
                        }}
                        style={{ minWidth: "220px" }} // Set minimum width here
                      >
                        {item.templateName}
                      </MenuItem>
                    ))}
                </NestedMenuItem>

                <NestedMenuItem
                  label={"Custom Templates"}
                  parentMenuOpen={openTemplate}
                  rightIcon={<ArrowRightRounded />}
                >
                  {(props.configList?.length === 0 ||
                    props.configList?.every((item) => {
                      if (props.name === ledgerUploadName.ownSide) {
                        return !item.IsRuConfigTemplate;
                      } else if (props.name === ledgerUploadName.bpSide) {
                        return !item.IsPartnerConfigTemplate;
                      }
                      return false;
                    })) && (
                    <MenuItem disabled style={{ minWidth: "220px" }}>
                      No Templates
                    </MenuItem>
                  )}
                  {props.configList
                    .filter((item) => {
                      if (props.name === ledgerUploadName.ownSide) {
                        return item.IsRuConfigTemplate;
                      } else if (props.name === ledgerUploadName.bpSide) {
                        return item.IsPartnerConfigTemplate;
                      }
                      return true; // Include all items if name doesn't match any specific condition
                    })
                    .map((item) => (
                      <MenuItem
                        key={item.templateId + item.templateName}
                        value={item.templateName}
                        onClick={(_evt) => {
                          handleChangeSelectTemplate({ target: { value: item.templateName } });
                          handleClose();
                        }}
                        style={{ minWidth: "220px" }}
                      >
                        {item.templateName}
                      </MenuItem>
                    ))}
                </NestedMenuItem>
                {selectTemplateFromAddIcon.current && (
                  <MenuItem
                    onClick={(_evt) => {
                      if (selectTemplateFromAddIcon.current) {
                        setChangedTemplateName(Customized);
                      }
                      handleChangeSelectTemplate({ target: { value: "Create Custom template" } });
                      handleClose();
                    }}
                    key="new-option"
                    value="Create Custom template"
                    style={{ border: "1px solid" }}
                  >
                    Create Custom template
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            {!selectTemplateFromAddIcon.current && (
              <>
                <div className="divider mt_15 mb_15">Or</div>
                <div className="center_align">
                  <div
                    className="auto_config_box"
                    style={{ border: autoConfigSelected ? "1px solid #2196F3" : "1px solid rgba(0, 0, 0, 0.12)" }}
                    onClick={() => {
                      setAutoConfigSelected(!autoConfigSelected);
                      if (!autoConfigSelected) {
                        // check this
                        setSelectTemplateData(null);
                        setSelectedValue("Select Template");
                      }
                    }}
                  >
                    <span className="center_align_ver_horiz">
                      <img src={AvatarImg} alt="" />
                    </span>
                    <span className="ml_10">Auto-Configure</span>
                  </div>
                </div>
              </>
            )}
          </div>
        </DialogContent>
        <DialogActions className="dialog_footer">
          <div>
            {selectedValue === "Create Custom template" ? (
              <Button
                className="green_btn"
                onClick={() => {
                  if (selectTemplateFromAddIcon.current) {
                    setOpenDialog(true);
                    setFromCreateCustomTemplate(true);
                    handleCloseSelectTemplate();
                  }
                }}
              >
                Next
              </Button>
            ) : (
              <Button
                startIcon={<LoadingIcon loading={props.loadingConfig} />}
                className={selectTemplateData || autoConfigSelected ? "green_btn" : "grey_btn"}
                onClick={() => {
                  //  here need to add autoConfigSelected condition
                  if (autoConfigSelected) {
                    props.isOpenSelectTemplateUploadConfirmRef.current = true;
                    handleCloseSelectTemplate();
                    props.uploadFileAgainAfterSelectTempSave();
                  } else {
                    props.saveRules(
                      selectTemplateData?.config,
                      selectTemplateData?.templateName,
                      selectTemplateData?.columnsToSanitize
                    );
                    props.setHeaderName(selectTemplateData?.config);
                    props.isOpenSelectTemplateUploadConfirmRef.current = true;
                    handleCloseSelectTemplate();
                  }
                }}
                disabled={selectTemplateData || autoConfigSelected ? false : true}
              >
                Next
              </Button>
            )}
          </div>
        </DialogActions>
      </ERPDialog>

      {/*  */}
      {openClosingBalanceBreakdownModal && (
        <ClosingBalanceBreakdownModal
          open={openClosingBalanceBreakdownModal}
          setOpen={setOpenClosingBalanceBreakdownModal}
          balance={balance}
          accountingSign={accountingSign}
          name={props.name}
          onceFocused={onceFocused}
          setSelectOpen={setSelectOpen}
          updateClosingBalance={updateClosingBalance}
          Currencies={Currencies}
          currency={currency.current}
          accountingTerminology={props.accountingTerminology}
          setClosingBalance={props.setClosingBalance}
          setClosingBalanceRef={props.setClosingBalanceRef}
          setOpenSumOfEntriesModal={setOpenSumOfEntriesModal}
          sumOfEntries={sumOfEntries}
          openingBalance={openingBalance}
          oldestEntry={props.dateSyncFromSAP.oldestEntry}
          latestEntry={props.dateSyncFromSAP.latestEntry}
          loaderClosingBalanceBreakdown={loaderClosingBalanceBreakdown}
          setOpeningBalance={setOpeningBalance}
        />
      )}
      {openSumOfEntriesModal && (
        <SumOfEntriesModal
          openSumOfEntriesModal={openSumOfEntriesModal}
          setOpenSumOfEntriesModal={setOpenSumOfEntriesModal}
          whichSide={props.name === "Own Documents" ? "Own" : "BP"}
          missingEntryNumbers={missingEntryNumbers}
          name={props.name}
        />
      )}
    </>
  );
};

export default LedgerUpload;
