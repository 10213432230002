import { Clear } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  Divider,
  IconButton,
  InputAdornment,
  ListItemText,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useContext, useEffect, useState } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import { userContext } from "src/Components/Contexts/userContext";
import { ClientType } from "src/entity/models/FrontendActor";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { isOverFlowing } from "src/Utils/Common/CheckTextOverflow";
import { uiLoggerNamesPartnerCommunication } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { TICKET_PRIORITIES } from "../../Tickets/CreateTicket";
import { DEFAULT_BRANCH_CODE, DEFAULT_COMPANY_ID, StateDispatch } from "../CommonLegacy/CommonComponents";

export interface User {
  id: number;
  name: string;
  email: string;
}
export interface GetDefaultOptionsResponse {
  users: User[];
  maker: User;
  checker: User;
  approver: User;
  statuses: { id: number; status: string }[];
}
export default function MoveTaskToReconModal({
  open,
  setOpen,
  title,
  tasksToMoveCount,
  isMovingBulkTasks,
  moveToReconTaskDetails,
  companyId,
  branchCode,
  ListAllBusinessPartnerForBcBeta,
  setClearRows,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  tasksToMoveCount: number;
  isMovingBulkTasks: boolean;
  moveToReconTaskDetails: { ticketId: number; businessPartnerId: number; mappingId: number }[];
  companyId: string;
  branchCode: string;
  ListAllBusinessPartnerForBcBeta: any;
  setClearRows: StateDispatch<boolean>;
}) {
  const { actor } = useContext(userContext);
  const [selectedMaker, setSelectedMaker] = useState({ id: null, name: null, email: null });
  const [selectedChecker, setSelectedChecker] = useState({ id: null, name: null, email: null });
  const [selectedApprover, setSelectedApprover] = useState({ id: null, name: null, email: null });
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [dueDateErrorText, setDueDateErrorText] = useState("");
  const [selectedStatus, setSelectedStatus] = useState({ id: null, status: null });
  const [selectedPriority, setSelectedPriority] = useState(null);
  const [selectedRemarks, setSelectedRemarks] = useState(null);
  const [allUsers, setAllUsers] = useState([]);
  const [allStatuses, setAllStatuses] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isMovingTaskToRecon, setIsMovingTaskToRecon] = useState(false);

  const isCheckerCompulsory =
    actor.clientType === ClientType.Product || actor.clientType === ClientType.ProductEnterprise
      ? false
      : !selectedChecker.id;

  useEffect(() => {
    getDefaultOptions();
  }, []);

  const getDefaultOptions = () => {
    setIsLoadingData(true);
    useFetch<GetDefaultOptionsResponse>(API_ENDPOINTS.GET_DEFAULT_OPTIONS.url, "POST", {
      failureMessage: API_ENDPOINTS.GET_DEFAULT_OPTIONS.failureMessage,
      data: {
        taskData: moveToReconTaskDetails,
        companyId: companyId || DEFAULT_COMPANY_ID,
        branchCode: branchCode || DEFAULT_BRANCH_CODE,
      },
      thenCallBack: (response) => {
        setAllUsers(response.data?.users || []);
        setSelectedMaker(response.data?.maker || { id: null, name: null, email: null });
        setSelectedChecker(response.data?.checker || { id: null, name: null, email: null });
        setSelectedApprover(response.data?.approver || { id: null, name: null, email: null });
        setAllStatuses(response.data?.statuses || []);
        setSelectedDate(response.data.dueDate ? new Date(response.data.dueDate) : null);
        setSelectedStatus(response.data?.status || { id: null, status: null });
        setSelectedPriority(response.data?.priority || null);
        setSelectedRemarks(response.data?.remarks || null);
        setIsLoadingData(false);
      },
    });
  };

  const moveTaskToRecon = () => {
    setIsMovingTaskToRecon(true);
    useFetch(API_ENDPOINTS.MOVE_TICKETS_TO_RECON.url, "POST", {
      failureMessage: API_ENDPOINTS.MOVE_TICKETS_TO_RECON.failureMessage,
      showSuccessToast: true,
      data: {
        taskData: moveToReconTaskDetails.map((task) => {
          return { ticketId: task.ticketId, mappingId: task.mappingId };
        }),
        makerId: selectedMaker.id,
        checkerId: selectedChecker ? selectedChecker.id : null,
        approverId: selectedApprover ? selectedApprover.id : null,
        statusId: selectedStatus.id,
        priority: selectedPriority,
        dueDate: selectedDate,
        remarks: selectedRemarks,
        companyId: companyId || DEFAULT_COMPANY_ID,
        branchCode: branchCode || DEFAULT_BRANCH_CODE,
      },
      thenCallBack: () => {
        setIsMovingTaskToRecon(false);
        setOpen(false);
        ListAllBusinessPartnerForBcBeta();
        setClearRows(true);
      },
      catchCallBack: () => {
        setIsMovingTaskToRecon(false);
        setOpen(false);
        ListAllBusinessPartnerForBcBeta();
      },
    });
  };

  return (
    <Dialog open={open} sx={{ "& .MuiDialog-paper": { borderRadius: "4px", minWidth: "600px" } }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 2 }}>
        <Typography
          variant="h6"
          ref={(th) => {
            if (th && isOverFlowing(th)) th.title = th.innerText;
          }}
          className="textOverflow_hidden"
        >
          {title}
        </Typography>
        <IconButton
          onClick={() => {
            setOpen(false);
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <Stack sx={{ padding: 2, gap: 2, minHeight: "400px" }}>
        {isLoadingData ? (
          <>
            <Skeleton variant="text" sx={{ fontSize: "2rem", width: "200px" }} />
            <Skeleton variant="rounded" width="100%" height={40} />
            <Skeleton variant="rounded" width="100%" height={40} />
            <Skeleton variant="rounded" width="100%" height={40} />
            <Skeleton variant="rounded" width="100%" height={40} />
            <Skeleton variant="rounded" width="100%" height={40} />
            <Skeleton variant="rounded" width="100%" height={40} />
            <Skeleton variant="rounded" width="100%" height={40} />
          </>
        ) : (
          <>
            <Typography variant="subtitle2" sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
              {tasksToMoveCount} {tasksToMoveCount > 1 ? "Tasks" : "Task"} to be moved to Recon
            </Typography>
            <Autocomplete
              defaultValue={selectedMaker.id ? selectedMaker : null}
              onChange={(_, newValue) => {
                setSelectedMaker(newValue);
              }}
              sx={{ width: "100%", "& fieldset": { borderRadius: "4px" } }}
              disableClearable
              options={allUsers}
              renderOption={(props, item) => (
                <li {...props} key={item.id}>
                  <ListItemText>{item.name}</ListItemText>
                </li>
              )}
              getOptionLabel={(option: { name: string; id: number; email: string }) => option.name}
              renderInput={(params) => <TextField {...params} label="Maker*" size="small" />}
            />

            <Autocomplete
              defaultValue={selectedChecker.id ? selectedChecker : null}
              onChange={(_, newValue) => {
                setSelectedChecker(newValue);
              }}
              sx={{ width: "100%", "& fieldset": { borderRadius: "4px" } }}
              disableClearable
              options={allUsers}
              renderOption={(props, item) => (
                <li {...props} key={item.id}>
                  <ListItemText>{item.name}</ListItemText>
                </li>
              )}
              getOptionLabel={(option: { name: string; id: number; email: string }) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    actor.clientType === ClientType.Product || actor.clientType === ClientType.ProductEnterprise
                      ? "Checker"
                      : "Checker*"
                  }
                  size="small"
                />
              )}
            />

            <Autocomplete
              defaultValue={selectedApprover.id ? selectedApprover : null}
              onChange={(_, newValue) => {
                setSelectedApprover(newValue);
              }}
              sx={{ width: "100%", "& fieldset": { borderRadius: "4px" } }}
              //   disableClearable
              options={allUsers}
              renderOption={(props, item) => (
                <li {...props} key={item.id}>
                  <ListItemText>{item.name}</ListItemText>
                </li>
              )}
              getOptionLabel={(option: { name: string; id: number; email: string }) => option.name}
              renderInput={(params) => <TextField {...params} label="Approver" size="small" />}
            />
            <Autocomplete
              defaultValue={selectedStatus.id ? selectedStatus : null}
              onChange={(_, newValue) => {
                setSelectedStatus(newValue);
              }}
              sx={{ width: "100%", "& fieldset": { borderRadius: "4px" } }}
              disableClearable
              options={allStatuses}
              renderOption={(props, item) => (
                <li {...props} key={item.id}>
                  <ListItemText>{item.status}</ListItemText>
                </li>
              )}
              getOptionLabel={(option: { status: string; id: number }) => option.status}
              renderInput={(params) => <TextField {...params} label="Status*" size="small" />}
            />

            <Autocomplete
              value={selectedPriority}
              onChange={(_, newValue) => {
                setSelectedPriority(newValue);
              }}
              sx={{ width: "100%", "& fieldset": { borderRadius: "4px" } }}
              disableClearable
              options={TICKET_PRIORITIES}
              renderOption={(props, item) => (
                <li {...props} key={item}>
                  <ListItemText>{item}</ListItemText>
                </li>
              )}
              renderInput={(params) => <TextField {...params} label="Priority" size="small" />}
            />

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                sx={{ "& fieldset": { borderRadius: "4px" } }}
                label="Due Date"
                onError={(error) => {
                  if (error) setDueDateErrorText(error);
                  else setDueDateErrorText("");
                }}
                onChange={(date: Date) => {
                  setSelectedDate(date);
                }}
                value={selectedDate}
                format="dd/MM/yyyy"
                slotProps={{
                  textField: {
                    helperText: dueDateErrorText && "Invalid Date",
                    sx: { width: "100%" },
                    size: "small",

                    InputProps: {
                      style: { borderRadius: "4px" },
                      startAdornment: (
                        <InputAdornment position="start" sx={{ margin: 0 }}>
                          {selectedDate && (
                            <IconButton
                              size="small"
                              sx={{ position: "absolute", right: "40px" }}
                              onClick={() => {
                                setSelectedDate(null);
                              }}
                            >
                              <Clear />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    },
                  },
                }}
              />
            </LocalizationProvider>

            <TextField
              value={selectedRemarks}
              InputProps={{
                style: {
                  borderRadius: "4px",
                },
              }}
              sx={{ width: "100%" }}
              size="small"
              label="Remarks"
              onChange={(e) => {
                const remarks = e.target?.value.trim();
                setSelectedRemarks(remarks.length > 0 ? remarks : null);
              }}
              multiline
              maxRows={4}
            />
            {isMovingBulkTasks && (
              <Alert severity="info" sx={{ "& .MuiAlert-message": { fontSize: "14px" } }}>
                Maker, Checker and Approver will only be added for the tickets where the former is not already present
              </Alert>
            )}
          </>
        )}
      </Stack>
      <DialogActions sx={{ padding: 2, background: "#F1F1F1" }}>
        <Button
          disabled={
            isLoadingData ||
            !selectedMaker.id ||
            isCheckerCompulsory ||
            !selectedStatus ||
            dueDateErrorText !== "" ||
            isMovingTaskToRecon
          }
          variant="contained"
          sx={{
            background: "#27B27C",
            padding: "8px 22px",
            color: "#FFF",
            borderRadius: "4px !important",
            fontWeight: 500,
            fontSize: "15px",
            "&:hover, &.Mui-focusVisible": { background: "#27B27C" },
          }}
          onClick={() => {
            if (isMovingBulkTasks) {
              uiLogger(
                uiLoggerNamesPartnerCommunication.UI_PC_BCB_BULKACTION_BULKMOVETASKTORECON_CONTINUE_MOVE_CLICK
                  .functionName,
                companyId,
                branchCode,
                {
                  message:
                    uiLoggerNamesPartnerCommunication.UI_PC_BCB_BULKACTION_BULKMOVETASKTORECON_CONTINUE_MOVE_CLICK
                      .message,
                }
              );
            } else {
              uiLogger(
                uiLoggerNamesPartnerCommunication.UI_PC_BCB_ACTIONS_MOVETASKTORECON_MOVE_CLICK.functionName,
                companyId,
                branchCode,
                {
                  message: uiLoggerNamesPartnerCommunication.UI_PC_BCB_ACTIONS_MOVETASKTORECON_MOVE_CLICK.message,
                }
              );
            }
            moveTaskToRecon();
          }}
        >
          <LoadingIcon loading={isMovingTaskToRecon} />
          Move
        </Button>
      </DialogActions>
    </Dialog>
  );
}
